import Vue from 'vue';
import Router from '../utils/Router';

Vue.config.productionTip = false;

App.Layout = class {
    static init() {
        this.checkSession();
        this.enableFlashes();
        this.enableChangelog();
        this.enableFeedback();
        this.enableTheme();
        this.enableSASLogout();
        this.enableWsLogout();
        this.enableWidgets();
        new App.Cti();
    }

    static enableWidgets($root = $(document)) {
        this.enableConfirmSubmit($root)
    }

    static enableConfirmSubmit($root) {
        $root.on('click', 'form .js-confirm-submit', function (event) {
            event.preventDefault();
            App.Layout.confirmDialog((answer) => {
                if (answer) {
                    $(event.target).closest('form').submit();
                }
            }, $(event.currentTarget).data('message') || 'Voulez-vous vraiment supprimer cet élément ?');
        });
    }

    static confirmDialog(handler, message, style = 'danger') {
        if (!message) {
            message = 'Êtes-vous sûr(e) ?';
        }

        let $modal = $(
            `<div id="confirm_modal" role="dialog" class="modal fade" aria-modal="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <header class="modal-header bg-${style} text-white">
                            <h5 class="modal-title">Êtes-vous sûr(e) ?</h5>
                            <button type="button" aria-label="Close" class="close text-white">×</button>
                        </header>
                        <div class="modal-body">${message}</div>
                        <footer class="modal-footer">
                            <button type="button" class="btn btn-secondary" id="confirm_modal_no">Annuler</button>
                            <button type="button" class="btn btn-outline-${style}" id="confirm_modal_yes">Confirmer</button>
                        </footer>
                    </div>
                </div>
            </div>`
        );

        $modal.modal();

        $modal.find('#confirm_modal_yes').click(function () {
            handler(true);
            $modal.modal('hide');
        });

        $modal.find('#confirm_modal_no').click(function () {
            handler(false);
            $modal.modal('hide');
        });

        $modal.on('hidden.bs.modal', () => $modal.remove());
    }

    static enableFlashes() {
        new Vue({el: '#app-flashes'});
    }

    static enableChangelog() {
        document.getElementById('app-changelog') && new Vue({el: '#app-changelog'});
    }

    static enableFeedback() {
        document.getElementById('app-feedback') && new Vue({el: '#app-feedback'});
    }

    static enableTheme() {
        window.onload = () => {
            const toggleButton = document.getElementById('js-toggle-theme');
            if (!toggleButton) {
                return;
            }
            const state = document.documentElement.classList.contains('dark-mode');
            const dark = document.getElementById('js-toggle-theme-dark');
            const white = document.getElementById('js-toggle-theme-light');
            if (state) {
                dark.style.display = "none";
                white.style.display = "block";
            }
            toggleButton.addEventListener('click', () => {
                let wanted = document.documentElement.classList.contains('dark-mode') ? 'light' : 'dark';
                let preference = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

                if (wanted === 'dark') {
                    dark.style.display = "none";
                    white.style.display = "block";
                    document.documentElement.classList.add('dark-mode');
                } else {
                    dark.style.display = "block";
                    white.style.display = "none";
                    document.documentElement.classList.remove('dark-mode');
                }

                if (wanted === preference) {
                    localStorage.removeItem('theme');
                } else {
                    localStorage.setItem('theme', wanted);
                }
            });
        };
    }

    static enableSASLogout() {
        // Déconnecte l'utilisateur de la plateforme nationale SAS, avant de déconnecter de l'application
        const link = document.getElementById('sas_logout');
        if (link) {
            link.addEventListener('click', () => {
                const sasWindow = window.open(link.href, link.target);
                window.focus(); // Tente de reprendre le focus sur cet onglet, sera probablement bloqué par le navigateur
                if (sasWindow) {
                    setTimeout(() => {
                        sasWindow.close();
                        window.location.href = link.dataset.selfLogoutUrl;
                    }, 200);
                } else {
                    window.location.href = link.dataset.selfLogoutUrl;
                }
            });
        }
    }

    static enableWsLogout() {
        if (document.body.dataset.userCode) {
            webSocket.subscribe('utilisateur/' + document.body.dataset.userCode, (url, data) => {
                if ('logout' === data.type) {
                    window.location.href = Router.generate('logout');
                }
            });
        }
    }

    static checkSession() {
        setTimeout(() => {
            if (
                document.body.dataset.auth
                && window.performance
                && window.performance.getEntriesByType("navigation")
                && window.performance.getEntriesByType("navigation")[0]
                && 0 === window.performance.getEntriesByType("navigation")[0].transferSize
            ) {
                // Si la page a été chargée par le cache, on vérifie si une session est active
                fetch(Router.generate('check-session')).then(response => {
                    if (response.status === 401 || (response.status === 200 && response.redirected && (response.url ?? '').match(/\/login/))) {
                        window.location.reload();
                    }
                });
            }
        }, 500);
    }

    static pushState(state, url, replace = false) {
        if (replace) {
            window.history.replaceState(state, '', url);
        } else {
            window.history.pushState(state, '', url);
        }
        state && state.documentTitle && (document.title = state.documentTitle);
        window.dispatchEvent(new CustomEvent('app.' + (replace ? 'replaceState' : 'pushState'), {detail: {state, url}}));
    }

    static clipboardCopy(text) {
        navigator.clipboard.writeText(text).then(() => document.dispatchEvent(new CustomEvent('app.flash', {
            detail: {
                type: 'success',
                flash: 'Copié dans le presse papier.',
                autoHideDelay: 1500,
            }
        })));
    }
}