import Vue from 'vue';
import Router from '../utils/Router';
import Request from "../utils/Request";
import ParametrageMixin from '../vue/Mixins/ParametrageMixin';

Vue.config.productionTip = false;

const presets = {
    vintkatre: [{debut: '0', fin: '24'}],
    ferme: [{debut: '0', fin: '0'}]
};

export default class {
    constructor() {
        new Vue({
            el: '#app',
            delimiters: ['[[', ']]'],
            mixins: [ParametrageMixin],
            data: {
                appelZones: [],
                categories: [],
                centreDep: {
                    lat: null,
                    lng: null,
                    zoom: null,
                },
                communesSecteurisables: [],
                secteursRegroupables: [],
                contextMenu: {
                    lat: null,
                    lng: null,
                },
                contactTypes: [],
                couleurs: {},
                csrf: {
                    delete: null,
                },
                dep: null,
                departements: [],
                etablissementTypes: [],
                filtersActifs: '1',
                filtersAnomalies: false,
                filtersSansArbo: false,
                filtersCommune: null,
                filtersDaeValides: '',
                filtersDepartement: '',
                filtersEnCours: '',
                filtersGarde: false,
                filtersGardeCommune: null,
                filtersGardeSecteur: null,
                filtersGardeSecteurCommune: null,
                filtersGeocode: '',
                filtersGlobal: null,
                filtersIsOverride: '',
                filtersQuery: null,
                filtersSansSecteur: false,
                filtersSkipWatchers: false,
                filtersType: {
                    contact: '',
                    organisme: '',
                    orgMoyen: '',
                    medecin: '',
                    etablissement: '',
                    poi: '',
                    secteur: '',
                },
                icones: {},
                list: {
                    categorie: {},
                    loading: false,
                    page: 1,
                    nbPages: 1,
                    count: null,
                    wrappers: [],
                    geocodage: {},
                    cursor: null,
                    geoForm: {
                        score: '70',
                        entities: 'noGeo',
                    },
                    related: [],
                },
                medecinTypes: [],
                menu: {
                    loading: false,
                    counts: [],
                },
                modalDeleteShow : false,
                noPolygon: false,
                onglet: {
                    etablissement: 'services',
                    organisme: 'moyens',
                    arbo: 'etablissements'
                },
                ongletArboSearch: {
                    etablissements: null,
                    medecins: null,
                    pharmacies: null,
                    pois: null
                },
                organismeTypes: [],
                orgMoyenTypes: [],
                poiTypes: [],
                secteurTypes: [],
                showMap: false,
                tables: {},
                timeout: null,
                typesGeocodes: [],
            },
            beforeMount() {
                const data = JSON.parse(this.$el.dataset.data);

                for (let key in data) {
                    this[key] = data[key];
                }
            },
            mounted() {
                this.$el.classList.remove('invisible');

                Request.fetchJson(Router.generate('communes.secteurisables'))
                .then((data) => {
                    this.communesSecteurisables = data;
                });
                window.addEventListener('popstate', event => {
                    if (event.state) {
                        this.filtersSkipWatchers = true;
                        for (let prop in event.state) {
                            this[prop] = event.state[prop];
                        }
                        this.showMap = false;
                        if (!event.state.form || !event.state.form.entity) {
                            this.formCancel();
                            this.fetchEntities(false);
                        } else {
                            this.prepareEditWrapper({
                                entity: event.state.form.entity,
                                parent: event.state.form.parent,
                                type: event.state.form.type
                            });
                        }
                    }
                });

                this.fetchCounts();
                if (this.list.categorie && this.list.categorie.type) {
                    this.fetchEntities();
                } else {
                    this.setHistoryState();
                }

                if(this.form.type && this.form.entity) {
                    this.prepareEditWrapper({
                        entity: this.form.entity,
                        parent: this.form.parent,
                        type: this.form.type
                    });
                }
            },
            watch: {
                filtersQuery(newValue, oldValue) {
                    if (!this.filtersSkipWatchers && ((newValue && newValue.length >= 3) || (oldValue && oldValue.length >= 3))) {
                        clearTimeout(this.timeout);
                        this.timeout = setTimeout(() => {
                            this.list.page = 1;

                            this.fetchEntities();
                        }, 500);
                    }
                },
                filtersActifs() {
                    if (!this.filtersSkipWatchers) {
                        this.list.page = 1;
                        this.fetchCounts();
                        this.fetchEntities();
                    }
                },
                filtersGlobal() {
                    if (!this.filtersSkipWatchers) {
                        this.fetchCounts();
                    }
                },
                filtersList() {
                    if (!this.filtersSkipWatchers) {
                        this.list.page = 1;
                        this.fetchEntities();
                    }
                },
                communesSecteurisables() {
                    if ('secteur' === this.form.type) {
                        this.showMapForSecteur();
                    }
                }
            },
            methods: {
                changeCategorie(categorie, typeSecteur) {
                    this.showMap = false;

                    this.list.categorie = categorie;
                    this.list.wrappers = [];
                    this.list.page = 1;

                    this.clearSearch();
                    if(typeSecteur === 'gardes') {
                        this.filtersGarde = true;
                    } else if(typeSecteur) {
                        this.filtersType.secteur = typeSecteur;
                    }
                    if(this.filtersGlobal && this.filtersGlobal.length >= 3) {
                        this.filtersQuery = this.filtersGlobal;
                        this.filtersGlobal = null;
                    }

                    this.formCancel();
                    this.fetchEntities();
                },
                changePage(page) {
                    this.list.page = page;

                    this.fetchEntities();
                },
                getUrl(type, entity) {
                    let options = Object.assign({}, this.getFilters());

                    if(type && entity && entity.id) {
                        options.type = type;
                        options.id = entity.id;
                        options.action = 'modifier';
                    }

                    return Router.generate('parametrage.index', options);
                },
                getDocumentTitle() {
                    const parts = ['AppliSAMU', 'Paramétrage'];
                    if (this.list.categorie && this.list.categorie.libelle) {
                        parts.push(this.list.categorie.libelle);
                    }
                    if (this.form.type && this.form.entity) {
                        if (this.form.entity.id) {
                            parts.push('Modifier « ' + (this.form.entity.libelle || this.form.entity.nom || this.form.entity.id || this.form.entity.code ) + ' »');
                        } else {
                            parts.push('Ajouter');
                        }
                    }

                    return parts.reverse().join(' - ');
                },
                setHistoryState() {
                    App.Layout.pushState({
                        filtersActifs: this.filtersActifs,
                        filtersAnomalies: this.filtersAnomalies,
                        filtersSansArbo: this.filtersSansArbo,
                        filtersCommune: this.filtersCommune,
                        filtersDaeValides: this.filtersDaeValides,
                        filtersDepartement: this.filtersDepartement,
                        filtersEnCours: this.filtersEnCours,
                        filtersGarde: this.filtersGarde,
                        filtersGardeCommune: this.filtersGardeCommune,
                        filtersGardeSecteur: this.filtersGardeSecteur,
                        filtersGardeSecteurCommune: this.filtersGardeSecteurCommune,
                        filtersGeocode: this.filtersGeocode,
                        filtersGlobal: this.filtersGlobal,
                        filtersIsOverride: this.filtersIsOverride,
                        filtersQuery: this.filtersQuery,
                        filtersSansSecteur: this.filtersSansSecteur,
                        filtersType: JSON.parse(JSON.stringify(this.filtersType)),
                        list: JSON.parse(JSON.stringify(this.list)),
                        form: JSON.parse(JSON.stringify(this.form)),
                        documentTitle: this.getDocumentTitle(),
                    }, this.getUrl(this.form.type, this.form.entity));
                },
                getFilters() {
                    if (!this.list.categorie || !this.list.categorie.type) {
                        return {};
                    }

                    let queryOptions = {
                        type: this.list.categorie.type,
                        page: this.list.page,
                        actifs: this.filtersActifs,
                    };
                    if (this.filtersQuery && this.filtersQuery.length >= 3) {
                        queryOptions.query = this.filtersQuery;
                    }
                    if (this.filtersCommune && this.filtersCommune.libelle) {
                        queryOptions.commune = this.filtersCommune.libelle;
                    }

                    if (['commune', 'dae', 'carto_evenement', 'lieu_dit'].includes(queryOptions.type) && this.filtersDepartement !== '') {
                        queryOptions.departement = this.filtersDepartement;
                    }

                    if (this.filtersGeocode && this.typesGeocodes.indexOf(this.list.categorie.type) !== -1) {
                        queryOptions.geocode = this.filtersGeocode;
                    }

                    if ('commune' === queryOptions.type && this.filtersSansSecteur) {
                        queryOptions.sansSecteur = this.filtersSansSecteur;
                    }

                    if ('carto_evenement' === queryOptions.type && this.filtersEnCours) {
                        queryOptions.enCours = this.filtersEnCours;
                    }

                    if ('secteur' === queryOptions.type && this.filtersGarde) {
                        queryOptions.garde = this.filtersGarde;
                    }

                    if (['etablissement', 'service'].includes(queryOptions.type) && this.filtersAnomalies) {
                        queryOptions.anomalies = this.filtersAnomalies;
                    }

                    if ('etablissement' === queryOptions.type && this.filtersSansArbo) {
                        queryOptions.sansArbo = this.filtersSansArbo;
                    }

                    if (['contact', 'organisme', 'medecin', 'etablissement', 'poi', 'secteur'].includes(queryOptions.type) && this.filtersType[queryOptions.type] !== '') {
                        queryOptions.filterType = this.filtersType[queryOptions.type];
                    }

                    if (('vsab_moyen' === queryOptions.type || 'smur_moyen' === queryOptions.type) && this.filtersType.orgMoyen !== '') {
                        queryOptions.filterType = this.filtersType.orgMoyen;
                    }

                    if ('dae' === queryOptions.type) {
                        if (this.filtersDaeValides !== '') {
                            queryOptions.daeValides = this.filtersDaeValides;
                        }
                        if (this.filtersIsOverride !== '') {
                            queryOptions.isOverride = this.filtersIsOverride;
                        }
                    }

                    return queryOptions;
                },
                fetchEntities(setHistoryState = true, onSuccess = null) {
                    setHistoryState && this.setHistoryState();
                    this.filtersSkipWatchers = false;
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        if(this.list.categorie.type) {
                            this.list.geocodage = {};
                            this.list.loading = true;
                            this.list.cursor = null;

                            Request.fetchJson(Router.generate('parametrage.collection', this.getFilters()))
                                .then(data => {
                                    this.list.wrappers = data.wrappers;
                                    this.list.related = data.related;
                                    this.list.count = data.count;
                                    this.list.nbPages = Math.floor(data.nbPages);

                                    this.list.loading = false;
                                    onSuccess && onSuccess(data);
                                })
                                .catch(() => {
                                    this.list.wrappers = [];
                                    this.list.nbPages = 1;
                                    this.list.page = 1;

                                    this.list.loading = 'Une erreur est survenue sur le serveur.';
                                    setTimeout(() => { this.list.loading = false; }, 2000);
                                })
                            ;
                        }
                    }, 100);
                },
                fetchCounts() {
                    this.menu.loading = true;

                    let queryOptions = {
                        query: this.filtersGlobal && this.filtersGlobal.length >= 3 ? this.filtersGlobal : '',
                        actifs: this.filtersActifs,
                    };

                    Request.fetchJson(Router.generate('parametrage.counts', queryOptions))
                        .then((data) => {
                            this.menu.counts = data;
                        })
                        .finally(() => {
                            this.menu.loading = false;
                        })
                    ;
                },
                clearSearch() {
                    this.filtersQuery = null;
                    this.filtersCommune = null;

                    this.filtersGeocode = '';
                    this.filtersDepartement = '';
                    this.filtersDaeValides = '';
                    this.filtersIsOverride = '';
                    this.filtersSansSecteur = false;
                    this.filtersEnCours = '';
                    this.filtersGarde = false;
                    this.filtersAnomalies = false;
                    this.filtersSansArbo = false;
                    this.filtersType = {
                        contact: '',
                        organisme: '',
                        orgMoyen: '',
                        medecin: '',
                        etablissement: '',
                        poi: '',
                        secteur: '',
                    };

                    this.filtersGardeCommune = null;
                    this.filtersGardeSecteur = null;
                    this.filtersGardeSecteurCommune = null;
                },
                createEnfant(type) {
                    if (this.form.entity) {
                        this.createWrapper(type, {
                            type: this.form.type,
                            entity: this.form.entity,
                            parent: this.form.parent,
                        });
                    }
                },
                createWrapper(type, parent) {
                    let wrapper = {
                        type: type ?? this.list.categorie.type,
                        entity: {
                            estActif : true
                        },
                        parent: parent ?? null
                    };

                    if('vsab' === wrapper.type) {
                        wrapper.entity.type = {
                            code: 'VSAB',
                            libelle: 'VSAB',
                        };
                    }

                    if('smur' === wrapper.type) {
                        wrapper.entity.type = {
                            code: 'SMUR',
                            libelle: 'SMUR',
                        };
                    }

                    if ('carto_evenement' === wrapper.type) {
                        wrapper.entity.debut = new Date().toLocaleDateString('fr-FR') + ' ' + new Date().toLocaleTimeString('fr-FR', {timeStyle: 'short'});
                        wrapper.entity.icone = this.icones[wrapper.type];
                    }

                    if('etablissement' === wrapper.type || 'service' === wrapper.type) {
                        wrapper.entity.horaires = {
                            'semaine': [{debut: '', fin: ''}]
                        };
                    }

                    if('plage' === wrapper.type) {
                        wrapper.entity.week = {'L': false, 'M': false, 'm': false, 'J': false, 'V': false, 'S': false, 'D': false};
                    }

                    if('secteur_groupe' === wrapper.type) {
                        wrapper.type = 'secteur';
                        wrapper.entity.estGroupe = true;
                        wrapper.entity.groupeWeek = {'L': false, 'M': false, 'm': false, 'J': false, 'V': false, 'S': false, 'D': false};
                    }

                    this.editWrapper(wrapper);
                },
                editEnfant(type, entity) {
                    if (this.form.entity) {
                        this.editWrapper({
                            type: type,
                            entity: entity,
                            parent: {
                                type: this.form.type,
                                entity: this.form.entity,
                                parent: this.form.parent,
                            }
                        });
                    }
                },
                editWrapper(wrapper) {
                    // Wrapper partiel
                    if (wrapper.id) {
                        this.form.saving = true;

                        Request.fetchJson(Router.generate('parametrage.wrapper.parent', {
                            type: wrapper.type,
                            id: wrapper.id,
                        })).then(data => {
                            this.list.related = data.related;
                            this.editWrapper(data.wrapper);
                        }).catch((e) => {
                            console.error(e);
                            this.form.saving = 'Erreur serveur';
                            setTimeout(() => { this.form.saving = false; }, 2000);
                        });

                        return;
                    }

                    window.scrollTo(0, 0);
                    this.formCancel();
                    this.prepareEditWrapper(wrapper);
                    this.setHistoryState();
                },
                prepareEditWrapper(wrapper) {
                    this.onglet = {
                        etablissement: 'services',
                        organisme: 'moyens',
                        arbo: 'etablissements'
                    };

                    this.list.cursor = this.list.wrappers.findIndex(w => w.entity.id === wrapper.entity.id);
                    this.form.parent = wrapper.parent;
                    this.form.type = wrapper.type;

                    let entity = JSON.parse(JSON.stringify(wrapper.entity));

                    if ('etablissement' === this.form.type && !entity.cartoCollection && entity.code) {
                        Request.fetchJson(Router.generate('etablissement.getCartoArbo', {code: entity.code}))
                            .then(data => {
                                entity.cartoCollection = data;
                                this.$forceUpdate();
                            })
                        ;
                    }

                    if ('commune' === this.form.type) {
                        this.form.loadingGardeCommune = true;

                        Request.fetchJson(Router.generate('secteurs.commune', {insee: entity.id}))
                            .then((data) => {
                                entity.secteurs = data;
                                this.$forceUpdate();
                            })
                            .finally(() => {
                                this.form.loadingGardeCommune = false;
                                this.$forceUpdate();
                            })
                        ;
                    }

                    if ('unique' === entity.id) {
                        this.list.categorie = {};
                    }

                    this.form.entity = entity;

                    if ('secteur' === this.form.type) {
                        this.showMapForSecteur();

                        if (this.form.entity.estGroupe && this.form.entity.type) {
                            this.form.loadingGroupeSecteur = true;
                            this.secteursRegroupables = [];

                            Request.fetchJson(Router.generate('secteurs.all', {type: this.form.entity.type.id}))
                                .then((data) => {
                                    data.filter(s => !s.estGroupe).forEach(s => {
                                        s.selected = this.form.entity.secteurCollection && this.form.entity.secteurCollection.indexOf(s.code) !== -1;
                                        this.secteursRegroupables.push(s);
                                    });
                                })
                                .finally(() => {
                                    this.form.loadingGroupeSecteur = false;
                                    this.$forceUpdate();
                                })
                            ;
                        }
                    } else if ('cartoParam' === this.form.type) {
                        this.showOnMap({
                            latitude: entity.CTOW_DEFY,
                            longitude: entity.CTOW_DEFX
                        }, 'carto_centre', 10);
                    } else {
                        this.showMap = false;
                    }
                },
                deleteEntity(entity, confirmed) {
                    if(!confirmed) {
                        this.modalDeleteShow = true;
                    } else {
                        this.form.saving = true;

                        let url;
                        if ('dae' === this.form.type) {
                            url = Router.generate('dae.delete', {id: entity.id});
                        } else if ('carto_evenement' === this.form.type) {
                            url = Router.generate('carto-evenement.delete', {id: entity.id});
                        } else if ('rue' === this.form.type) {
                            url = Router.generate('rue.delete', {id: entity.id});
                        } else if ('contact' === this.form.type) {
                            url = Router.generate('contact.delete', {id: entity.id});
                        } else if ('lieu_dit' === this.form.type) {
                            url = Router.generate('lieu_dit.delete', {insee: entity.insee, libelle: entity.libelle});
                        } else {
                            url = Router.generate('arbo.delete', {id: entity.id});
                        }
                        Request.fetchJson(url, 'DELETE', {delete: {submitted: '1', _token: this.csrf.delete}})
                            .then(data => {
                                if (data.success) {
                                    this.form.saving = false;

                                    this.fetchEntities();
                                    this.formCancel();
                                    this.setHistoryState();
                                } else {
                                    this.form.saving = 'Erreur à la suppression.';
                                    setTimeout(() => { this.form.saving = false; }, 1000);
                                }
                            })
                            .catch(() => {
                                this.form.saving = 'Erreur serveur';
                                setTimeout(() => { this.form.saving = false; }, 2000);
                            })
                        ;
                    }
                },
                toggleSousGlossaire(codeGlos, codeItem) {
                    this.form.saving = true;

                    Request.fetchJson(Router.generate('parametrage.glossaire21.toggle', {codeGlos: codeGlos, codeItem: codeItem}))
                        .then(data => {
                            if (data.success) {
                                this.form.saving = 'OK';

                                setTimeout(() => {
                                    this.form.saving = false;

                                    if (this.form.entity) {
                                        this.list.related = data.related;
                                        this.editWrapper(data.wrapper);
                                        this.fetchEntities();
                                    }
                                }, 2000);
                            } else {
                                this.form.saving = 'Erreur à la modification.';
                                setTimeout(() => { this.form.saving = false; }, 1000);
                            }
                        })
                        .catch(() => {
                            this.form.saving = 'Erreur serveur';
                            setTimeout(() => { this.form.saving = false; }, 2000);
                        })
                    ;
                },
                addItemToCollection(property, item) {
                    if (!Array.isArray(this.form.entity[property])) {
                        Vue.set(this.form.entity, property, [item]);
                    } else {
                        this.form.entity[property].push(item);
                    }
                },
                arboAddSub(collection, event) {
                    if(this.form.entity[collection]) {
                        this.form.entity[collection].push(event);
                        this.form.entity[collection + 'Ajax'] = '';

                        this.$forceUpdate();
                    }
                },
                arboRemoveSub(collection, element) {
                    if(this.form.entity[collection]) {
                        delete this.form.entity[collection][this.form.entity[collection].indexOf(element)];
                        this.form.entity[collection] = this.form.entity[collection].filter(n => n);

                        this.$forceUpdate();
                    }
                },
                showOnMap(entity, type, zoom) {
                    if(entity.latitude && entity.longitude) {
                        this.showMap = true;

                        this.$refs.carto.hideAllCommunes();
                        this.$refs.carto.cleanMap();
                        this.$refs.carto.drawMarkerFromEntity(entity, type ?? this.form.type);

                        this.$refs.carto.zoomer(zoom ?? 13);
                    }
                },
                showMapForGeolocInverse() {
                    this.showMap = true;

                    this.$refs.carto.hideAllCommunes();
                    this.$refs.carto.cleanMap();
                    this.$refs.carto.centrer();
                    this.$refs.carto.zoomer();
                },
                showPolygonOnMap(entity) {
                    this.noPolygon = false;

                    if(entity.id) {
                        Request.fetchJson('/rastik/getPolygon/com&' + (entity.codePolygon ?? entity.id)).then(objects => {
                            this.showOnMap(entity);

                            this.$refs.carto.drawPolygon(objects, entity);
                        }, () => {
                            this.noPolygon = true;
                        }).catch(() => {
                            this.noPolygon = true;
                        });
                    }
                },
                showMapForSecteur() {
                    this.showMap = true;

                    this.$refs.carto.showAllCommunes();
                    this.$refs.carto.cleanMap();
                    this.$refs.carto.centrer();
                    this.$refs.carto.zoomer();

                    if(this.$refs.carto.communesReady && this.communesSecteurisables.length) {
                        this.communesSecteurisables.forEach(commune => {
                            if (commune.insee) {
                                commune.selected = this.form.entity.communeCollection ? this.form.entity.communeCollection.indexOf(commune.insee) !== -1 : false;
                                commune.warning = false;

                                this.$refs.carto.setCommunesState(commune.insee, commune.selected ? (this.form.entity.estGroupe ? 'covered' : 'selected') : 'shown');
                            }
                        });
                        this.$refs.carto.applyCommunesState();

                        if (this.form.entity.type && !this.form.entity.estGroupe) {
                            const inseeMap = this.communesSecteurisables.map(commune => commune.insee);
                            Request.fetchJson(Router.generate('communes.sansSecteur', {type: this.form.entity.type.id}))
                                .then((data) => {
                                    data.forEach(insee => {
                                        const index = inseeMap.indexOf(insee);

                                        if(this.communesSecteurisables[index]) {
                                            this.communesSecteurisables[index].warning = true;
                                            this.$refs.carto.setCommunesState(insee, 'warning');
                                        }
                                    });
                                    this.$refs.carto.applyCommunesState();
                                })
                            ;
                        }
                    }
                },
                communesMapReady() {
                    if ('secteur' === this.form.type) {
                        this.showMapForSecteur();
                    }
                },
                toggleSecteur(secteur) {
                    if(secteur) {
                        secteur.selected = !secteur.selected;

                        if(secteur.selected) {
                            this.form.entity.secteurCollection.push(secteur.code);
                        } else {
                            delete this.form.entity.secteurCollection[this.form.entity.secteurCollection.indexOf(secteur.code)];
                            this.form.entity.secteurCollection = this.form.entity.secteurCollection.filter(n => n);
                        }

                        secteur.communeCollection.forEach(insee => {
                            if(secteur.selected) {
                                this.form.entity.communeCollection.push(insee);
                            } else {
                                delete this.form.entity.communeCollection[this.form.entity.communeCollection.indexOf(insee)];
                                this.form.entity.communeCollection = this.form.entity.communeCollection.filter(n => n);
                            }

                            this.$refs.carto.setCommunesState(insee, secteur.selected ? 'covered' : null);
                        });
                    }

                    this.$refs.carto.applyCommunesState();
                },
                toggleCommune(commune, event) {
                    if (!this.form.type || 'secteur' !== this.form.type || !this.form.entity.communeCollection) {
                        return;
                    }

                    const insee = event ? event.insee : commune.insee;

                    if (this.form.entity.estGroupe) {
                        this.filtersGardeSecteurCommune = event;

                        return;
                    }

                    if(insee && !commune) {
                        this.communesSecteurisables.forEach(c => {
                            if(c.insee === insee) {
                                commune = c;
                            }
                        });
                    }

                    if(!commune.selected) {
                        this.form.entity.communeCollection.push(insee);
                        commune.selected = true;
                    } else {
                        delete this.form.entity.communeCollection[this.form.entity.communeCollection.indexOf(insee)];
                        this.form.entity.communeCollection = this.form.entity.communeCollection.filter(n => n);
                        commune.selected = false;
                    }

                    this.$refs.carto.setCommunesState(commune.insee, commune.selected ? 'selected' : (commune.warning ? 'warning' : 'shown'));
                    this.$refs.carto.applyCommunesState();
                },
                toggleZoneAmu(zone) {
                    if (!Array.isArray(this.form.entity.zoneCollection)) {
                        this.form.entity.zoneCollection = [];
                    }
                    const index = this.form.entity.zoneCollection.map(z => z.code).indexOf(zone.code);
                    if(index === -1) {
                        this.form.entity.zoneCollection.push(zone);
                    } else {
                        this.form.entity.zoneCollection.splice(index, 1);
                    }
                },
                formSave() {
                    this.form.saving = true;

                    let type = this.form.type,
                        edit = this.form.entity.id,
                        route = edit ? Router.generate('parametrage.edit', { type: type, id: this.form.entity.id }) : Router.generate('parametrage.new', { type: type }),
                        data = this.forms[type] ? this.normalize(this.forms[type].skeleton, this.form.entity) : this.form.entity;

                    if (type === 'cartoPoi') {
                        route = Router.generate('parametrage.carto.pois');
                    }
                    if (type === 'cartoParam') {
                        route = Router.generate('parametrage.carto.param');
                    }
                    if (this.form.parent) {
                        data.parent = this.form.parent.id ?? this.form.parent.entity.id;
                    }

                    Request.fetchJson(route, 'POST', data).then(response => {
                        if (response.success) {
                            this.form.errors = {};

                            this.form.saving = 'OK';
                            setTimeout(() => {
                                this.form.saving = false;

                                if (this.form.entity) {
                                    this.editWrapper(response.wrapper);
                                    this.fetchEntities();
                                }
                                if (!edit) {
                                    this.fetchCounts();
                                }
                                if (type === 'commune') {
                                    this.postCommuneSave();
                                }
                            }, 2000);
                        } else {
                            this.form.errors = response.errors;

                            this.form.saving = 'Erreur à l\'enregistrement.';
                            setTimeout(() => { this.form.saving = false; }, 1000);
                        }
                    }, () => {
                        this.form.saving = 'Erreur à l\'envoi du formulaire.';
                        setTimeout(() => { this.form.saving = false; }, 2000);
                    });
                },
                formCancel() {
                    this.form.type = null;
                    this.form.entity = null;
                    this.form.saving = false;
                    this.form.errors = {};

                    this.noPolygon = false;

                    this.ongletArboSearch = {
                        etablissements: null,
                        medecins: null,
                        pharmacies: null,
                        pois: null
                    };
                },
                postCommuneSave() {
                    Request.fetchJson(Router.generate('communes.secteurisables'))
                        .then((data) => {
                            this.communesSecteurisables = data;
                        });

                    this.$refs.carto.refreshPolygonsCom();
                },
                toutGeocoder() {
                    if(this.list.categorie.type) {
                        const app = this;
                        this.list.loading = true;

                        Request.fetchJson(Router.generate('geocoder.fetch', { type: this.list.categorie.type }))
                            .then(data => {
                                this.list.loading = false;

                                this.list.geocodage = data;

                                if(this.list.geocodage.ok && this.list.geocodage.ok.length) {
                                    this.list.geocodage.ok.forEach(geocodage => {
                                        geocodage.checkGeocodageSave = function () {
                                            return !(app.list.geoForm.score && app.list.geoForm.score > this.score) &&
                                                !(app.list.geoForm.entities === 'noGeo' && (this.bdd_latitude.toString().trim() !== '' || this.bdd_longitude.toString().trim() !== ''));
                                        };
                                    });
                                }
                            })
                            .catch(() => {
                                this.list.loading = 'Erreur serveur';
                                setTimeout(() => { this.list.loading = false; }, 2000);
                            })
                        ;
                    }
                },
                toutGeocoderSave() {
                    if(this.list.categorie.type) {
                        this.list.loading = true;

                        let route = Router.generate('geocoder.save', { type: this.list.categorie.type }),
                            data = this.list.geocodage.ok ? this.list.geocodage.ok.filter(geocodage => geocodage.checkGeocodageSave()) : [];

                        Request.fetchJson(route, 'POST', data)
                            .then((response) => {
                                if (response.success) {
                                    this.list.loading = 'OK';
                                    setTimeout(() => { this.list.loading = false; }, 2000);
                                } else {
                                    this.list.loading = 'Erreur serveur';
                                    setTimeout(() => { this.list.loading = false; }, 2000);
                                }
                            }, () => {
                                this.list.loading = 'Erreur à l\'envoi du formulaire.';
                                setTimeout(() => { this.list.loading = false; }, 2000);
                            });
                    }
                },
                latLngHorsFrance (entity) {
                    return parseFloat(entity.longitude) < -4.65 ||
                        parseFloat(entity.longitude) > 9.45 ||
                        parseFloat(entity.latitude) < 41.59101 ||
                        parseFloat(entity.latitude) > 51.03457;
                },
                listTrClass(entity) {
                    return (entity && 'estActif' in entity && !entity.estActif ? ' text-muted text-deleted' : '') + ('cartographie' !== this.list.categorie.type ? (this.list.cursor >= 0 && this.list.cursor === this.list.wrappers.indexOf(entity) ?  ' bg-info-ultralight' : ' clickable-div') : '');
                },
                switchModeHoraires(object) {
                    if(object && object.horaires) {
                        if (object.horaires.semaine) {
                            object.horaires = {
                                'lundi': [{debut: '', fin: ''}],
                                'mardi': [{debut: '', fin: ''}],
                                'mercredi': [{debut: '', fin: ''}],
                                'jeudi': [{debut: '', fin: ''}],
                                'vendredi': [{debut: '', fin: ''}],
                                'samedi': [{debut: '', fin: ''}],
                                'dimanche': [{debut: '', fin: ''}]
                            };
                        } else {
                            object.horaires = {
                                'semaine': [{debut: '', fin: ''}]
                            };
                        }
                    }
                },
                addHoraires(horaires, index) {
                    if(horaires) {
                        if(!horaires[index]) {
                            horaires[index] = [];
                        }
                        horaires[index].push({debut: '', fin: ''});
                        this.$forceUpdate();
                    }
                },
                presetHoraires(horaires, index, preset) {
                    if(horaires && presets[preset]) {
                        horaires[index] = JSON.parse(JSON.stringify(presets[preset]));
                        this.$forceUpdate();
                    }
                },
                setCartoParamFromContext(lat, lng, newLat, newLng) {
                    Vue.set(this.form.entity, lat, newLat);
                    Vue.set(this.form.entity, lng, newLng);

                    this.$refs.carto.drawMarkerFromEntity({
                        latitude: this.form.entity[lat],
                        longitude: this.form.entity[lng]
                    }, 'carto_centre');
                },
                toggleTypePoi(type) {
                    const index = this.form.entity.typeCollection.indexOf(type.code);
                    if(index === -1) {
                        this.form.entity.typeCollection.push(type.code);
                    } else {
                        this.form.entity.typeCollection.splice(index, 1);
                    }
                },
                onTypeaheadInput() {
                    Vue.set(this.form.entity, 'insee', null);
                    Vue.set(this.form.entity, 'codePostal', null);
                },
                onTypeaheadSelect(field, data) {
                    if ('ville' === field) {
                        Vue.set(this.form.entity, 'insee', data.codeInsee);
                        Vue.set(this.form.entity, 'ville', data.libelle);
                        Vue.set(this.form.entity, 'codePostal', data.codePostal);
                    } else if ('adresse2' === field) {
                        Vue.set(this.form.entity, 'ville', data.ville);
                        Vue.set(this.form.entity, 'codePostal', data.codePostal);
                        Vue.set(this.form.entity, 'insee', data.codeInsee);
                        Vue.set(this.form.entity, 'adresse2', data.libelleComplet || data.libelle);
                    } else if ('voie' === field) {
                        Vue.set(this.form.entity, 'ville', data.ville);
                        Vue.set(this.form.entity, 'codePostal', data.codePostal);
                        Vue.set(this.form.entity, 'voie', data.libelle);
                    }
                },
                highlightCommune(commune) {
                    this.$refs.carto.highlightCommune(commune ? commune['insee'] : null);
                },
                highlightSecteur(secteur) {
                    if(secteur) {
                        secteur.communeCollection.forEach(insee => {
                            this.$refs.carto.setCommunesState(insee, 'selected_noborder_soft', true);
                        });
                    }

                    this.$refs.carto.applyCommunesState();
                },
                openContextMenu(event) {
                    this.contextMenu.lat = event.latlng.lat.toFixed(6);
                    this.contextMenu.lng = event.latlng.lng.toFixed(6);
                    this.$refs.contextMenu.open();
                },
                setGeocodageInverse(lat, lng) {
                    Vue.set(this.form.entity, 'latitude', lat);
                    Vue.set(this.form.entity, 'longitude', lng);
                    this.$refs.carto.drawMarkerFromEntity(this.form.entity, this.form.type);
                    if (this.form.type === 'lieu_dit') {
                        // Ne donne rien la plupart du temps alors on ignore la recherche d'adresse
                        return;
                    }

                    this.form.loadingAdresse = true;
                    Request.fetchJson('https://api-adresse.data.gouv.fr/reverse/?lat=' + lat + '&lon=' + lng)
                        .then(data => {
                            let result = data.features.length ? data.features[0].properties : {
                                housenumber: null,
                                street: null,
                                name: null,
                                postcode: null,
                                citycode: null,
                                city: null,
                            };
                            if ('undefined' !== typeof this.forms[this.form.type].skeleton.adresse2) {
                                Vue.set(this.form.entity, 'adresse1', result.housenumber);
                                Vue.set(this.form.entity, 'adresse2', result.street);
                            } else if ('undefined' !== typeof this.forms[this.form.type].skeleton.adresse) {
                                Vue.set(this.form.entity, 'adresse', result.name);
                            } else if ('undefined' !== typeof this.forms[this.form.type].skeleton.noVoie) {
                                Vue.set(this.form.entity, 'noVoie', result.housenumber);
                                Vue.set(this.form.entity, 'voie', result.street);
                            }
                            if ('undefined' !== typeof this.forms[this.form.type].skeleton.codePostal) {
                                Vue.set(this.form.entity, 'codePostal', result.postcode);
                            }
                            if ('undefined' !== typeof this.forms[this.form.type].skeleton.insee) {
                                Vue.set(this.form.entity, 'insee', result.citycode);
                            }
                            if ('undefined' !== typeof this.forms[this.form.type].skeleton.ville) {
                                Vue.set(this.form.entity, 'ville', result.city);
                            } else {
                                Vue.set(this.form.entity, 'commune', {
                                    id: result.citycode,
                                    codeInsee: result.citycode,
                                    codePostal: result.postcode,
                                    libelle: result.city,
                                });
                            }
                        })
                        .finally(() => this.form.loadingAdresse = false)
                    ;
                },
                clipboardCopy(text) {
                    App.Layout.clipboardCopy(text)
                },
            },
            computed: {
                tableLayout() {
                    return this.tables[this.list.categorie.type] ? this.tables[this.list.categorie.type] : this.tables['default'];
                },
                geocodageMultipleTodos() {
                    if(this.list.geocodage.ok && this.list.geocodage.ok.length) {
                        return this.list.geocodage.ok.filter(geocodage => geocodage.checkGeocodageSave()).length;
                    }

                    return 0;
                },
                filtersList() {
                    return 'filtersCommune' + (this.filtersCommune ? this.filtersCommune.id : '')
                        + 'filtersGeocode' + this.filtersGeocode
                        + 'filtersDepartement' + this.filtersDepartement
                        + 'filtersType.organisme' + this.filtersType.organisme
                        + 'filtersType.orgMoyen' + this.filtersType.orgMoyen
                        + 'filtersType.medecin' + this.filtersType.medecin
                        + 'filtersType.etablissement' + this.filtersType.etablissement
                        + 'filtersType.contact' + this.filtersType.contact
                        + 'filtersType.poi' + this.filtersType.poi
                        + 'filtersType.secteur' + this.filtersType.secteur
                        + 'filtersSansSecteur' + this.filtersSansSecteur
                        + 'filtersEnCours' + this.filtersEnCours
                        + 'filtersGarde' + this.filtersGarde
                        + 'filtersAnomalies' + this.filtersAnomalies
                        + 'filtersSansArbo' + this.filtersSansArbo
                        + 'filtersDaeValides' + this.filtersDaeValides
                        + 'filtersIsOverride' + this.filtersIsOverride
                    ;
                },
                secteurOrganismeChoices() {
                    if (this.form.entity) {
                        const secteurType = this.form.entity.type ? this.form.entity.type.code : null;

                        let filteredOrganismes = [];

                        if (secteurType === '002CS' || secteurType === '002SM') {
                            filteredOrganismes = this.organismes.filter(o => o.type === (secteurType === '002CS' ? 'VSAB' : 'SMUR'));
                        } else {
                            filteredOrganismes = this.organismes.filter(o => o.type !== 'VSAB' && o.type !== 'SMUR');
                        }

                        if(this.form.entity.organisme && !filteredOrganismes.filter(o => o.code === this.form.entity.organisme.code).length) {
                            this.form.entity.organisme = null;
                        }

                        return filteredOrganismes;
                    }

                    return [];
                },
                serviceSpecialiteChoices() {
                    if (this.form.entity) {
                        const specialites = this.specialites.filter(s => s.codeGlossaire === this.orientationSG[this.form.entity.orientation ? this.form.entity.orientation.code : '']);

                        if(this.form.entity.specialite && !specialites.filter(s => s.code === this.form.entity.specialite.code).length) {
                            this.form.entity.specialite = null;
                        }

                        return specialites;
                    }

                    return [];
                },
                previousWrapper() {
                    return this.list.wrappers[this.list.cursor - 1] ?? null;
                },
                nextWrapper() {
                    return this.list.wrappers[this.list.cursor + 1] ?? null;
                },
            },
        });
    }
}