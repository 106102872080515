<template>
    <div>
        <template v-if="roleBroadcast && webSocketOnline">
            <a
                aria-haspopup="true"
                class="nav-link text-white"
                href="javascript:void(0)"
                style="line-height: 47px;"
                title="Diffuser un message à tous les utilisateurs"
                v-b-modal.modal-new-broadcast
            >
                <i class="fa-duotone fa-broadcast-tower px-2"></i>
            </a>
            <b-modal
                id="modal-new-broadcast"
                cancel-title="Annuler"
                centered
                class="d-none"
                :header-bg-variant="flashMessageTypes[newFlashMessageType].style"
                header-text-variant="white"
                :ok-variant="'outline-' + flashMessageTypes[newFlashMessageType].style"
                title="Diffuser un message à tous les utilisateurs"
                @cancel="newFlashMessageContent = null"
                @ok="broadcast()"
            >
                <b-form-group label="Message" label-for="newFlashMessageContent">
                    <b-form-input v-model="newFlashMessageContent" id="newFlashMessageContent" required></b-form-input>
                </b-form-group>
                <b-form-group label="Thème" label-for="newFlashMessageType">
                    <b-form-select v-model="newFlashMessageType" :options="newFlashMessageTypeOptions" id="newFlashMessageType" required></b-form-select>
                </b-form-group>
            </b-modal>
        </template>

        <b-toaster name="b-toaster-top-center" role="alert"></b-toaster>
        <div class="d-none">
            <template v-for="(flashes, type) in flashes">
                <template v-if="flashMessageTypes[type]">
                    <b-toast
                        v-for="(flash, index) in flashes"
                        :key="type + '-' + index"
                        :auto-hide-delay="flash.autoHideDelay || flashMessageTypes[type].autoHideDelay"
                        :no-auto-hide="flash.autoHideDelay === 0"
                        solid
                        toaster="b-toaster-top-center"
                        :variant="flashMessageTypes[type].style"
                        visible
                    >
                        <template #toast-title>
                            <div class="d-flex flex-grow-1 align-items-baseline">
                                <strong class="mr-auto">{{ flashMessageTypes[type].label }}</strong>
                            </div>
                        </template>
                        {{ flash.flash }}
                    </b-toast>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import FlashMessageMixin from "../../Mixins/FlashMessageMixin";

export default {
    data: function () {
        return {
            flashes: {
                welcome: [],
                success: [],
                error: [],
                warning: [],
                info: [],
            },
            webSocketOnline: false,
        };
    },
    mixins: [FlashMessageMixin],
    props: {
        roleBroadcast: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        let data = JSON.parse(this.$el.dataset.data);
        for (let key in data) {
            this.flashes[key].push(...data[key].map(f => ({
                flash: f,
            })));
        }
        document.addEventListener('app.flash', (event) => {
            if (event.detail && event.detail.type && event.detail.flash) {
                this.flashes[event.detail.type || 'info'].push(event.detail);
            }
        });
        if (typeof webSocket !== 'undefined') {
            document.addEventListener('websocket', (e) => {
                this.webSocketOnline = e.detail.online;
            });
            webSocket.subscribe('broadcast', (url, data) => {
                if (data.flash) {
                    let type = data.type && this.flashes[data.type] ? data.type : 'info';
                    document.dispatchEvent(new CustomEvent('app.flash', {detail: {
                        type: type,
                        flash: data.flash,
                        autoHideDelay: 0,
                    }}));
                }
            });
        }
    },
    methods: {
        broadcast() {
            if (this.webSocketOnline && (this.newFlashMessageContent || '').trim()) {
                webSocket.publish('broadcast', {
                    type: this.newFlashMessageType,
                    flash: this.newFlashMessageContent.trim(),
                });
                this.newFlashMessageContent = null;
                this.newFlashMessageType = 'info';
            }
        },
    },
}
</script>