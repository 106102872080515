import Vue from 'vue';
import Router from '../utils/Router';
import Request from '../utils/Request';
import moment from 'moment';
import DevenirMixin from "../vue/Mixins/DevenirMixin";
import ModalRegulationMixin from "../vue/Mixins/ModalRegulationMixin";
import ShortcutMixin from "../vue/Mixins/ShortcutMixin";
import {
    APPEL_DEMANDE,
    APPEL_DEMANDE_ATTR,
    APPEL_ZONE,
    APPEL_ZONE_ATTR,
    DEFAULT_ATTR,
    HORODATAGE_ATTR,
    HORODATAGE_FORMATTER,
    HORODATAGE_SORT_FORMATTER
} from "./BootstrapVueHelpers";

Vue.config.productionTip = false;

export default class {
    constructor() {
        new Vue({
            el: '#app',
            delimiters: ['[[', ']]'],
            mixins: [DevenirMixin, ModalRegulationMixin, ShortcutMixin],
            data: {
                apercuPieceJointe: null,
                appelChoices: null,
                appels: [],
                appelSkeleton: null,
                archives: false,
                autreTypeDecision: null,
                csrf: {sms: null},
                currentAppel: null,
                currentAppelErrors: {},
                currentAppelLocked: true,
                currentAppelSaving: false,
                currentAppelUnlocking: false,
                currentDecision: null,
                currentDecisionErrors: {},
                currentDecisionSaving: false,
                currentInformation: null,
                decisionChoices: null,
                decisionSkeleton: null,
                decisionTypeApDelai: null,
                decisionTypeApMoyen: null,
                decisionTypeMgDelai: null,
                decisionTypeMgMoyen: null,
                decisionTypeSmur: null,
                decisionTypeVsav: null,
                editPieceJointeCollection: {},
                expanded: null,
                garde: null,
                gardeDateHeure: null,
                gardeMatchOnly: true,
                historique: null,
                informationChoices: null,
                informations: {},
                loadingAppel: false,
                loadingGarde: false,
                missions: [],
                modal: null,
                modeles: [],
                newDecision: null,
                newDecisionChoices: null,
                newDecisionErrors: {},
                newDecisionSaving: false,
                newDecisionSkeleton: null,
                newObservation: {contenu: null, numeroPatient: null, nomUsuel: null, prenomUsuel: null},
                newObservationChoices: null,
                newObservationErrors: {},
                newObservationSaving: false,
                newObservationSkeleton: null,
                newPieceJointeCollection: [],
                parametres: {},
                patientAgeUniteDatalist: null,
                patientSexeDatalist: null,
                roles: null,
                searchCurrentPage: 1,
                searchFields: [
                    { key: 'numero', label: '#', headerTitle: 'Numéro', tdClass: 'width-1px', sortable: true },
                    { key: 'horodatage', label: 'HAp', headerTitle: 'Heure d\'appel', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                    { key: 'regulateur', label: '', tdClass: 'width-1px', sortable: false },
                    { key: '_metaLieu', label: 'Lieu', tdClass: 'text-ellipsis', thStyle: { 'min-width': '100px' }, sortable: false, tdAttr: DEFAULT_ATTR },
                    { key: '_metaNom', label: 'Nom', tdClass: 'text-ellipsis', thStyle: { 'min-width': '100px' }, sortable: false, tdAttr: DEFAULT_ATTR },
                    { key: 'demande.libelle', label: 'Demande', tdClass: 'text-ellipsis', sortable: true, thStyle: { 'min-width': '100px' }, formatter: APPEL_DEMANDE, tdAttr: APPEL_DEMANDE_ATTR },
                    { key: 'zone.libelle', label: 'Zone', tdClass: 'text-nowrap width-1px', sortable: true, formatter: APPEL_ZONE, tdAttr: APPEL_ZONE_ATTR },
                    { key: '_metaObservation', label: 'Observation', tdClass: 'text-ellipsis', thStyle: { 'min-width': '100px' }, sortable: false, tdAttr: DEFAULT_ATTR },
                    { key: 'affaire.etat.libelle', label: 'Etat', tdClass: 'text-nowrap width-1px', sortable: true },
                    { key: 'affaire._estVerrouille', label: '', tdClass: 'width-1px' },
                ],
                searchForm: {},
                searchItemNumberPerPage: null,
                searchItems: null,
                searchLoading: false,
                searchTotalItemCount: 0,
                selectAppelArgs: [],
                shortcuts: {
                    // Communs à régulation et archives
                    'control-arrowdown': {
                        description: 'Patient suivant.',
                        handler: 'onShortcutPatientSuivant',
                    },
                    'control-arrowup': {
                        description: 'Patient précédent.',
                        handler: 'onShortcutPatientPrecedent',
                    },
                    'control-arrowleft': {
                        description: 'Onglet patient précédent.',
                        handler: 'onShortcutOngletPatientPrecedent',
                    },
                    'control-arrowright': {
                        description: 'Onglet patient suivant.',
                        handler: 'onShortcutOngletPatientSuivant',
                    },
                },
                toutAcquitterDisabled: false,
                utilisateur: null,
                webSocketOnline: false,
                zones: null,
                zonesParDefaut: null,
            },
            beforeMount() {
                const data = JSON.parse(this.$el.dataset.data);

                for (let key in data) {
                    this[key] = data[key];
                }

                if (!this.archives) {
                    this.shortcuts['control-enter'] = {
                        description: 'Déverrouiller ou sauvegarder le dossier.',
                        handler: 'onShortcutVerrouillage',
                    };
                    this.shortcuts['doubleclic'] = {
                        description: 'Sur un champ de saisie : déverrouille le dossier et place le curseur.',
                    };
                }

                if (this.currentAppel && this.currentAppel.numero) {
                    if (this.currentAppel.patientCollection[0]) {
                        this.currentAppel._patient = this.currentAppel.patientCollection[0];
                    } else {
                        this.addPatient();
                    }
                } else {
                    this.clearAppel();
                }

                window.addEventListener('beforeunload', this.onUnload);
                document.addEventListener('websocket', (e) => {
                    this.webSocketOnline = e.detail.online;
                });
                window.addEventListener('popstate', (event) => {
                    if (event.state && event.state.appel) {
                        this.selectAppel(event.state.appel);
                    } else if (event.state && null == event.state.appel) {
                        this.clearAppel();
                    }
                });

                !this.archives && webSocket.subscribe('liste', (url, data) => {
                    if ('appels' === data.type) {
                        this.appels = data.data;
                    } else if ('missions' === data.type) {
                        this.missions = data.data;
                    } else if ('informations' === data.type) {
                        this.informations = data.data;
                        if (this.currentInformation && data.data[this.currentInformation.chapitre || '']) {
                            const currentInformation = data.data[this.currentInformation.chapitre || '']
                                .find(i => i.titre === this.currentInformation.titre || (i.titre.match(/Crédits SMS :/i) && this.currentInformation.titre.match(/Crédits SMS :/i)));
                            if (currentInformation) {
                                this.openInformation(currentInformation);
                            } else {
                                this.currentInformation = null;
                            }
                        }
                    }
                });
            },
            mounted() {
                this.$el.classList.remove('invisible');
                document.addEventListener('app.selectAppel', (event) => {
                    event.detail && event.detail.numero && this.selectAppel(event.detail.numero, true);
                });
                document.addEventListener('app.createAppel', (event) => {
                    if (event.detail && event.detail.numero) {
                        this.modal = null;
                        this.clearAppel();
                        this.currentAppel.telephone = event.detail.numero;
                    }
                });
                document.addEventListener('dblclick', this.onShortcutFocus);
                if (this.archives && (!this.currentAppel || !this.currentAppel.numero)) {
                    this.openSearch();
                }
            },
            beforeDestroy() {
                window.removeEventListener('beforeunload', this.onUnload);
            },
            methods: {
                toutAcquitter() {
                    if (this.archives) {
                        return;
                    }
                    this.toutAcquitterDisabled = true;

                    Request.fetchJson(Router.generate('mission.sos-medecin.tout-acquitter', { appel: this.currentAppel.numero }))
                        .then(data => {
                            Vue.set(this.currentAppel, 'decisionCollection', data.appel.decisionCollection);
                        })
                        .finally(() => {
                            this.toutAcquitterDisabled = false;
                        })
                    ;
                },
                copyJSON() {
                    let decisionRows = this.decisionRows(this.currentAppel.decisionCollection);
                    let data = {
                        date: this.currentAppel.horodatage.date,
                        heure: this.currentAppel.horodatage.heure,
                        dossier: this.currentAppel.numero,
                        telephone_appelant: this.currentAppel.telephone,
                        insee: this.currentAppel.primaire ? this.currentAppel.primaire.codeInsee : null,
                        rue_num: this.currentAppel.primaire ? this.currentAppel.primaire.adresseNumero : null,
                        telephone_dom: this.currentAppel.primaire ? this.currentAppel.primaire.telephone : null,
                        appelant: this.currentAppel.typeAppel ? this.currentAppel.typeAppel.libelle : null,
                        motif: this.currentAppel.typeEvenement ? this.currentAppel.typeEvenement.libelle : null,
                        cp: null,
                        commune: this.currentAppel.primaire ? this.currentAppel.primaire.ville : null,
                        rue_lib: this.currentAppel.primaire ? this.currentAppel.primaire.adresse : null,
                        nom: this.currentAppel._patient ? this.currentAppel._patient.nomUsuel : null,
                        prenom: this.currentAppel._patient ? this.currentAppel._patient.prenomUsuel : null,
                        age_valeur: this.currentAppel._patient ? this.currentAppel._patient.age : null,
                        age_unite: this.currentAppel._patient ? this.currentAppel._patient.ageUnite : null,
                        diag_code: this.currentAppel._patient && this.currentAppel._patient.etioPpal ? this.currentAppel._patient.etioPpal.code : null,
                        diag_lib: this.currentAppel._patient && this.currentAppel._patient.etioPpal ? this.currentAppel._patient.etioPpal.libelle : null,
                        demande_lib: decisionRows.length && decisionRows[decisionRows.length - 1].items.length ? decisionRows[decisionRows.length - 1].items[decisionRows[decisionRows.length - 1].items.length - 1].mission : null
                    };
                    this.copyPaste('APPLIGOS_SAMU' + JSON.stringify(data));
                },
                copyPaste(text) {
                    let el = document.createElement('div');
                    let textNode = document.createTextNode(text);
                    el.appendChild(textNode);
                    document.body.appendChild(el);

                    try {
                        if (document.body.createTextRange) {
                            var textRange = document.body.createTextRange();
                            textRange.moveToElementText(el);
                            textRange.select();
                            textRange.execCommand('Copy');
                        } else if (window.getSelection && document.createRange) {
                            var range = document.createRange();
                            range.selectNodeContents(el);
                            var sel = window.getSelection();
                            sel.removeAllRanges();
                            sel.addRange(range);
                            document.execCommand('copy');
                        }
                    } catch (error) {}
                    el.remove();
                },
                openSearch() {
                    this.searchForm.archives = this.archives;
                    this.modal = 'search';

                    if (null === this.searchItems) {
                        this.$nextTick(() => this.refreshSearch());
                    }
                },
                decisionRows(decisions) {
                    return decisions.map(decision => {
                        let items = [];

                        for (const mission of decision.missionCollection) {
                            items.push({
                                mission: mission.organisme ? mission.organisme.libelle : '',
                                moyen: mission.moyenAutre,
                                estSMUR: mission.estSMUR,
                                estVSAB: mission.estVSAB,
                                estATSU: mission.estATSU,
                                estMG: mission.estMG,
                                estSOSMedecin: mission.estSOSMedecin,
                                enAttenteDeMoyen: mission.enAttenteDeMoyen,
                            });
                        }

                        for (const element of decision.elementCollection) {
                            for (const complement of element.complementCollection) {
                                items.push({
                                    mission: element.element,
                                    moyen: complement
                                });
                            }
                        }

                        return {
                            decision,
                            items
                        };
                    });
                },
                openDecision(decision) {
                    if (this.archives) {
                        return;
                    }
                    this.currentDecision = JSON.parse(JSON.stringify(decision));
                    this.currentDecisionSaving = false;
                    this.currentDecisionErrors = {};

                    this.modal = 'decision';
                },
                addDecision() {
                    if (this.archives) {
                        return;
                    }
                    const now = moment();
                    this.newDecision = JSON.parse(JSON.stringify(this.newDecisionSkeleton));
                    this.newDecision.elementCollection = [];
                    this.newDecision.horodatage = {
                        date: now.format('DD/MM/YYYY'),
                        time: now.format('HH:mm'),
                    };
                    Vue.set(this.newDecision.missionATSU, '_type', this.decisionTypeApMoyen[0] ?? null);
                    Vue.set(this.newDecision.missionATSU, '_delai', this.decisionTypeApDelai[0] ?? null);
                    Vue.set(this.newDecision.missionMG, '_type', this.decisionTypeMgMoyen[0] ?? null);
                    Vue.set(this.newDecision.missionMG, '_delai', this.decisionTypeMgDelai[0] ?? null);

                    if(this.newDecisionChoices.type) {
                        for (const item of this.newDecisionChoices.type) {
                            if ((item.code === '01704' && this.currentAppel.decisionCollection.length) || (item.code === '01701' && !this.currentAppel.decisionCollection.length)) {
                                this.newDecision.type = item;
                            }
                        }
                    }
                    this.newDecisionSaving = false;
                    this.newDecisionErrors = {};

                    this.modal = 'new-decision';
                },
                saveNewObservation() {
                    if (this.archives) {
                        return;
                    }
                    this.newObservationSaving = true;
                    if (this.currentAppel._patient && this.currentAppel._patient.numeroPatient) {
                        this.newObservation.numeroPatient = this.currentAppel._patient.numeroPatient;
                        this.newObservation.nomUsuel = this.currentAppel._patient.nomUsuel;
                        this.newObservation.prenomUsuel = this.currentAppel._patient.prenomUsuel;
                    } else {
                        this.newObservation.numeroPatient = null;
                        this.newObservation.nomUsuel = null;
                        this.newObservation.prenomUsuel = null;
                    }

                    Request.fetchJson(
                        Router.generate('appel.observation.add', { appel: this.currentAppel.numero }),
                        'POST',
                        this.normalize(JSON.parse(JSON.stringify(this.newObservationSkeleton)), this.newObservation)
                    ).then(response => {
                        if (response.success) {
                            this.newObservationErrors = {};
                            this.newObservation.contenu = '';
                            Vue.set(this.currentAppel, 'observations', response.appel.observations);

                            this.scrollObservations();
                        } else {
                            this.newObservationErrors = response.errors;
                        }
                    }, () => {
                        this.newObservationErrors = {'': ['Une erreur est survenue, les données n\'ont pas pu être enregistrées.']};
                    }).finally(() => this.newObservationSaving = false);
                },
                saveNewDecision() {
                    if (this.archives) {
                        return;
                    }
                    this.newDecisionSaving = true;

                    Request.fetchJson(
                        Router.generate('appel.decision.add', { appel: this.currentAppel.numero }),
                        'POST',
                        this.normalize(JSON.parse(JSON.stringify(this.newDecisionSkeleton)), this.newDecision)
                    ).then((response) => {
                        if (response.success) {
                            this.newDecisionErrors = {};
                            this.modal = null;
                            Vue.set(this.currentAppel, 'decisionCollection', response.appel.decisionCollection);
                        } else {
                            this.newDecisionErrors = response.errors;
                        }
                    }, () => this.newDecisionErrors = {'': ['Une erreur est survenue, les données n\'ont pas pu être enregistrées.']})
                    .finally(() => this.newDecisionSaving = false);
                },
                saveCurrentDecision() {
                    if (this.archives) {
                        return;
                    }
                    this.currentDecisionSaving = true;

                    Request.fetchJson(
                        Router.generate('appel.decision.edit', {appel: this.currentAppel.numero, decision: this.currentDecision.numeroDecision}),
                        'POST',
                        this.normalize(JSON.parse(JSON.stringify(this.decisionSkeleton)), this.currentDecision)
                    ).then((response) => {
                        if (response.success) {
                            this.currentDecisionErrors = {};
                            this.modal = null;
                            Vue.set(this.currentAppel, 'decisionCollection', response.appel.decisionCollection);
                        } else {
                            this.currentDecisionErrors = response.errors;
                        }
                    }, () => this.currentDecisionErrors = {'': ['Une erreur est survenue, les données n\'ont pas pu être enregistrées.']})
                    .finally(() => this.currentDecisionSaving = false);
                },
                onUnload(event) {
                    if (this.currentAppel && !this.currentAppelLocked && this.currentAppel.numero) {
                        event.returnValue = 'Êtes-vous sûr(e) de vouloir quitter la page ? Vous pourriez perdre des données non sauvegardées.';

                        return event.returnValue;
                    }
                },
                unlockCurrentAppel() {
                    if (this.archives) {
                        return;
                    }
                    this.currentAppelUnlocking = true;

                    webSocket.publish('affaire/' + this.currentAppel.affaire.numero, {
                        action: 'unlock',
                    });
                },
                onPatientInput() {
                    if (this.archives) {
                        return;
                    }
                    if (!this.currentAppel._patient.numeroPatient) {
                        let lastNumero = null;
                        for (const patient of this.currentAppel.patientCollection) {
                            if (patient.numeroPatient) {
                                lastNumero = patient.numeroPatient;
                            }
                        }

                        this.currentAppel._patient.numeroPatient = ((Number(lastNumero) + 1) + '').padStart(2, '0') || '01';
                    }
                },
                calculerAge() {
                    if (this.currentAppel && this.currentAppel._patient && this.currentAppel._patient.naissance) {
                        const naissance = moment(this.currentAppel._patient.naissance, 'YYYY-MM-DD');
                        const date = this.currentAppel.horodatage && this.currentAppel.horodatage.date ? moment(this.currentAppel.horodatage.date, 'DD/MM/YYYY') : moment();
                        if (naissance.isValid() && date.isValid() && naissance.isSameOrBefore(date)) {
                            date.hours(0).minutes(0).seconds(0);
                            const diffInYears = date.diff(naissance, 'years', false);
                            if (diffInYears > 0) {
                                this.currentAppel._patient.age = diffInYears;
                                this.currentAppel._patient.ageUnite = 'A';
                                return;
                            }
                            const diffInMonths = date.diff(naissance, 'months', false);
                            if (diffInMonths > 0) {
                                this.currentAppel._patient.age = diffInMonths;
                                this.currentAppel._patient.ageUnite = 'M';
                                return;
                            }
                            const diffInWeeks = date.diff(naissance, 'weeks', false);
                            if (diffInWeeks > 0) {
                                this.currentAppel._patient.age = diffInWeeks;
                                this.currentAppel._patient.ageUnite = 'S';
                                return;
                            }
                            const diffInDays = date.diff(naissance, 'days', false);
                            if (diffInDays >= 0) {
                                this.currentAppel._patient.age = diffInDays;
                                this.currentAppel._patient.ageUnite = 'J';
                            }
                        } else if (null !== this.currentAppel._patient.age) {
                            this.currentAppel._patient.age = null;
                            this.currentAppel._patient.ageUnite = null;
                        }
                    }
                },
                getPatientOptions(patientCollection) {
                    return patientCollection.map((patient) => ({
                        text: patient.numeroPatient ? patient.numeroPatient + ' - ' + (patient.nomUsuel || '') : 'Nouveau patient',
                        patient
                    }));
                },
                openInformation(information) {
                    this.currentInformation = JSON.parse(JSON.stringify(information));
                },
                listTrClass(liste) {
                    return (item) => {
                        let classes = [];

                        if (item) {
                            if (
                                ('search' === liste && this.currentAppel.numero === item.numero) ||
                                ('missionsSAS' === liste && this.currentAppel.numero === item.decision.appel.numero)
                            ) {
                                classes.push('bg-secondary');
                            }
                        }

                        if (item && ('search' === liste || this.canChangeAppel)) {
                            classes.push('cursor-pointer');
                        }

                        return classes;
                    };
                },
                toggleExpanded(panel) {
                    this.expanded = panel === this.expanded ? null : panel;
                },
                closeAppel() {
                    if (this.currentAppelLocked) {
                        this.closeAppelConfirm();
                    } else {
                        this.modal = 'close';
                    }
                },
                closeAppelConfirm() {
                    this.modal = null;
                    this.clearAppel();
                },
                clearAppel(force) {
                    if ((this.newObservation.contenu ?? '').trim().length > 0 && this.currentAppel && this.currentAppel.numero && !force) {
                        this.selectAppelArgs = [];
                        this.$bvModal.show('modal-alerte-observation');
                        return;
                    }
                    if ((this.newPieceJointeCollection || []).filter(pj => !pj.success && !pj.error && pj.active).length > 0 && this.currentAppel && this.currentAppel.numero && !force) {
                        this.selectAppelArgs = [];
                        this.$bvModal.show('modal-alerte-upload');
                        return;
                    }
                    // Si l'on ferme sans enregistrer
                    if (this.currentAppel && this.currentAppel.numero && !this.currentAppelLocked) {
                        window.dispatchEvent(new CustomEvent('appelLocked', {detail: {state: false}}));
                    }

                    this.currentAppel = JSON.parse(JSON.stringify(this.appelSkeleton));
                    this.currentAppel.decisionCollection = [];
                    this.currentAppel.observations = [];
                    this.currentAppel.horodatage = {};
                    this.currentAppel.numero = null;
                    this.currentAppel._patient = this.currentAppel.patientCollection[0];
                    this.currentAppel._patient._isNew = true;
                    this.currentAppel._patient.ageUnite = 'A';
                    this.newObservation.contenu = '';
                    this.newPieceJointeCollection = [];
                    this.editPieceJointeCollection = {};
                    if (this.$refs.upload) {
                        this.$refs.upload.clear();
                    }

                    this.currentAppelLocked = false;
                    this.currentAppelErrors = {};
                    if (window.location.pathname.match(/\/voir/)) {
                        App.Layout.pushState(
                            {appel: null, documentTitle: (this.archives ? 'Archives' : 'Régulation') + ' - AppliSAMU'},
                            Router.generate(this.archives ? 'archives' : 'regulation')
                        );
                    }
                },
                addPatient() {
                    const newPatient = JSON.parse(JSON.stringify(this.appelSkeleton.patientCollection[0]));
                    newPatient._isNew = true;
                    newPatient.ageUnite = 'A';

                    Vue.set(this.currentAppel, '_patient', newPatient);
                    this.currentAppel.patientCollection.push(newPatient);
                },
                removePatient(patient) {
                    if (this.archives) {
                        return;
                    }
                    const index = this.currentAppel.patientCollection.indexOf(patient);
                    if (index !== -1) {
                        this.currentAppel.patientCollection.splice(index, 1);
                    }

                    if (this.currentAppel.patientCollection.length) {
                        Vue.set(this.currentAppel, '_patient', this.currentAppel.patientCollection[this.currentAppel.patientCollection.length - 1]);
                    } else {
                        this.addPatient();
                    }
                },
                openPatient(indexPatient) {
                    this.loadingAppel = true;
                    Vue.set(this.currentAppel, '_patient', this.currentAppel.patientCollection[indexPatient]);
                    this.loadingAppel = false;
                },
                selectAppel(numero, force = false) {
                    if (!force && !this.canChangeAppel) {
                        return;
                    }
                    if (force) {
                        this.newObservation.contenu = '';
                    }
                    if ((this.newObservation.contenu ?? '').trim().length > 0 && this.currentAppel && this.currentAppel.numero && !force) {
                        this.selectAppelArgs = [numero, true];
                        this.$bvModal.show('modal-alerte-observation');
                        return;
                    }
                    if ((this.newPieceJointeCollection || []).filter(pj => !pj.success && !pj.error && pj.active).length > 0 && this.currentAppel && this.currentAppel.numero && !force) {
                        this.selectAppelArgs = [numero, true];
                        this.$bvModal.show('modal-alerte-upload');
                        return;
                    }

                    this.loadingAppel = true;
                    this.modal = null;
                    this.newPieceJointeCollection = [];
                    this.editPieceJointeCollection = {};
                    if (this.$refs.upload) {
                        this.$refs.upload.clear();
                    }

                    setTimeout(() => {
                        this.$refs.dossier.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                        });
                    }, 100);

                    Request.fetchJson(Router.generate(this.archives ? 'archives.view' : 'appel.view', { appel: numero }))
                        .then(data => {
                            this.currentAppel = data.appel;
                            this.currentAppelLocked = true;
                            this.currentAppelErrors = {};
                        })
                        .finally(() => {
                            this.loadingAppel = false;
                        })
                    ;
                },
                setAutreDecison(data) {
                    const elements = data.reduce((res, item) => ((res[item.element] = res[item.element] || []).push(item.code), res), {});
                    let elementCollection = [];

                    for (const element in elements) {
                        elementCollection.push({
                            element,
                            complementCollection: elements[element].map(complement => ({ complement }))
                        })
                    }

                    Vue.set(this.newDecision, 'elementCollection', elementCollection);
                },
                saveCurrentAppel() {
                    if (this.archives) {
                        return;
                    }
                    this.currentAppelSaving = true;
                    let data = this.normalize(JSON.parse(JSON.stringify(this.appelSkeleton)), this.currentAppel);
                    for (const index in data.patientCollection) {
                        if (!data.patientCollection[index].numeroPatient) {
                            data.patientCollection.splice(index, 1);
                        }
                    }

                    if (this.currentAppel.affaire.numero) {
                        delete data._token; // Session indisponible et protection csrf inutile dans la connexion WS
                        webSocket.publish('affaire/' + this.currentAppel.affaire.numero, {
                            action: 'update',
                            data
                        });
                    } else {
                        Request.fetchJson(Router.generate('appel.new'), 'POST', data)
                        .then((response) => {
                            if (response.success) {
                                this.currentAppelErrors = {};
                                this.currentAppelLocked = true;
                                this.currentAppel = response.appel;
                            } else {
                                this.currentAppelErrors = response.errors;
                            }
                        }, () => this.currentAppelErrors = {'': ['Une erreur est survenue, les données n\'ont pas pu être enregistrées.']})
                        .finally(() => this.currentAppelSaving = false);
                    }
                },
                applyObservationModele(modele) {
                    let content = this.newObservation.contenu || '';
                    let initialLength = content.length;

                    if (content !== '') {
                        initialLength += 2
                        content += '\n\n';
                    }

                    content += modele.texte;
                    this.newObservation.contenu = content;
                },
                normalize(base, data) {
                    let newData = {};

                    for (const key in base) {
                        const baseValue = base[key];
                        const value = data[key];

                        if ('_token' === key) {
                            newData[key] = baseValue;
                        } else if (value && 'object' === typeof value && value.hasOwnProperty('code') && value.hasOwnProperty('libelle')) {
                            newData[key] = value.code;
                        } else if (Array.isArray(baseValue) && baseValue[0]) {
                            newData[key] = value.map((item) => this.normalize(baseValue[0], item));
                        } else if (value && baseValue !== null && typeof baseValue === 'object') {
                            newData[key] = this.normalize(base[key], value);
                        } else {
                            newData[key] = value
                        }
                    }

                    return newData;
                },
                clearSearch() {
                    this.searchForm = {};
                    this.searchCurrentPage = 1;

                    this.refreshSearch();
                },
                refreshSearch() {
                    this.searchLoading = true;
                    let params = { page: this.searchCurrentPage, appel_filter: this.searchForm };

                    const context = this.$refs.searchTable.context;
                    if (context.sortBy) {
                        params.direction = context.sortDesc ? 'desc' : 'asc';
                        params.ordre = ('a.' + context.sortBy).split('.').slice(-2).join('.');
                    }

                    Request.fetchJson(Router.generate(this.searchForm.archives ? 'archives.search' : 'appel.search', params))
                        .then(data => {
                            this.searchCurrentPage = data.currentPage;
                            this.searchTotalItemCount = data.totalItemCount;
                            this.searchItemNumberPerPage = data.itemNumberPerPage;
                            this.searchItems = data.items;
                        })
                        .finally(() => {
                            this.searchLoading = false;
                        })
                    ;
                },
                onSearchDateInput(value) {
                    if (null === value) {
                        this.refreshSearch();
                    }
                },
                onSearchDateClose(value) {
                    if (null !== value) {
                        this.refreshSearch();
                    }
                },
                presetSearchDate() {
                    Vue.set(this.searchForm, 'debut', moment().subtract(1, 'hour').format('DD/MM/YYYY HH:mm'));
                    Vue.set(this.searchForm, 'fin', moment().add(1, 'hour').format('DD/MM/YYYY HH:mm'));

                    this.refreshSearch();
                },
                copySearchDate() {
                    Vue.set(this.searchForm, 'fin', moment(this.searchForm.debut, 'DD/MM/YY HH:mm').add(1, 'day').format('DD/MM/YYYY HH:mm'));

                    this.refreshSearch();
                },
                onCurrentAppelTypeaheadSelect(field, data) {
                    if (this.archives) {
                        return;
                    }
                    if ('primaire.ville' === field) {
                        Vue.set(this.currentAppel.primaire, 'ville', data.libelle);
                        Vue.set(this.currentAppel.primaire, 'codeInsee', data.codeInsee);
                    } else if ('primaire.adresse' === field) {
                        Vue.set(this.currentAppel.primaire, 'ville', data.ville);
                        Vue.set(this.currentAppel.primaire, 'adresse', data.libelleComplet || data.libelle);
                        Vue.set(this.currentAppel.primaire, 'codeInsee', data.codeInsee);
                    } else if ('primaire.lieuDit' === field) {
                        Vue.set(this.currentAppel.primaire, 'ville', data.ville);
                        Vue.set(this.currentAppel.primaire, 'lieuDit', data.libelle);
                        Vue.set(this.currentAppel.primaire, 'codeInsee', data.codeInsee);
                        Vue.set(this.currentAppel.primaire, 'adresseNumero', data.noVoie);
                        Vue.set(this.currentAppel.primaire, 'adresse', data.voie);
                        Vue.set(this.currentAppel.primaire, 'telephone', data.telephone1);
                        Vue.set(this.currentAppel.primaire, 'observation', [
                            data.observationCommune ? '[c:' + data.observationCommune.trim() + ']' : null,
                            data.observation || data.telephone2 ? '{l:' + [data.telephone2 ? data.telephone2.trim() : null, data.observation ? data.observation.trim() : null].filter(v => v).join(' ') + '}' : null,
                        ].filter(v => v).join(' '));
                    } else {
                        for (const type of['PriseEnCharge', 'Destination']) {
                            if ('secondaire.adresse1' + type === field) {
                                Vue.set(this.currentAppel.secondaire, 'ville' + type, data.ville);
                                Vue.set(this.currentAppel.secondaire, 'codePostal' + type, data.codePostal);
                                Vue.set(this.currentAppel.secondaire, 'codeInsee' + type, data.codeInsee);
                                Vue.set(this.currentAppel.secondaire, 'adresse1' + type, data.libelleComplet || data.libelle);
                            } else if ('secondaire.codePostal' + type === field || 'secondaire.ville' + type === field) {
                                Vue.set(this.currentAppel.secondaire, 'ville' + type, data.libelle);
                                Vue.set(this.currentAppel.secondaire, 'codePostal' + type, data.codePostal);
                                Vue.set(this.currentAppel.secondaire, 'codeInsee' + type, data.codeInsee);
                            } else if ('secondaire.etablissementLibelle' + type === field) {
                                Vue.set(this.currentAppel.secondaire, 'etablissementLibelle' + type, (data.libelle ?? '').substring(0, 30));
                                Vue.set(this.currentAppel.secondaire, 'etablissement' + type, data.code);
                                Vue.set(this.currentAppel.secondaire, 'ville' + type, data.ville);
                                Vue.set(this.currentAppel.secondaire, 'codeInsee' + type, data.codeInsee);
                                Vue.set(this.currentAppel.secondaire, 'codePostal' + type, data.codePostal);
                                Vue.set(this.currentAppel.secondaire, 'adresse1' + type, (data.adresse1 ?? '').substring(0, 30));
                                Vue.set(this.currentAppel.secondaire, 'telephone1' + type, data.telephone);
                            } else if ('secondaire.serviceLibelle' + type === field) {
                                Vue.set(this.currentAppel.secondaire, 'serviceLibelle' + type, data.libelle);
                                Vue.set(this.currentAppel.secondaire, 'telephone2' + type, data.telephone);
                                Vue.set(this.currentAppel.secondaire, 'contact' + type, data.contact);
                            }
                        }
                    }
                },
                onCurrentAppelTypeaheadInput(field) {
                    if (this.archives) {
                        return;
                    }
                    if ('primaire.ville' === field) {
                        Vue.set(this.currentAppel.primaire, 'codeInsee', null);
                    } else {
                        for (const type of['PriseEnCharge', 'Destination']) {
                            if ('secondaire.adresse1' + type === field || 'secondaire.codePostal' + type === field || 'secondaire.ville' + type === field || 'secondaire.etablissementLibelle' + type === field) {
                                Vue.set(this.currentAppel.secondaire, 'codeInsee' + type, null);
                            }
                        }
                    }
                },
                showGarde(date = null) {
                    if (this.archives) {
                        return;
                    }
                    if(!date) {
                        this.gardeDateHeure = moment().format('DD/MM/YYYY HH:mm');
                    }

                    this.loadingGarde = true;
                    const insee = this.currentAppel && this.currentAppel.primaire && this.currentAppel.primaire.codeInsee ? this.currentAppel.primaire.codeInsee : '';
                    Request.fetchJson(Router.generate('garde.encours', {insee: insee, date}))
                        .then(data => {
                            this.garde = data;

                            this.modal = 'garde';
                        })
                        .finally(() => {
                            this.loadingGarde = false;
                        })
                    ;
                },
                showHistorique() {
                    if(this.currentAppel.numero) {
                        this.loadingAppel = true;

                        Request.fetchJson(Router.generate(this.archives ? 'archives.historique' : 'appel.historique', { appel: this.currentAppel.numero }))
                            .then(data => {
                                this.historique = data;

                                this.modal = 'historique';
                            })
                            .finally(() => {
                                this.loadingAppel = false;
                            })
                        ;
                    }
                },
                scrollObservations() {
                    setTimeout(() => {
                        const obsList = document.getElementById('observations_list');
                        if(obsList) {
                            obsList.scrollTo(0, obsList.scrollHeight);
                        }
                    }, 100);
                },
                inputFile(newFile, oldFile) {
                    if (this.archives) {
                        return;
                    }
                    if (newFile && !this.$refs.upload.active) {
                        // Upload dès le dépôt du fichier
                        this.$refs.upload.active = true;
                    }
                    if (newFile && newFile.error && !newFile.formErrors) {
                        switch (newFile.error) {
                            case 'extension':
                                return this.$refs.upload.update(newFile, {formErrors: {file: ['Extension non prise en charge.']}});
                            case 'size':
                                return this.$refs.upload.update(newFile, {formErrors: {file: ['Le fichier est trop volumineux (' + parseInt(newFile.size).toReadableSize() + '). Sa taille ne doit pas dépasser ' + parseInt(this.$refs.upload.size).toReadableSize() + '.']}});
                            case 'timeout':
                                return this.$refs.upload.update(newFile, {formErrors: {file: ['Temps d\'attente dépassé.']}});
                            case 'abort':
                                return this.$refs.upload.update(newFile, {formErrors: {file: ['Téléchargement interrompu.']}});
                            case 'network':
                                return this.$refs.upload.update(newFile, {formErrors: {file: ['Erreur réseau.']}});
                            case 'server':
                                return this.$refs.upload.update(newFile, {formErrors: {file: ['Une erreur est survenue.']}});
                        }
                    }
                    if (
                        newFile
                        && oldFile
                        && !newFile.active
                        && oldFile.active
                        && newFile.response
                    ) {
                        // Transfert terminé
                        if (
                            newFile.response.success
                            && newFile.response.appel
                            && this.currentAppel
                            && this.currentAppel.numero === newFile.response.appel.numero
                        ) {
                            // Great success
                            this.currentAppel.pieceJointeCollection = newFile.response.appel.pieceJointeCollection;
                            setTimeout(() => this.$refs.upload.remove(newFile), 1500);
                        } else if (newFile.response.errors) {
                            // Form errors
                            this.$refs.upload.update(newFile, {formErrors: newFile.response.errors});
                        }
                    }
                },
                editPieceJointe(pieceJointe) {
                    if (this.archives) {
                        return;
                    }
                    Vue.set(this.editPieceJointeCollection, pieceJointe.code, JSON.parse(JSON.stringify({
                        libelleCourt: pieceJointe.libelleCourt,
                        observation: pieceJointe.observation,
                        _token: this.csrf.pieceJointeEdit,
                    })));
                },
                cancelEditPieceJointe(code) {
                    Vue.delete(this.editPieceJointeCollection, code);
                },
                errorEditPieceJointe(code, errors) {
                    Vue.set(this.editPieceJointeCollection[code], 'errors', errors);
                },
                onShortcutVerrouillage() {
                    if (
                        !this.archives
                        && this.roles.ROLE_DOSSIER_EDIT
                        && this.webSocketOnline
                        && null !== this.currentAppel.numero
                        && this.currentAppelLocked
                        && !this.currentAppel.affaire._estVerrouille
                        && !this.modal
                        && !this.loadingAppel
                    ) {
                        this.unlockCurrentAppel();
                        this.$refs.observationPatient.$el.focus();
                        this.$refs.observationPatient.$el.selectionStart = this.$refs.observationPatient.$el.value.length;
                    } else if (!this.appelFieldsLocked && !this.modal && !this.loadingAppel && this.webSocketOnline) {
                        this.saveCurrentAppel();
                    }
                },
                onShortcutPatientSuivant() {
                    if (
                        !this.modal
                        && this.currentAppel
                        && this.currentAppel._patient
                        && this.currentAppel.patientCollection
                        && this.currentAppel.patientCollection.length > 1
                    ) {
                        const current = this.currentAppel.patientCollection.findIndex(v => v === this.currentAppel._patient);
                        if (current !== -1 && current < this.currentAppel.patientCollection.length - 1) {
                            this.openPatient(current + 1);
                        }
                    }
                },
                onShortcutPatientPrecedent() {
                    if (
                        !this.modal
                        && this.currentAppel
                        && this.currentAppel._patient
                        && this.currentAppel.patientCollection
                        && this.currentAppel.patientCollection.length > 1
                    ) {
                        const current = this.currentAppel.patientCollection.findIndex(v => v === this.currentAppel._patient);
                        if (current !== -1 && current > 0) {
                            this.openPatient(current - 1);
                        }
                    }
                },
                onShortcutOngletPatientPrecedent() {
                    let index = null;
                    const nodes = this.$refs.ongletsPatient.$el.querySelectorAll('.nav-link');
                    for (let i = nodes.length - 1; i >= 0; i--) {
                        if (index !== null) {
                            nodes[i].click();
                            return;
                        }
                        if (nodes[i].classList.contains('active')) {
                            index = i;
                        }
                    }
                },
                onShortcutOngletPatientSuivant() {
                    let index = null;
                    const nodes = this.$refs.ongletsPatient.$el.querySelectorAll('.nav-link');
                    for (let i = 0; i < nodes.length; i++) {
                        if (index !== null) {
                            nodes[i].click();
                            return;
                        }
                        if (nodes[i].classList.contains('active')) {
                            index = i;
                        }
                    }
                },
                onShortcutFocus(event) {
                    if (
                        !this.archives
                        && this.roles.ROLE_DOSSIER_EDIT
                        && this.webSocketOnline
                        && null !== this.currentAppel.numero
                        && this.currentAppelLocked
                        && !this.currentAppel.affaire._estVerrouille
                        && !this.modal
                        && !this.loadingAppel
                        && event.target
                        && (event.target.readOnly || event.target.disabled || event.target.closest('.vs--disabled'))
                    ) {
                        this.unlockCurrentAppel();
                        let vSelect = event.target.closest('.v-select');
                        if (vSelect) {
                            setTimeout(() => vSelect.querySelector('.vs__search').focus(), 150);
                        } else {
                            event.target.focus();
                            if('undefined' !== typeof event.target.selectionStart && event.target.selectionStart !== null) {
                                event.target.selectionStart = event.target.value ? event.target.value.length : 0;
                            }
                        }
                    }
                },
                selectAppelFromSearch(numero) {
                    if (!this.archives && this.searchForm.archives) {
                        window.location.href = Router.generate('archives.voir', {numero});
                    } else if (this.archives && !this.searchForm.archives) {
                        window.location.href = Router.generate('regulation.voir', {numero});
                    } else {
                        this.selectAppel(numero, true);
                    }
                },
                updateTopHoraireMission($event) {
                    for (let i = 0; i < this.missions.length; i++) {
                        if (
                            this.missions[i].decision.appel.numero === $event.numeroAppel
                            && this.missions[i].decision.numeroDecision === $event.numeroDecision
                            && this.missions[i].numeroMission === $event.numeroMission
                        ) {
                            if ('topDestinationArriveeReel' === $event.field) {
                                if ($event.estSecondaire) {
                                    Vue.set(this.missions[i].decision.appel.secondaire, 'topDestinationArriveeReel', $event.top ? {
                                        date: new Intl.DateTimeFormat('fr').format($event.top),
                                        heure: new Intl.DateTimeFormat('fr', {hour: 'numeric', minute: 'numeric'}).format($event.top),
                                    } : null);
                                } else {
                                    Vue.set(this.missions[i].devenirData[$event.indexDevenir], 'topDestinationArriveeReel', $event.top ? {
                                        date: new Intl.DateTimeFormat('fr').format($event.top),
                                        heure: new Intl.DateTimeFormat('fr', {hour: 'numeric', minute: 'numeric'}).format($event.top),
                                    } : null);
                                }
                            } else {
                                Vue.set(this.missions[i], $event.field, $event.top ? {
                                    date: new Intl.DateTimeFormat('fr').format($event.top),
                                    heure: new Intl.DateTimeFormat('fr', {hour: 'numeric', minute: 'numeric'}).format($event.top),
                                } : null);
                            }
                            break;
                        }
                    }
                },
            },
            watch: {
                currentAppelSaving: Request.debounce(function (newValue) {
                    if (newValue && this.currentAppelSaving) {
                        this.currentAppelSaving = false;
                        document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: 'Délai d\'attente dépassé, veuillez réessayer.'}}));
                    }
                }, 15000),
                currentAppelUnlocking: Request.debounce(function (newValue) {
                    if (newValue && this.currentAppelUnlocking) {
                        this.currentAppelUnlocking = false;
                        document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: 'Délai d\'attente dépassé, veuillez réessayer.'}}));
                    }
                }, 15000),
                searchCurrentPage() {
                    this.refreshSearch();
                },
                webSocketOnline() {
                    if (!this.webSocketOnline) {
                        if (this.currentAppel.affaire.numero) {
                            this.currentAppelLocked = true;
                            this.currentAppelSaving = false;
                            this.currentAppelUnlocking = false;
                            this.currentAppelErrors = {};
                        }
                    }
                },
                currentAppel(newAppel, oldAppel) {
                    if (this.archives && (!newAppel || !newAppel.numero)) {
                        this.openSearch();
                    }
                    if (!this.archives && oldAppel && oldAppel.affaire.numero && (!newAppel || newAppel.affaire.numero !== oldAppel.affaire.numero)) {
                        webSocket.unsubscribe('affaire/' + oldAppel.affaire.numero);
                    }

                    if (newAppel.affaire.numero && (!oldAppel || newAppel.affaire.numero !== oldAppel.affaire.numero)) {
                        (!window.history.state || window.history.state.appel !== newAppel.numero) && App.Layout.pushState(
                            {appel: newAppel.numero, documentTitle: '#' + newAppel.numero + ' - ' + (this.archives ? 'Archives' : 'Régulation') + ' - AppliSAMU'},
                            Router.generate(this.archives ? 'archives.voir' : 'regulation.voir', {numero: newAppel.numero})
                        );
                        !this.archives && webSocket.subscribe('affaire/' + newAppel.affaire.numero, (url, data) => {
                            if ('appel' === data.action) {
                                this.currentAppel = data.data;
                            } else if ('unlock' === data.action) {
                                this.currentAppelUnlocking = false;

                                if ('failed' === data.status) {
                                    this.currentAppelErrors = {'': ['Une erreur est survenue.']};
                                } else if (data.success) {
                                    this.currentAppelLocked = false;
                                    this.currentAppelErrors = {};
                                }
                            } else if ('update' === data.action) {
                                this.currentAppelSaving = false;

                                if ('success' === data.status || 'failed' === data.status) {
                                    this.currentAppelErrors = {};
                                    this.currentAppelLocked = true;
                                    if (data.appel) {
                                        this.currentAppel = data.appel;
                                    }
                                } else if ('errors' === data.status) {
                                    this.currentAppelErrors = data.errors;
                                }

                                if ('failed' === data.status) {
                                    this.currentAppelErrors = {'': ['Une erreur est survenue, les données n\'ont pas pu être enregistrées.']};
                                }
                            }
                        });
                    }

                    if (oldAppel && newAppel.affaire.numero === oldAppel.affaire.numero) {
                        const oldPatientIndex = oldAppel.patientCollection.indexOf(oldAppel._patient);

                        if (oldPatientIndex in newAppel.patientCollection) {
                            Vue.set(newAppel, '_patient', newAppel.patientCollection[oldPatientIndex]);
                        }
                    }

                    if (!newAppel._patient) {
                        if (newAppel.patientCollection[0]) {
                            Vue.set(newAppel, '_patient', newAppel.patientCollection[0]);
                        } else {
                            this.addPatient();
                        }
                    }

                    if (!oldAppel || oldAppel.numero !== newAppel.numero) {
                        this.scrollObservations();
                    }
                },
                gardeDateHeure(value) {
                    this.showGarde(value);
                },
                currentAppelLocked(newValue) {
                    if (this.currentAppel && this.currentAppel.numero) {
                        // Appel verrouillé POUR l'utilisateur != appel verrouillé PAR l'utililsateur
                        window.dispatchEvent(new CustomEvent('appelLocked', {detail: {state: !newValue}}));
                    }
                },
            },
            computed: {
                appelFieldsLocked() {
                    return this.currentAppelLocked || (!this.roles.ROLE_DOSSIER_NEW && null === this.currentAppel.numero);
                },
                patientFieldsLocked() {
                    return this.appelFieldsLocked || (!this.roles.ROLE_DOSSIER_PATIENT_NEW && !this.currentAppel._patient.numeroPatient);
                },
                canChangeAppel() {
                    return null === this.modal && (this.currentAppelLocked || !this.currentAppel.numero);
                },
                canAddPatient() {
                    for (const patient of this.currentAppel.patientCollection) {
                        if (!patient.numeroPatient) {
                            return false;
                        }
                    }

                    return true;
                },
                disableActions() {
                    return this.modal || this.loadingAppel || this.toutAcquitterDisabled;
                },
                urlCarto() {
                    if (this.roles.VOTE_MODULE_CARTO || this.parametres.CTOW_AD) {
                        let domain = '';
                        let query = '';
                        if (!this.roles.VOTE_MODULE_CARTO) {
                            domain = (this.parametres.CTOW_PROTOCOLE ? this.parametres.CTOW_PROTOCOLE + ':' : window.location.protocol)
                                + '//'
                                + (this.parametres.CTOW_AD)
                            ;
                            if (this.currentAppel && (!this.currentAppel.geolocalisation || !this.currentAppel.geolocalisation.longitude) && this.currentAppel.primaire && this.currentAppel.primaire.ville) {
                                query += '?' + (new URLSearchParams({
                                    q: [
                                        this.currentAppel.primaire.adresseNumero,
                                        this.currentAppel.primaire.adresse,
                                        this.currentAppel.primaire.ville,
                                    ].filter(v => !!v).join(' ')
                                })).toString();
                            }
                        }

                        return domain
                            + Router.generate('carto.dossier', {
                                session: 'asw',
                                dossier: this.currentAppel ? (this.currentAppel.numero || '0') : '0',
                                lat: this.currentAppel && this.currentAppel.geolocalisation && this.currentAppel.geolocalisation.latitude ? this.currentAppel.geolocalisation.latitude : (this.parametres.CTOW_DEFY || '48.8589466'),
                                long: this.currentAppel && this.currentAppel.geolocalisation && this.currentAppel.geolocalisation.longitude ? this.currentAppel.geolocalisation.longitude : (this.parametres.CTOW_DEFX || '2.2769957'),
                                zoom: '14',
                                forme: ''
                            })
                            + query
                        ;
                    }

                    return null;
                },
                nbInformations() {
                    return Object.entries(this.informations).reduce((acc, cur) => acc + cur[1].length, 0);
                },
                imc() {
                    if (this.currentAppel && this.currentAppel._patient && parseInt(this.currentAppel._patient.poids) && parseInt(this.currentAppel._patient.taille)) {
                        return parseInt(this.currentAppel._patient.poids) / ((parseInt(this.currentAppel._patient.taille) / 100) ** 2);
                    }

                    return null;
                },
            },
        });
    }
}