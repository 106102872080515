<template>
    <l-map :zoom="zoom" :options="{zoomControl: false}" ref="map" class="carto-absolute" @contextmenu="$emit('contextmenu', $event)" @click="$emit('click', $event)">
        <l-control-scale v-if="controlScale" position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
        <l-control v-if="controlIncidents" position="bottomright">
            <div
                aria-haspopup="true"
                class="leaflet-control-layers leaflet-control m-0"
                :class="hoveringIncidentsButton || displayIncidentsButton ? 'leaflet-control-layers-expanded' : ''"
                style="padding: 4px 6px"
                @mouseover="hoveringIncidentsButton = true"
                @mouseout="hoveringIncidentsButton = false"
            >
                <a
                    class="leaflet-control-layers-toggle"
                    :class="hoveringIncidentsButton || displayIncidentsButton ? 'd-none' : ''"
                    href="#"
                    id="leaflet-control-layers-toggle-incidents"
                    role="button"
                >
                    <i class="fa-solid fa-cars text-muted"></i>
                </a>
                <section class="leaflet-control-layers-list">
                    <div class="leaflet-control-layers-overlays d-flex align-items-center">
                        <label class="mb-0 flex-1" :title="'Limité à ' + incidentsBoundingBoxSize + ' km² autour du centre de la carte. Rafraîchissement manuel uniquement.'">
                            <span>
                                <input v-model="displayIncidentsButton" type="checkbox" class="leaflet-control-layers-selector">
                                <span> Perturbations du trafic</span>
                            </span>
                        </label>
                        <b-button
                            class="ml-2 text-muted p-1"
                            :class="displayIncidentsButton ? 'visible' : 'invisible'"
                            size="sm"
                            title="Rafraîchir"
                            type="button"
                            @click="refreshIncidents()"
                        >
                            <i class="fa-solid fa-refresh fa-fw"></i>
                        </b-button>
                    </div>
                </section>
            </div>
        </l-control>
        <l-control-layers v-if="controlLayers" position="bottomright"></l-control-layers>
        <l-control v-if="controlTraffic && displayTrafficButton" position="bottomright">
          <div
              class="carto-btn carto-btn-traffic"
              title="Rafraîchir le trafic"
              @click="refreshTraffic()"
          >
                    <span class="fa-stack">
                        <i class="fa-duotone fa-traffic-light-slow fa-stack-1x"></i>
                        <i class="fa-light fa-refresh fa-stack-2x"></i>
                    </span>
          </div>
        </l-control>
        <l-tile-layer
            v-for="tile in tiles.base"
            :key="tile.name"
            :name="tile.name"
            :visible="tile.visible"
            :url="tile.url"
            layer-type="base"
            :options="{
                minZoom: tile.minZoom,
                maxNativeZoom: tile.maxZoom,
                maxZoom: 21
            }"
        ></l-tile-layer>
        <l-tile-layer
            v-for="tile in tiles.overlay"
            :key="tile.name"
            :name="tile.name"
            :visible="tile.visible"
            :url="tile.url"
            :z-index="tile.zIndex"
            layer-type="overlay"
            :options="{
                id: tile.id,
                minZoom: tile.minZoom,
                maxNativeZoom: tile.maxZoom,
                maxZoom: 21,
                forceRefresh: tile.forceRefresh
            }"
            @update:visible="updateLayerVisibility(tile.id, $event)"
        ></l-tile-layer>

        <l-marker
            v-if="markers.dossier && markers.dossier.latLng"
            :key="markers.dossier.id"
            :ref="'marker_' + markers.dossier.id"
            :lat-lng="markers.dossier.latLng"
            :name="markers.dossier.name"
            :opacity="1"
            :z-index-offset="9999"
            @click="clickMarker(markers.dossier)"
        >
            <l-icon :iconSize="[42, 42]" :iconAnchor="[21, 42]" :tooltipAnchor="[0, markers.dossier.id === markerSelected ? -42 : -33]">
                <div class="marker-pin marker-pin-square" :class="markers.dossier.id === markerSelected ? 'marker-pin-selected' : ''">
                    <div class="marker-body" :style="markers.dossier.bg ? 'background:' + markers.dossier.bg : ''"></div>
                    <div class="marker-icon" :style="'color:' + markers.dossier.color">
                        <i :class="markers.dossier.icoClass + (markers.dossier.id === markerSelected ? ' fa-beat-fade' : '')"></i>
                    </div>
                </div>
                <div class="pulse" v-if="markers.dossier.id === markerSelected"></div>
            </l-icon>
            <l-tooltip
                v-if="markers.dossier.libelle || markers.dossier.numero"
                :content="markers.dossier.libelle ?? '#' + markers.dossier.numero"
                :options="{direction: 'top'}"
            ></l-tooltip>
        </l-marker>

        <template v-if="markers.triangulation && markers.triangulation.latLng">
            <l-marker
                :key="markers.triangulation.id"
                :ref="'marker_' + markers.triangulation.id"
                :lat-lng="markers.triangulation.latLng"
                :opacity="1"
                :z-index-offset="9995"
            >
                <l-icon :iconSize="[42, 42]" :iconAnchor="[21, 42]" :tooltipAnchor="[0, markers.triangulation.id === markerSelected ? -42 : -33]">
                    <div class="marker-pin marker-pin-square" :class="markers.triangulation.id === markerSelected ? 'marker-pin-selected' : ''">
                        <div class="marker-body" :style="markers.triangulation.bg ? 'background:' + markers.triangulation.bg : ''"></div>
                        <div class="marker-icon" :style="'color:' + markers.triangulation.color">
                            <i :class="markers.triangulation.icoClass + (markers.triangulation.id === markerSelected ? ' fa-beat-fade' : '')"></i>
                        </div>
                    </div>
                    <div class="pulse" v-if="markers.triangulation.id === markerSelected"></div>
                </l-icon>
                <l-tooltip
                    v-if="markers.triangulation.tooltip"
                    :content="markers.triangulation.tooltip"
                    :options="{direction: 'top'}"
                ></l-tooltip>
            </l-marker>
            <l-circle
                v-if="markers.triangulation.circle"
                :lat-lng="markers.triangulation.circle.latLng"
                :radius="markers.triangulation.circle.radius"
            ></l-circle>
            <l-semicircle
                v-if="markers.triangulation.semicircle"
                :lat-lng="markers.triangulation.semicircle.latLng"
                :options="markers.triangulation.semicircle.options"
            ></l-semicircle>
            <l-polygon
                v-if="markers.triangulation.polygon"
                :lat-lngs="markers.triangulation.polygon"
            ></l-polygon>
            <carto-ellipse
                v-if="markers.triangulation.ellipse"
                :lat-lng="markers.triangulation.ellipse.latLng"
                :radius="markers.triangulation.ellipse.radius"
                :tilt="markers.triangulation.ellipse.tilt"
            ></carto-ellipse>
        </template>

        <template v-if="controlIncidents && displayIncidentsButton && incidents.length">
            <template v-for="incident in incidents">
                <l-marker
                    :lat-lng="'LineString' === incident.geometry.type ? {lat: incident.geometry.coordinates[0][0], lng: incident.geometry.coordinates[0][1]}: {lat: incident.geometry.coordinates[0], lng: incident.geometry.coordinates[1]}"
                    :name="incident.properties.events[0].description"
                    :z-index-offset="9990"
                >
                    <l-icon :tooltipAnchor="[0, -25]">
                        <div class="marker-pin marker-pin-icon-only">
                            <div class="marker-body"></div>
                            <div class="marker-icon">
                                <span class="fa-stack fa-2x">
                                    <i
                                        class="fa-solid fa-circle fa-stack-2x"
                                        :style="'color: ' + incidentMagnitudeColors[incident.properties.magnitudeOfDelay]"
                                    ></i>
                                    <i
                                        class="fa-stack-1x"
                                        :class="incidentIcons[incident.properties.iconCategory] + ' ' + ('yellow' === incidentMagnitudeColors[incident.properties.magnitudeOfDelay] ? 'text-dark' : 'text-white')"
                                        :style="incident.properties.iconCategory === 7 ? 'left: -1px' : ''"
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </l-icon>
                    <l-tooltip
                        :content="incident.properties.events[0].description"
                        :options="{direction: 'top'}"
                    ></l-tooltip>
                </l-marker>
                <l-polyline
                    v-if="incident.geometry.type === 'LineString' && incident.geometry.coordinates.length > 1"
                    :color="incidentMagnitudeColors[incident.properties.magnitudeOfDelay]"
                    :lat-lngs="incident.geometry.coordinates"
                ></l-polyline>
            </template>
        </template>

        <carto-marker-cluster :options="{
            maxClusterRadius: clusterRadius,
            showCoverageOnHover: false,
            removeOutsideVisibleBounds: false,
            zoomToBoundsOnClick: false,
        }" ref="clustersLayer"
            @clusterclick="$event.layer.spiderfy()">
            <template v-for="marker in markers">
                <l-marker
                    v-if="!marker.patient && marker.id !== 'triangulation'"
                    :key="marker.id"
                    :ref="'marker_' + marker.id"
                    :lat-lng="marker.latLng"
                    :name="marker.name"
                    :opacity="marker.estFutur
                        || (
                            limite
                            && marker.itineraire
                            && (
                                ('km'===limiteUnite && marker.itineraire.distance && marker.itineraire.distance > limite)
                                || ('min' === limiteUnite && marker.itineraire.temps && marker.itineraire.temps > limite)
                            )
                        ) ? 0.5 : 1"
                    :z-index-offset="markerSelected === marker ? 10000 : 0"
                    @click="clickMarker(marker)"
                >
                    <l-icon :iconSize="[42, 42]" :iconAnchor="[21, 42]" :tooltipAnchor="[0, marker.id === markerSelected ? -42 : -33]">
                        <div class="marker-pin" :class="(marker.id === markerSelected ? 'marker-pin-selected' : '') + (marker.dossier ? ' marker-pin-square' : '')">
                            <div class="marker-body" :style="marker.bg ? 'background:' + marker.bg : (marker.mission ? 'background:' + marker.color : '')"></div>
                            <div class="marker-spin" v-if="marker.mission"></div>
                            <div class="marker-icon" :style="'color:' + (marker.mission ? '#FFFFFF' : marker.color)">
                                <i :class="marker.icoClass + (marker.id === markerSelected ? ' fa-beat-fade' : '')"></i>
                            </div>
                            <div class="marker-charge" v-if="marker.charge && typeof marker.charge.pourcent !== 'undefined' && marker.charge.pourcent !== null">
                                <template v-for="pas in [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]">
                                    <div
                                        class="marker-charge-pas"
                                        :class="marker.charge.pourcent >= pas ? (marker.charge.pourcent >= 100 ? 'bg-danger' : (marker.charge.pourcent >= 70 ? 'bg-warning' : 'bg-warning-light')) : ''"
                                    >-</div>
                                </template>
                            </div>
                            <div class="marker-charge" :class="marker.ressources.pourcent <= 10 ? 'border-danger border-heavy' : null" v-if="marker.ressources && typeof marker.ressources.pourcent !== 'undefined' && marker.ressources.pourcent !== null">
                                <template v-for="pas in [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]">
                                    <div
                                        class="marker-charge-pas"
                                        :class="marker.ressources.pourcent >= pas ? (marker.ressources.pourcent <= 10 ? 'bg-danger' : (marker.ressources.pourcent <= 30 ? 'bg-warning' : 'bg-success')) : ''"
                                    >-</div>
                                </template>
                            </div>
                        </div>
                        <div class="pulse" v-if="marker.id === markerSelected"></div>
                    </l-icon>
                    <l-tooltip
                        v-if="marker.libelle || marker.numero"
                        :content="marker.libelle ?? '#' + marker.numero"
                        :options="{direction: 'top'}"
                    ></l-tooltip>
                </l-marker>
            </template>
        </carto-marker-cluster>

        <template v-for="marker in markers">
            <l-polyline v-if="marker.trajet" :lat-lngs="marker.trajet.points" color="black" :weight="8"></l-polyline>
            <l-polyline v-if="marker.trajet" :lat-lngs="marker.trajet.points" color="white" :weight="4" dashArray="10, 10" dashOffset="10"></l-polyline>
            <l-polyline v-if="marker.trajet" :lat-lngs="marker.trajet.points" :color="marker.color" :weight="4" dashArray="10, 10"></l-polyline>
        </template>

        <carto-path :lat-lngs="paths.osrm && !paths.shortest && !paths.fastest ? paths.osrm : []"></carto-path>
        <carto-path :lat-lngs="paths.shortest ?? []" color="blue"></carto-path>
        <carto-path :lat-lngs="paths.fastest ?? []" color="red" delay="800"></carto-path>
        <carto-path :lat-lngs="paths.secondaire ?? []" color="purple"></carto-path>
    </l-map>
</template>

<script>
import {latLng, latLngBounds} from "leaflet";
import {
    LCircle,
    LControl,
    LControlLayers,
    LControlScale,
    LIcon,
    LMap,
    LMarker,
    LPolygon,
    LPolyline,
    LTileLayer,
    LTooltip
} from 'vue2-leaflet';

import 'leaflet/dist/leaflet.css';
import Request from "../../../utils/Request";
import Router from "../../../utils/Router";
import Vue2LeafletSemicircle from 'vue2-leaflet-semicircle';

export default {
    components: {
        'l-map': LMap,
        'l-tile-layer': LTileLayer,
        'l-marker': LMarker,
        'l-control-layers': LControlLayers,
        'l-control-scale': LControlScale,
        'l-control': LControl,
        'l-icon': LIcon,
        'l-tooltip': LTooltip,
        'l-polyline': LPolyline,
        'l-semicircle': Vue2LeafletSemicircle,
        'l-circle': LCircle,
        'l-polygon': LPolygon,
    },
    data: function () {
        return {
            communes: {},
            communesState: {},
            communesTempState: {},
            communesReady: false,
            displayIncidentsButton: false,
            displayTrafficButton: false,
            hoveringIncidentsButton: false,
            incidentMagnitudeColors: {
                0: 'yellow',
                1: 'yellow',
                2: 'orange',
                3: 'red',
                4: 'red',
            },
            incidentIcons: {
                0: 'fa-solid fa-question', // Unknown
                1: 'fa-solid fa-car-burst', // Accident
                2: 'fa-solid fa-cloud-fog', // Fog
                3: 'fa-solid fa-exclamation', // DangerousConditions
                4: 'fa-solid fa-cloud-showers-heavy', // Rain
                5: 'fa-solid fa-snowflake', // Ice
                6: 'fa-solid fa-cars', // Jam
                7: 'fa-solid fa-merge fa-rotate-270', // LaneClosed
                8: 'fa-solid fa-road-lock', // RoadClosed
                9: 'fa-solid fa-person-digging', // RoadWorks
                10: 'fa-solid fa-wind', // Wind
                11: 'fa-solid fa-house-flood-water', // Flooding
                14: 'fa-solid fa-engine-warning', // BrokenDownVehicle
            },
            incidents: [],
            incidentsBoundingBoxSize: 5000, // km²
            markersDrawn: {},
            markersImported: {},
            markerSelected: null,
            paths: {
                osrm: null,
                fastest: null,
                shortest: null,
                secondaire: null,
            },
            polygons: [],
            tiles: global.tiles(false),
            zoom: 15,
        };
    },
    props: {
        centerMarkerOnClick: {
            type: Boolean,
            required: false,
            default: true,
        },
        clustering: {
            type: Boolean,
            required: false,
            default: true,
        },
        controlIncidents: {
            type: Boolean,
            required: false,
            default: true,
        },
        controlLayers: {
            type: Boolean,
            required: false,
            default: true,
        },
        controlScale: {
            type: Boolean,
            required: false,
            default: true,
        },
        controlTraffic: {
            type: Boolean,
            required: false,
            default: true,
        },
        couleurs: {
            type: Object,
            required: false,
            default: function () {
                return {};
            },
        },
        defaults: {
            type: Object,
            required: false,
            default: function () {
                return {};
            },
        },
        icones: {
            type: Object,
            required: false,
            default: function () {
                return {};
            },
        },
        limite: {
            type: String,
            required: false,
            default: '0',
        },
        limiteUnite: {
            type: String,
            required: false,
            default: 'km',
        },
        tooltipCommunes: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    beforeMount() {},
    mounted() {
        L.Icon.Default.prototype.options.iconUrl = '/favicon-16x16.png';
        L.Icon.Default.prototype.options.shadowUrl = '/favicon-16x16.png';

        this.checkWebAccess();

        Request.fetchJson(Router.generate('get.polygons', {type: 'reg'})).then(data => {
            for (const [reg, objects] of Object.entries(data)) {
                if(objects.type) {
                    const feature = L.geoJSON(objects, {
                        name: reg,
                        color: 'black',
                        weight: 2,
                        opacity: 1,
                        fill: false,
                        interactive: false,
                    });

                    feature.addTo(this.$refs.map.mapObject);
                } else {
                    objects.forEach(object => {
                        const points = object.map(point => latLng(point[1], point[0]));
                        const lPolygon = L.polygon(points, {
                            name: reg,
                            color: 'black',
                            weight: 2,
                            opacity: 1,
                            fill: false,
                            interactive: false,
                        });

                        lPolygon.addTo(this.$refs.map.mapObject);
                    });
                }
            }
        });

        Request.fetchJson(Router.generate('get.polygons', {type: 'dep'})).then(data => {
            for (const [dep, objects] of Object.entries(data)) {
                if(objects.type) {
                    const feature = L.geoJSON(objects, {
                        name: dep,
                        color: 'black',
                        weight: 2,
                        opacity: 1,
                        fill: false,
                        interactive: false,
                    });

                    feature.addTo(this.$refs.map.mapObject);
                } else {
                    objects.forEach(object => {
                        const points = object.map(point => latLng(point[1], point[0]));
                        const lPolygon = L.polygon(points, {
                            name: dep,
                            color: 'black',
                            weight: 2,
                            opacity: 1,
                            fill: false,
                            interactive: false,
                        });

                        lPolygon.addTo(this.$refs.map.mapObject);
                    });
                }
            }
        });

        this.refreshPolygonsCom();
    },
    methods: {
        cleanMarkers() { this.markersDrawn = {}; },
        cleanPolygons() {
            this.polygons.forEach(polygon => { polygon.remove(); });
        },
        cleanPaths() {
            this.paths = {
                osrm: null,
                fastest: null,
                shortest: null,
                secondaire: null,
            };
        },
        cleanMap() {
            this.cleanMarkers();
            this.cleanPolygons();
        },
        checkWebAccess() {
            fetch('https://nominatim.openstreetmap.org/search.php?q=Avenue+Gustave+Eiffel+75007+Paris&format=geojson&extratags=1')
                .then(response => {
                    if (response.ok) {
                        this.tiles = global.tiles(true);

                        this.$refs.map.mapObject.setMaxNativeZoom(global.tilesMaxZoom);

                        this.refreshCluster();
                    }
                })
                .catch(() => null);
        },
        refreshPolygonsCom() {
            for (let [insee, objects] of Object.entries(this.communes)) {
                objects.forEach(o => o.remove());
            }
            this.communes = {};

            Request.fetchJson(Router.generate('get.polygons', {type: 'com'})).then(data => {
                for (let [insee, objects] of Object.entries(data)) {
                    this.communes[insee] = [];

                    this.setCommunesState(insee);

                    if (typeof objects === 'string') {
                        objects = JSON.parse(objects);
                    }

                    if(objects.type) {
                        const feature = L.geoJSON(objects, {
                            name: insee,
                            interactive: true,
                        }).on('click', (e) => {
                            this.$emit('clickcommune', {
                                insee: e.target.options.name,
                                libelle: e.target.options.commune
                            });
                        });

                        this.communes[insee].push(feature);
                        feature.addTo(this.$refs.map.mapObject);
                    } else {
                        objects.forEach(object => {
                            const points = object.map(point => latLng(point[1], point[0]));
                            const lPolygon = L.polygon(points, {
                                name: insee,
                                interactive: true,
                            }).on('click', (e) => {
                                this.$emit('clickcommune', {
                                    insee: e.target.options.name,
                                    libelle: e.target.options.commune
                                });
                            });

                            this.communes[insee].push(lPolygon);
                            lPolygon.addTo(this.$refs.map.mapObject);
                        });
                    }
                }

                this.applyCommunesState();
                this.communesReady = true;
                this.$emit('communesready');

                if(this.tooltipCommunes) {
                    Request.fetchJson(Router.generate('communes.libelles')).then(data => {
                        data.forEach(commune => {
                            const insee = commune.insee;

                            if(this.communes[insee] && this.communes[insee].length) {
                                this.communes[insee].forEach(polygon => {
                                    polygon.bindTooltip(commune.libelle, {
                                        direction: 'top',
                                        sticky: true,
                                        opacity: 0.7,
                                        offset: [2, -5]
                                    });

                                    polygon.options['commune'] = commune.libelle;
                                });
                            }
                        });
                    });
                }
            });
        },
        refreshTraffic() {
            this.$refs.map.mapObject.eachLayer(layer => {
                if(layer instanceof L.TileLayer && layer.options.id && 'traffic' === layer.options.id) {
                    layer.options.forceRefresh = true;
                    layer.redraw();
                    layer.options.forceRefresh = false;
                }
            });
        },
        refreshIncidents() {
            this.incidents = [];
            const center = this.$refs.map.mapObject.getBounds().getCenter();
            const distanceToAdd = Math.sqrt(this.incidentsBoundingBoxSize) / 2;
            const earthRadius = 6378;
            const x1 = center.lng - (distanceToAdd / earthRadius) * (180 / Math.PI) / Math.cos(center.lat * Math.PI / 180);
            const y1 = center.lat - (distanceToAdd / earthRadius) * (180 / Math.PI);
            const x2 = center.lng + (distanceToAdd / earthRadius) * (180 / Math.PI) / Math.cos(center.lat * Math.PI / 180);
            const y2 = center.lat + (distanceToAdd / earthRadius) * (180 / Math.PI);

            Request.fetchJson('/rastik/incidents/' + [x1, y1, x2, y2].map(v => v.toFixed(6)).join('&')).then(data => {
                if ('undefined' === typeof data || 'undefined' === typeof data.incidents) {
                    document.dispatchEvent(new CustomEvent('app.flash', {
                        detail: {
                            type: 'error',
                            flash: 'Impossible de récupérer les perturbations du trafic.',
                            autoHideDelay: 3000,
                        }
                    }))
                    throw new Error(data && data.detailedError ? data.detailedError.code + ': ' + data.detailedError.message : 'Impossible de récupérer les perturbations du trafic.');
                }
                this.incidents = data.incidents.map(incident => {
                    // lng/lat => lat/lng
                    incident.geometry.coordinates = 'LineString' === incident.geometry.type
                        ? incident.geometry.coordinates.map(coord => coord.reverse())
                        : incident.geometry.coordinates.reverse()
                    ;

                    return incident;
                });
            });
        },
        getMapPadding() {
            const entite = document.getElementById('carto-entite');
            const leftSidebar = document.getElementById('carto-left-sidebar');
            const rightSidebar = document.getElementById('carto-right-sidebar');

            let paddingLeft = (entite && entite.offsetHeight > 100 && !entite.closest('.carto-oneColSidebar') ? entite.offsetWidth : 0) + (leftSidebar && leftSidebar.offsetHeight > 100 ? leftSidebar.offsetWidth : 0);
            let paddingRight = rightSidebar && rightSidebar.offsetHeight > 100 ? rightSidebar.offsetWidth : 0;

            return {paddingLeft, paddingRight};
        },
        centrer(centre) {
            const map = this.$refs.map.mapObject,
                {paddingLeft, paddingRight} = this.getMapPadding(),
                target = centre ? centre : latLng(this.defaults.lat, this.defaults.lng),
                targetPoint = map.project(target).subtract([paddingLeft / 2, 0]).add([paddingRight / 2, 0]),
                targetLatLng = map.unproject(targetPoint);

            map.setView(targetLatLng, undefined, {animate: this.animate, duration: 0.75});
        },
        setPath(type, points) {
            this.$set(this.paths, type, points);
        },
        zoomer(zoom) {
            this.zoom = parseInt(zoom ? zoom : this.defaults.zoom);
            this.$refs.map.mapObject.setZoom(this.zoom);
        },
        drawMarker(marker) {
            if(marker.latLng && marker.latLng.lat && marker.latLng.lng) {
                marker.drawn = true;

                this.$set(this.markersDrawn, marker.id, marker);
            }
        },
        drawMarkerFromEntity(entity, type) {
            if(entity.latitude && entity.longitude) {
                let marker = {
                    color: this.couleurs[type] ?? 'grey',
                    icoClass: this.icones[type] ?? 'fa-solid fa-map-pin fa-lg',
                    latLng : latLng(entity.latitude, entity.longitude),
                    drawn: true,
                    id: 'entity',
                };

                this.$set(this.markersDrawn, marker.id, marker);

                this.centrer(marker.latLng);
            }
        },
        drawPolygon(objects) {
            if(objects) {
                if(objects.type) {
                    const feature = L.geoJSON(objects, {
                        color: 'black',
                        weight: 1,
                        opacity: 1,
                        fillColor: 'grey',
                        fillOpacity: 0.5,
                        interactive: true,
                    });

                    this.polygons.push(feature);
                    feature.addTo(this.$refs.map.mapObject);
                } else {
                    objects.forEach(object => {
                        let latLongs = [];
                        object.forEach(coor => {
                            latLongs.push(latLng(coor[1], coor[0]));
                        });

                        let polygon = L.polygon(latLongs, {
                            color: 'black',
                            weight: 1,
                            opacity: 1,
                            fillColor: 'grey',
                            fillOpacity: 0.5,
                            interactive: true,
                        });

                        this.polygons.push(polygon);
                        polygon.addTo(this.$refs.map.mapObject);
                    });
                }
            }
        },
        showAllCommunes() { this.toggleDisplayAllCommunes(true); },
        hideAllCommunes() { this.toggleDisplayAllCommunes(false); },
        toggleDisplayAllCommunes(display = true) {
            for (const [insee, state] of Object.entries(this.communesState)) {
                this.setCommunesState(insee, display ? 'shown' : null);
            }
            this.applyCommunesState();
        },
        applyCommunesState() {
            for (const [insee, polygons] of Object.entries(this.communes)) {
                const state = this.communesState[insee];
                const tempState = this.communesTempState[insee];

                polygons.forEach(polygon => {
                    polygon.setStyle(tempState ?? state);
                });
            }

            this.communesTempState = {};
        },
        setCommunesState(insee, mode, temp) {
            let state = {};

            switch(mode) {
                case 'selected':
                    state = {
                        color: 'grey',
                        weight: 1,
                        opacity: 1,
                        fillColor: '#03a9f3',
                        fillOpacity: 0.5,
                    };
                    break;
                case 'selected_noborder':
                    state = {
                        color: '#03a9f3',
                        weight: 1,
                        opacity: 0.5,
                        fillColor: '#03a9f3',
                        fillOpacity: 0.5,
                    };
                    break;
                case 'selected_noborder_light':
                    state = {
                        color: '#03a9f3',
                        weight: 1,
                        opacity: 0.3,
                        fillColor: '#03a9f3',
                        fillOpacity: 0.3,
                    };
                    break;
                case 'selected_noborder_soft':
                    state = {
                        color: '#03a9f3',
                        weight: 1,
                        opacity: 0.2,
                        fillColor: '#03a9f3',
                        fillOpacity: 0.2,
                    };
                    break;
                case 'warning':
                    state = {
                        color: 'grey',
                        weight: 1,
                        opacity: 1,
                        fillColor: '#fed96a',
                        fillOpacity: 0.5,
                    };
                    break;
                case 'covered':
                    state = {
                        color: 'grey',
                        weight: 1,
                        opacity: 1,
                        fillColor: '#00c292',
                        fillOpacity: 0.5,
                    };
                    break;
                case 'shown':
                    state = {
                        color: 'grey',
                        weight: 1,
                        opacity: 1,
                        fillColor: 'grey',
                        fillOpacity: 0.5,
                    };
                    break;
                default:
                    state = {
                        color: 'grey',
                        weight: 1,
                        opacity: 0,
                        fillColor: 'grey',
                        fillOpacity: 0,
                    };
                    break;
            }

            if(temp) {
                this.communesTempState[insee] = state;
            } else {
                this.communesState[insee] = state;
            }
        },
        highlightCommune(insee) {
            this.applyCommunesState();
            if (insee && this.communes[insee]) {
                this.communes[insee].forEach(polygon => {
                    polygon.setStyle({weight: 3, color: 'black'});
                });
            }
        },
        importMarkers(markers) {
            this.markersImported = markers;
        },
        selectMarker(id) {
            this.markerSelected = id;

            // Perdu au refresh des markers : à voir si nécessaire
            /*if(this.$refs.clustersLayer && this.$refs['marker_' + id]) {
                const parent = this.$refs.clustersLayer.mapObject.getVisibleParent(this.$refs['marker_' + id][0].mapObject);
                if(parent && parent.spiderfy) {
                    parent.spiderfy();
                }
            }*/
        },
        clickMarker(marker) {
            this.$emit('clickmarker', marker);

            this.selectMarker(marker.id);
            this.centerMarkerOnClick && this.centrer(marker.latLng);
        },
        fitMarker(marker) {
            if(marker.latLng) {
                const {paddingLeft, paddingRight} = this.getMapPadding();

                this.$refs.map.mapObject.fitBounds(L.latLngBounds(marker.latLng, marker.latLng), {
                    paddingTopLeft: [paddingLeft, 0],
                    paddingBottomRight: [paddingRight, 0],
                    animate: this.animate,
                    duration: 0.75,
                    maxZoom: 13,
                });
            }
        },
        fitAllMarkers() {
            const {paddingLeft, paddingRight} = this.getMapPadding();
            let lMarkers = [];

            this.$refs.map.mapObject.eachLayer(layer => {
                if(layer instanceof L.Marker) {
                    lMarkers.push(layer);
                }
            });

            if(lMarkers.length > 1) {
                this.$refs.map.mapObject.fitBounds(L.featureGroup(lMarkers).getBounds(), {
                    paddingTopLeft: [paddingLeft, 0],
                    paddingBottomRight: [paddingRight, 0],
                    animate: this.animate,
                    duration: 0.75,
                });
            }
        },
        fitAllPaths() {
            if(this.paths.osrm || this.paths.shortest || this.paths.fastest || this.paths.secondaire) {
                this.fitPoints([
                    ...(this.paths.osrm ?? []),
                    ...(this.paths.shortest ?? []),
                    ...(this.paths.fastest ?? []),
                    ...(this.paths.secondaire ?? [])
                ]);
            }
        },
        fitPoints(points) {
            const {paddingLeft, paddingRight} = this.getMapPadding();

            if(points) {
                this.$refs.map.mapObject.fitBounds(latLngBounds(points), {
                    paddingTopLeft: [paddingLeft, 0],
                    paddingBottomRight: [paddingRight, 0],
                    animate: this.animate,
                    duration: 0.75,
                    maxZoom: 13,
                });
            }
        },
        refreshCluster() {
            const layers = this.$refs.clustersLayer.mapObject.getLayers();
            this.$refs.clustersLayer.mapObject.clearLayers();
            this.$refs.clustersLayer.mapObject.addLayers(layers);
        },
        updateLayerVisibility(id, visible) {
            if ('traffic' === id) {
                this.displayTrafficButton = visible;
            } else if ('incidents' === id) {
                this.displayIncidentsButton = visible;
            }
        },
    },
    watch: {
        clustering() {
            setTimeout(() => { this.refreshCluster(); }, 100);
        },
        displayIncidentsButton(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.refreshIncidents();
            } else if (!newValue) {
                this.incidents = [];
            }
        },
    },
    computed: {
        markers() {
            const markers = {};

            Object.assign(markers, this.markersDrawn);
            Object.assign(markers, this.markersImported);

            return markers;
        },
        animate() {
            return this.$refs.map.mapObject._lastCenter && this.$refs.map.mapObject._lastCenter.lat && this.$refs.map.mapObject._lastCenter.lng;
        },
        clusterRadius() {
            return this.clustering ? 30 : 0;
        },
    },
}
</script>
