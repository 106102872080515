<template>
    <div>
        <div class="card">
            <div class="card-header">
                Websocket
            </div>
            <div class="card-body d-flex justify-content-between text-center px-0 py-2">
                <div class="supervision-status">
                    <div class="supervision-status-value">
                        <div class="text-info" v-if="null === webSocketOnline">
                            <i class="fa-solid fa-spinner fa-pulse"></i>
                        </div>
                        <div class="text-success" v-else-if="webSocketOnline">
                            <i class="fa-solid fa-check" :title="websocketUptime"></i>
                        </div>
                        <div class="text-danger" v-else>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div class="supervision-status-label">Statut</div>
                </div>
                <div class="supervision-status">
                    <div class="supervision-status-value">{{ webSocketClients.length }}</div>
                    <div class="supervision-status-label">Connexions</div>
                </div>
                <div class="supervision-status">
                    <div class="supervision-status-value">{{ Object.keys(webSocketClientsByUser).length }} </div>
                    <div class="supervision-status-label">Utilisateurs</div>
                </div>
            </div>
        </div>

        <div class="card" v-if="server && server.symfony_version">
            <div class="card-header">
                Serveur
            </div>
            <div class="card-body">
                <div class="table-responsive m-0 p-0">
                    <table class="table table-bordered table-compact table-sm">
                        <tbody>
                        <tr>
                            <th class="width-1px text-nowrap">Hostname</th>
                            <td>{{ server.hostname }}</td>
                        </tr>
                        <tr>
                            <th class="width-1px text-nowrap">Version ASW</th>
                            <td>{{ server.version }}</td>
                        </tr>
                        <tr>
                            <th class="width-1px text-nowrap">Version Symfony</th>
                            <td>
                                <a :href="'https://symfony.com/releases/' + server.symfony_version + '.json'" target="_blank">{{ server.symfony_version }}</a>
                            </td>
                        </tr>
                        <tr>
                            <th class="width-1px text-nowrap">Version PHP</th>
                            <td>{{ server.php_version }}</td>
                        </tr>
                        <tr>
                            <th class="width-1px text-nowrap">Stage</th>
                            <td>{{ server.stage }}</td>
                        </tr>
                        <tr>
                            <th class="width-1px text-nowrap">Git</th>
                            <td class="text-ellipsis">[{{ server.branch }}] <span :title="server.commit">{{ server.commit }}</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: ['webSocket', 'server'],
    data() {
        return {
            clients: [],
            websocketUptime: 0,
            webSocketOnline: null,
        }
    },
    mounted() {
        if (webSocket.isInit()) {
            this.webSocketOnline = webSocket.isOnline();
        }

        document.addEventListener('websocket', (e) => {
            this.webSocketOnline = e.detail.online;
        });
    },
    methods: {
        updateWebSocketUptime() {
            if(this.webSocket.server.startTime) {
                this.websocketUptime = moment(this.webSocket.server.startTime * 1000, 'x').fromNow();
            } else {
                this.websocketUptime = null;
            }
        },
    },
    watch: {
        webSocketServer() {
            this.updateWebSocketUptime();
        }
    },
    computed: {
        webSocketServer() {
            return this.webSocket.server || {};
        },
        webSocketClients() {
            return this.webSocket.clients || [];
        },
        webSocketClientsByUser() {
            let users = {};

            this.webSocketClients.forEach((client) => {
                if(!(client.user.code in users)) {
                    users[client.user.code] = [];
                }

                users[client.user.code].push(client);
            });

            return users;
        },
    }
}
</script>
