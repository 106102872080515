import Vue from 'vue';
import {latLng} from "leaflet";
import Router from "../utils/Router";
import moment from "moment";
import Request from "../utils/Request";
import ParametrageMixin from '../vue/Mixins/ParametrageMixin';
import DevenirMixin from "../vue/Mixins/DevenirMixin";
import ModalRegulationMixin from "../vue/Mixins/ModalRegulationMixin";
import ShortcutMixin from "../vue/Mixins/ShortcutMixin";
import levenshtein from 'js-levenshtein';
import {CLASS_ORGANISME_FORMATTER} from "./BootstrapVueHelpers";

Vue.config.productionTip = false;

const presets = {
    vintkatre: [{debut: '0', fin: '24'}],
    ferme: [{debut: '0', fin: '0'}]
};

export default class {
    constructor() {
        new Vue({
            el: '#app',
            delimiters: ['[[', ']]'],
            mixins: [ParametrageMixin, DevenirMixin, ModalRegulationMixin, ShortcutMixin],
            data: {
                appel: null,
                appelOnglet: null,
                appels: [],
                arbo: [],
                carto: {
                    cursor: null,
                    markers: [],
                    patient: null,
                    marker: null,
                    rightSidebar: null,
                    triangulation: null,
                },
                codesEtablissement: [],
                codesMedecin: [],
                codesPharmacie: [],
                codesPoi: [],
                config: {
                    show: false,
                    regroupement: true,
                    hideCards: false,
                    onMissionOnly: false,
                },
                contextMenu: {
                    lat: null,
                    lng: null,
                    dms: null,
                },
                csrf: {
                    commentaire: null,
                    delete: null,
                    horaires: null,
                    serviceHoraires: null,
                    sms: null,
                },
                default: {},
                detailSelected: null,
                entities: [],
                forms: {},
                horairesEdited: null,
                horlogeHeure: moment().hour(),
                horlogeJour: {1: 'lundi', 2:  'mardi', 3: 'mercredi', 4: 'jeudi', 5: 'vendredi', 6: 'samedi', 7: 'dimanche'}[moment().isoWeekday()],
                itineraires: {},
                coordonneesCustom: {
                    type: null, // (origine|destination)
                    lngLatOsrm: null,
                    info: null, // libelle, adresse, instructions, ...
                },
                keywords: null,
                limite: null,
                limiteUnite: 'min',
                loadingDae: false,
                loadingItineraire: false,
                loadingMedecins: false,
                loadingPharmacies: false,
                loadingRoutingMachine: false,
                markersAppel: [],
                missions: [],
                modeles: [],
                moyens: [],
                newCommentaire: {
                    opened: false,
                    saving: false,
                    errors: {},
                    contenu: null,
                    original: null,
                },
                newObservation: {
                    saving: false,
                    errors: {},
                    contenu: null,
                },
                noMatch: false,
                noMatchHints: {},
                page: 1,
                parametres: {},
                pois: [],
                primaireSelected: null,
                regulation: {
                    appels: false,
                    demandes: false,
                    missions: false,
                },
                roles: {},
                savingHoraires: false,
                secteurListed: null,
                secteurs: {
                    VSAB: null ,
                    SMUR: null,
                    ATSU: null,
                    MG: null,
                    PHAR: null,
                    AUTRE: null,
                    secteursMatch: null,
                    gardesMatch: null,
                },
                secteurSelected: null,
                selectAppelArgs: null,
                shortcuts: {
                    ' ': {description: 'Afficher/masquer le volet patient.', handler: 'onShortcutPatient'},
                    'clic droit': {description: 'Menu contextuel (sur la carte).'},
                    'a': {description: 'Afficher/masquer la liste des <b style="text-decoration: underline">A</b>ppels.', handler: 'onShortcutAppels'},
                    'c': {description: '<b style="text-decoration: underline">C</b>entrer la carte sur le patient.', handler: 'onShortcutCentrerPatient'},
                    'shift-c': {description: '<b style="text-decoration: underline">C</b>entrer la carte sur l\'entité sélectionnée.', handler: 'onShortcutCentrerMarker'},
                    'm': {description: 'Afficher/masquer la liste des <b style="text-decoration: underline">M</b>issions.', handler: 'onShortcutMissions'},
                    'o': {description: 'Nouvelle <b style="text-decoration: underline">O</b>bservation patient.', handler: 'onShortcutObservation'},
                    'p': {description: 'Afficher / masquer la position de l\'appelant (<b style="text-decoration: underline">P</b>FLAU/AML).', handler: 'onShortcutTriangulation'},
                    'alt-r': {description: '<b style="text-decoration: underline">R</b>éinitialiser les filtres.', handler: 'onShortcutReset'},
                    't': {description: 'Centrer les <b style="text-decoration: underline">T</b>rajets affichés.', handler: 'onShortcutTrajets'},
                },
                showTriangulation: false,
                timeout: null,
                typesMedecins: [],
                typeMedecin: null,
                urgenceOnly: false,
                wsUpdating: false,
                zones: [],
                zonesParDefaut: null,
                appelsZoneFilter: [],
            },
            beforeMount() {
                const data = JSON.parse(this.$el.dataset.data);

                for (let key in data) {
                    this[key] = data[key];
                }

                for (const [type] of Object.entries(this.secteurs)) {
                    if(type !== 'secteursMatch' && type !== 'gardesMatch') {
                        Request.fetchJson(Router.generate('secteurs.all', {type: type}))
                            .then(data => {
                                this.secteurs[type] = data;
                                this.refreshSecteursMatch();
                            });
                    }
                }

                webSocket.subscribe('liste', (url, data) => {
                    if ('appels' === data.type) {
                        this.appels = data.data;
                    } else if ('missions' === data.type) {
                        this.missions = data.data;
                    } else if ('carto' === data.type) {
                        this.wsUpdating = true;
                        setTimeout(() => { this.wsUpdating = false; }, 2000);

                        data.moyens.forEach(newMoyen => {
                            const index = this.moyens.findIndex(oldMoyen => newMoyen.categorie === oldMoyen.categorie && newMoyen.libelle === oldMoyen.libelle);
                            if (!newMoyen.endpoint || -1 === index) {
                                newMoyen.markers.forEach(marker => this.prepareMarker(marker, newMoyen));
                                if (-1 === index) {
                                    this.moyens.push(newMoyen);
                                } else {
                                    this.moyens[index].markers = newMoyen.markers;
                                }
                            }
                        });
                        data.entities.forEach(newEntity => {
                            const index = this.entities.findIndex(oldEntity => newEntity.libelle === oldEntity.libelle);
                            if (!newEntity.endpoint || -1 === index) {
                                newEntity.markers.forEach(marker => this.prepareMarker(marker, newEntity));
                                if (-1 === index) {
                                    this.entities.push(newEntity);
                                } else {
                                    this.entities[index].markers = newEntity.markers;
                                }
                            }
                        });
                        data.pois.forEach(newPoi => {
                            const index = this.pois.findIndex(oldPoi => newPoi.libelle === oldPoi.libelle);
                            if (!newPoi.endpoint || -1 === index) {
                                newPoi.markers.forEach(marker => this.prepareMarker(marker, newPoi));
                                if (-1 === index) {
                                    this.pois.push(newPoi);
                                } else {
                                    this.pois[index].markers = newPoi.markers;
                                }
                            }
                        });

                        this.updateMarkers(true);
                    }
                });
            },
            mounted() {
                this.$el.classList.remove('invisible');

                this.handleKiosk();
                this.initMap();
                this.refreshHorloge();

                window.addEventListener('popstate', (event) => {
                    if (event.state && event.state.appel) {
                        this.selectAppel(event.state.appel);
                    }
                });
                document.addEventListener('app.selectAppel', (event) => {
                    event.detail && event.detail.numero && this.selectAppel(event.detail.numero);
                });
            },
            watch: {
                appel(newValue, oldValue) {
                    if (oldValue && oldValue.affaire.numero && (!newValue || newValue.affaire.numero !== oldValue.affaire.numero)) {
                        webSocket.unsubscribe('affaire/' + oldValue.affaire.numero);
                        this.$refs.carto.cleanPaths();
                        this.showTriangulation = !!(
                            newValue
                            && newValue.affaire.numero
                            && newValue.triangulation
                            && (!newValue.geolocalisation || !newValue.geolocalisation.latitude)
                        );
                    }
                    if (newValue.affaire.numero && (!oldValue || newValue.affaire.numero !== oldValue.affaire.numero)) {
                        (!window.history.state || window.history.state.appel !== newValue.numero) && App.Layout.pushState(
                            {appel: newValue.numero, documentTitle: '#' + newValue.numero + ' - Cartographie - AppliSAMU'},
                            location.pathname.match('/' + newValue.numero + '/')
                                    ? location.pathname + location.search
                                    : Router.generate('carto.dossier', {
                                        session: 'asw',
                                        dossier: newValue.numero,
                                        lat: (newValue.geolocalisation && newValue.geolocalisation.latitude) || this.$refs.carto.$refs.map.mapObject.getCenter().lat.toFixed(6),
                                        long: (newValue.geolocalisation && newValue.geolocalisation.longitude) || this.$refs.carto.$refs.map.mapObject.getCenter().lng.toFixed(6),
                                        zoom: this.$refs.carto.$refs.map.mapObject.getZoom(),
                                        forme: '',
                                    })
                        );
                        webSocket.subscribe('affaire/' + newValue.affaire.numero, (url, data) => {
                            if ('appel' === data.action) {
                                this.appel = data.data;
                            }
                        });
                        if ('observations' === this.appelOnglet) {
                            this.scrollObservations();
                        }
                    }
                    if (
                        newValue && oldValue && newValue.numero === oldValue.numero
                        && (
                            JSON.stringify(newValue.geolocalisation) !== JSON.stringify(oldValue.geolocalisation)
                            || JSON.stringify(newValue.triangulation) !== JSON.stringify(oldValue.triangulation)
                        )
                    ) {
                        this.updateMarkersPatient(false);
                    }

                    this.refreshSecteursMatch();
                },
                keywords() {
                    const etablissements = this.entities.find(e => true === e.etablissements);
                    if (etablissements) {
                        clearTimeout(this.timeout);
                        this.timeout = setTimeout(() => {
                            if (
                                !etablissements.selected
                                && this.filtreTexteActif
                                && (
                                    this.arbo.length === 0
                                    || !this.arbo.some(p => p.selected || p.secondaires.some(s => true === s.selected))
                                )
                            ) {
                                etablissements.selected = true;
                            }
                            this.updateMarkers();
                        }, 500);
                    }
                },
                origineDae(newValue) {
                    const indexEntity = this.entities.findIndex(e => e.dae === true);
                    if (newValue) {
                        const [long, lat] = newValue.split(',');
                        this.loadingDae = true;
                        Request.fetchJson(Router.generate('carto.dae', {lat, long}))
                            .then(data => {
                                data.forEach(marker => this.prepareMarker(marker, this.entities[indexEntity]));
                                this.entities[indexEntity].markers = data;
                                this.updateMarkers();
                            })
                            .finally(() => {
                                this.loadingDae = false;
                            })
                        ;
                    } else {
                        this.entities[indexEntity].markers = [];
                        this.updateMarkers();
                    }
                },
                origineMedecins(newValue) {
                    const indexEntity = this.entities.findIndex(e => e.medecins === true);
                    if (newValue) {
                        const [long, lat, q, typeMedecin] = newValue.split(',');
                        this.loadingMedecins = true;
                        Request.fetchJson(Router.generate('carto.medecins', {lat, long, q, typeMedecin}))
                            .then(data => {
                                data.forEach(marker => this.prepareMarker(marker, this.entities[indexEntity]));
                                this.entities[indexEntity].markers = data;
                                this.updateMarkers();
                            })
                            .finally(() => {
                                this.loadingMedecins = false;
                            })
                        ;
                    } else {
                        this.entities[indexEntity].markers = [];
                        this.updateMarkers();
                    }
                },
                originePharmacies(newValue) {
                    const indexEntity = this.entities.findIndex(e => e.pharmacies === true);
                    if (newValue) {
                        const [long, lat, q] = newValue.split(',');
                        this.loadingPharmacies = true;
                        Request.fetchJson(Router.generate('carto.pharmacies', {lat, long, q}))
                            .then(data => {
                                data.forEach(marker => this.prepareMarker(marker, this.entities[indexEntity]));
                                this.entities[indexEntity].markers = data;
                                this.updateMarkers();
                            })
                            .finally(() => {
                                this.loadingPharmacies = false;
                            })
                        ;
                    } else {
                        this.entities[indexEntity].markers = [];
                        this.updateMarkers();
                    }
                },
                origineDossier(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        this.itineraires = {};

                        this.updateMarkers();
                    }
                },
                markersArray(newValue) {
                    const markers = {};
                    newValue.forEach(marker => {
                        if (marker.latLng) {
                            markers[marker.id] = marker;
                        }
                    });

                    if(this.affichageRegulation) {
                        if(this.regulation.appels) {
                            this.appels.forEach(appel => {
                                if(appel.geolocalisation && appel.geolocalisation.latitude && appel.geolocalisation.longitude && (
                                    0 === this.appelsZoneFilter.length || appel.zone && this.appelsZoneFilter.some(zone => zone.code === appel.zone.code)
                                )) {
                                    let marker = {
                                        color: 'white',
                                        bg: '#e46a76',
                                        icoClass: 'fa-solid fa-phone',
                                        latLng : latLng(appel.geolocalisation.latitude, appel.geolocalisation.longitude),
                                        id: 'APP' + appel.numero,
                                        numero: appel.numero,
                                        dossier: true,
                                    };

                                    markers[marker.id] = marker;
                                }
                            });
                        }
                        if(this.regulation.demandes || this.regulation.missions) {
                            this.missions.forEach(mission => {
                                if(this.regulation[mission.enAttenteDeMoyen ? 'demandes' : 'missions']) {
                                    const appel = mission.decision.appel;
                                    if (appel.geolocalisation && appel.geolocalisation.latitude && appel.geolocalisation.longitude && (
                                        0 === this.appelsZoneFilter.length || appel.zone && this.appelsZoneFilterFilter.some(zone => zone.code === appel.zone.code)
                                    )) {
                                        let marker = {
                                            color: 'white',
                                            bg: mission.enAttenteDeMoyen ? '#fec107' : '#03a9f3',
                                            icoClass: mission.enAttenteDeMoyen ? 'fa-solid fa-headset' : 'fa-solid fa-truck-fast',
                                            latLng: latLng(appel.geolocalisation.latitude, appel.geolocalisation.longitude),
                                            id: 'APP' + appel.numero,
                                            numero: appel.numero,
                                            dossier: true,
                                        };

                                        markers[marker.id] = marker;
                                    }
                                }
                            });
                        }
                        if(this.carto.patient && this.carto.patient.id === 'dossier') {
                            markers.dossier = this.carto.patient;
                        }
                    } else if (this.carto.markers.dossier) {
                        markers.dossier = this.carto.markers.dossier;
                    }
                    if (!this.affichageRegulation && this.carto.markers.triangulation && ('query' === this.carto.markers.triangulation.origine || this.showTriangulation)) {
                        markers.triangulation = this.carto.markers.triangulation;
                    }

                    this.$refs.carto.importMarkers(markers);

                    if (this.carto.marker && this.carto.cursor && this.carto.markers && !this.carto.markers[this.carto.cursor]) {
                        this.carto.cursor = null;
                        this.carto.marker = null;
                        this.detailSelected = null;
                    }
                },
                appelsZoneFilter() {
                    if(this.affichageRegulation) {
                        this.updateMarkers();
                    }
                },
                appelOnglet(newValue) {
                    if ('observations' === newValue) {
                        this.scrollObservations();
                    }
                },
                showTriangulation(newValue) {
                    this.updateMarkers();
                    newValue && this.$refs.carto.centrer(this.carto.triangulation.latLng);
                },
                typeMedecin(newValue) {
                    if (newValue) {
                        this.entities[this.entities.findIndex(e => e.medecins === true)].selected = true;
                    }
                    // Les markers seront mis à jour par le watcher sur origineMedecins
                },
            },
            methods: {
                addItineraire(origine, destination, type, distance, temps, points) {
                    if (!this.itineraires[origine]) {
                        Vue.set(this.itineraires, origine, {});
                    }
                    if (!this.itineraires[origine][destination]) {
                        Vue.set(this.itineraires[origine], destination, {});
                    }
                    if ('undefined' === typeof points && this.itineraires[origine][destination][type]) {
                        // Conserve les points s'ils ne sont pas fournis en paramètre
                        points = this.itineraires[origine][destination][type].points;
                    }
                    Vue.set(this.itineraires[origine][destination], type, {
                        distance,
                        temps,
                        points,
                    });
                },
                getItineraire(origine, destination, type = null) {
                    if (origine && destination) {
                        if (type) {
                            if (
                                this.itineraires[origine]
                                && this.itineraires[origine][destination]
                                && this.itineraires[origine][destination][type]
                            ) {
                                return this.itineraires[origine][destination][type];
                            }
                        } else if (this.itineraires[origine] && this.itineraires[origine][destination]) {
                            for (let type of ['fastest', 'shortest', 'osrm', 'oiseau']) {
                                if (this.itineraires[origine][destination][type] && this.itineraires[origine][destination][type].distance) {
                                    return Object.assign({type: type}, this.itineraires[origine][destination][type]);
                                }
                            }
                        }
                    }

                    return {distance: null, temps: null};
                },
                prepareMarker (marker, entity) {
                    marker.color = entity.color ?? '#00c292';
                    if ('HL' === marker.codeTypeMoyen) {
                        marker.icoClass = 'fa-solid fa-helicopter';
                    } else if ('AV' === marker.codeTypeMoyen) {
                        marker.icoClass = 'fa-solid fa-plane';
                    } else if ('APIED' === marker.codeTypeMoyen) {
                        marker.icoClass = 'fa-solid fa-person-walking';
                    } else if (!marker.icoClass) {
                        marker.icoClass = entity.icon ? entity.icon : 'fa-solid fa-bullseye';
                    }

                    this.prepareMarkerForOsrm(marker);

                    if(marker.dae && marker.ferme !== false) {
                        marker.color = marker.ferme === true ? '#e46a76' : '#fec107';
                    }

                    if (typeof marker.etat !== 'undefined') {
                        if ('D' === marker.etat) {
                            marker.color = '#00C292';
                        } else if ('I' === marker.etat) {
                            marker.color = '#e46a76';
                        } else if ('E' === marker.etat) {
                            marker.color = '#fec107';
                        }
                    }

                    if (marker.estMoyen) {
                        this.missions.forEach(mission => {
                            if (
                                (marker.code === mission.codeMoyen)
                                || (mission.ambulance && mission.ambulanceMoyen && marker.id === 'AMBM' + mission.ambulance.code + '-' + mission.ambulanceMoyen.code)
                            ) {
                                this.updateMission(marker, mission);
                            }
                        });
                    }

                    if(entity.etablissements) {
                        if (marker.estUrgences) {
                            marker.icoClass = 'fa-solid fa-square-u fa-lg';
                        }
                        this.prepareMarkerColor(marker);

                        if (!marker.latLng) {
                            marker.color = '#03a9f3';
                            marker.icoClass = 'fa-solid fa-square-info fa-lg';
                        }
                    }

                    if (marker.theme) {
                        marker.icoClass += ' text-' + marker.theme;
                    }
                },
                prepareMarkerForOsrm (marker) {
                    marker.lngLatOsrm = marker.latLng && marker.latLng.lng && marker.latLng.lat
                        ? parseFloat(marker.latLng.lng).toFixed(6) + ',' + parseFloat(marker.latLng.lat).toFixed(6)
                        : null;
                },
                updateFiltres() {
                    this.$forceUpdate();
                    this.computeFiltrageArbo();
                    this.updateMarkers();
                },
                togglePrimaire(entity) {
                    entity.selected = !entity.selected;
                    this.arbo.forEach(primaire => {
                        if(primaire.selected && primaire.code !== entity.code) {
                            primaire.selected = false;
                        }
                        primaire.secondaires.forEach(secondaire => {
                            secondaire.locked = primaire.selected;
                            secondaire.selected = false;
                        });
                    });
                    this.updateFiltres();
                },
                toggleSecondaire(secondaire) {
                    secondaire.selected = !secondaire.selected;
                    if (secondaire.etablissements && !secondaire.selected) {
                        this.urgenceOnly = false;
                    }
                    if (secondaire.medecins && !secondaire.selected && this.typeMedecin) {
                        this.typeMedecin = null;
                    }
                    if (!secondaire.selected || !secondaire.endpoint) {
                        this.updateFiltres();
                    }
                },
                toggleUrgenceOnly() {
                    this.urgenceOnly = !this.urgenceOnly;
                    this.updateFiltres();
                },
                computeFiltrageArbo() {
                    this.codesEtablissement = [];
                    this.codesMedecin = [];
                    this.codesPharmacie = [];
                    this.codesPoi = [];

                    this.arbo.forEach(primaire => {
                        primaire.secondaires.forEach(secondaire => {
                            if(primaire.selected) {
                                this.codesEtablissement = this.codesEtablissement.concat(secondaire.etablissementCollection.filter(el => !this.codesEtablissement.includes(el)));
                                this.codesMedecin = this.codesMedecin.concat(secondaire.medecinCollection.filter(el => !this.codesMedecin.includes(el)));
                                this.codesPharmacie = this.codesPharmacie.concat(secondaire.pharmacieCollection.filter(el => !this.codesPharmacie.includes(el)));
                                this.codesPoi = this.codesPoi.concat(secondaire.poiCollection.filter(el => !this.codesPoi.includes(el)));
                            } else if (secondaire.selected) {
                                this.codesEtablissement = !this.codesEtablissement.length ? secondaire.etablissementCollection : this.codesEtablissement.filter(el => secondaire.etablissementCollection.includes(el));
                                this.codesMedecin = !this.codesMedecin.length ? secondaire.medecinCollection : this.codesMedecin.filter(el => secondaire.medecinCollection.includes(el));
                                this.codesPharmacie = !this.codesPharmacie.length ? secondaire.pharmacieCollection : this.codesPharmacie.filter(el => secondaire.pharmacieCollection.includes(el));
                                this.codesPoi = !this.codesPoi.length ? secondaire.poiCollection : this.codesPoi.filter(el => secondaire.poiCollection.includes(el));
                            }
                        });
                    });
                },
                countShown(entity) {
                    if (entity.secondaires) {
                        let primaireEtabs = [];

                        entity.secondaires.forEach((secondaire) => {
                            let etabs = this.countSelectedEtablissement(secondaire);
                            secondaire.count = etabs.length;

                            etabs.forEach((code) => {
                                if(primaireEtabs.indexOf(code) === -1) {
                                    primaireEtabs.push(code);
                                }
                            });
                        });

                        return primaireEtabs.length;
                    } else if (entity.codeParent) {
                        return entity.count;
                    } else {
                        return entity.markers.filter((marker) => { return !marker.hidden; }).length;
                    }
                },
                countSelectedEtablissement(secondaire) {
                    const etablissements = this.entities.find(e => e.etablissements),
                        markers = etablissements.markers.filter((marker) => { return !marker.hidden; });

                    if(!this.filtreTexteActif && !this.isFiltreArboActif()) {
                        return secondaire.etablissementCollection;
                    } else {
                        let etablissementCollection = [];

                        markers.forEach((marker) => {
                            if(secondaire.etablissementCollection.indexOf(marker.code) !== -1) {
                                etablissementCollection.push(marker.code);
                            }
                        });

                        return etablissementCollection;
                    }
                },
                updateMarkers(fromWS) {
                    if(!fromWS) {
                        this.page = 1;
                        this.$refs.carto.cleanMarkers();
                        this.$refs.carto.cleanPaths();
                    }

                    this.markersAppel = [];
                    if(this.appel) {
                        if(this.appel.secondaire && this.appel.secondaire.etablissementPriseEnCharge) {
                            this.markersAppel.push('ETB' + this.appel.secondaire.etablissementPriseEnCharge);
                        }
                        if(this.appel.secondaire && this.appel.secondaire.etablissementDestination) {
                            this.markersAppel.push('ETB' + this.appel.secondaire.etablissementDestination);
                        }
                        this.appelMissions.forEach(mission => {
                            if (mission.organisme && mission.organisme.type && mission.moyenCode) {
                                this.markersAppel.push('AMUM' + mission.organisme.type.code + mission.moyenCode);
                            } else if (mission.organisme && mission.ambulance && mission.ambulanceMoyen) {
                                this.markersAppel.push('AMBM' + mission.ambulance.code + '-' + mission.ambulanceMoyen.code);
                            }
                        });
                        this.markersAppel.push(...Object.keys(this.etablissementsDevenir).map(e => 'ETB' + e));
                    }

                    let markers = {};

                    if (this.coordonneesCustom && this.coordonneesCustom.lngLatOsrm) {
                        markers['coordonneesCustom'] = this.coordonneesCustom.info;
                    }

                    this.moyens.filter((moyen) => { return moyen.selected || moyen.markers.find(m => m.estMoyen); }).forEach(moyen => {
                        moyen.markers.filter((marker) => { return this.checkMarkerVisibility(marker, moyen); }).forEach(marker => {
                            markers[marker.id] = marker;
                        });
                    });

                    this.entities.filter((entity) => { return entity.selected || entity.etablissements; }).forEach(entity => {
                        entity.markers.filter((marker) => { return this.checkMarkerVisibility(marker, entity); }).forEach(marker => {
                            if(this.$refs.carto.markersImported[marker.id] && this.$refs.carto.markersImported[marker.id].showAllService) {
                                this.resetAffichageServices(marker);
                            }

                            markers[marker.id] = marker;
                        });
                    });

                    this.pois.filter((poi) => { return poi.selected; }).forEach(poi => {
                        poi.markers.filter((marker) => { return this.checkMarkerVisibility(marker, poi); }).forEach(marker => {
                            markers[marker.id] = marker;
                        });
                    });

                    if(this.secteurSelected && this.secteurSelected.markers) {
                        this.secteurSelected.markers.forEach(marker => {
                            markers[marker.id] = marker;
                            this.prepareMarkerForOsrm(marker);
                        });
                    }

                    if(this.codesMedecin && this.codesMedecin.length) {
                        const medecins = this.entities.find(e => e.medecins),
                            fromArbo = medecins.fromArbo.filter((marker) => this.codesMedecin.indexOf(marker.code) !== -1 && this.checkMarkerVisibility(marker, medecins));

                        fromArbo.forEach(marker => {
                            markers[marker.id] = marker;
                            this.prepareMarker(marker, medecins);
                        });
                    }

                    if(this.codesPharmacie && this.codesPharmacie.length) {
                        const pharmacies = this.entities.find(e => e.pharmacies),
                            fromArbo = pharmacies.fromArbo.filter((marker) => this.codesPharmacie.indexOf(marker.code) !== -1 && this.checkMarkerVisibility(marker, pharmacies));

                        fromArbo.forEach(marker => {
                            markers[marker.id] = marker;
                            this.prepareMarker(marker, pharmacies);
                        });
                    }

                    if(this.codesPoi && this.codesPoi.length) {
                        const pois = this.entities.find(e => e.pois),
                            fromArbo = pois.fromArbo.filter((marker) => this.codesPoi.indexOf(marker.code) !== -1 && this.checkMarkerVisibility(marker, pois));

                        fromArbo.forEach(marker => {
                            markers[marker.id] = marker;
                            this.prepareMarker(marker, pois);
                        });
                    }

                    this.noMatch = (this.filtreTexteActif || this.isFiltreArboActif()) && !Object.entries(markers).length;
                    if(this.noMatch) {
                        this.findHints();
                    }

                    if (this.carto.patient) {
                        markers['dossier'] = this.carto.patient;
                    }
                    if (this.carto.triangulation && this.showTriangulation) {
                        markers['triangulation'] = this.carto.triangulation;
                    }

                    this.updateDistances(markers, this.carto.marker && this.carto.marker.id && markers[this.carto.marker.id] ? markers[this.carto.marker.id] : this.carto.marker);
                },
                updateMarkersPatient(allowTriangulationFromQuery) {
                    this.setCartoPatient(this.appel);
                    this.setCartoTriangulation(this.appel, allowTriangulationFromQuery);

                    this.entities.forEach(entity => {
                        entity.markers.forEach(marker => this.prepareMarker(marker, entity));
                    });

                    this.updateMarkers();
                },
                setMarkers(markers) {
                    Vue.set(this.carto, 'markers', markers);
                    if (this.carto.marker && markers[this.carto.marker.id]) {
                        this.carto.marker = markers[this.carto.marker.id];
                    }
                },
                updateDistances(markers, infoMarker = null) {
                    const origineDossier = this.origineDossier;
                    if (origineDossier) {
                        let calls = [];
                        const canCompute = (marker, type) => marker.latLng
                            && marker.lngLatOsrm
                            && isFinite(marker.latLng.lat)
                            && Math.abs(marker.latLng.lat) <= 90
                            && isFinite(marker.latLng.lng)
                            && Math.abs(marker.latLng.lng) <= 180
                            && !marker.patient
                            && marker.libelle
                            && (!this.getMarkerItineraires(marker) || !this.getMarkerItineraires(marker).type || typeof this.getMarkerItineraires(marker).type.points === 'undefined')
                        ;
                        Object.entries(markers).forEach(([id, marker]) => {
                            const lngLats = this.getMarkerLngLats(marker);

                            if (canCompute(marker, 'oiseau')) {
                                let coords = latLng(origineDossier.split(',').reverse());
                                this.addItineraire(
                                    lngLats.origine,
                                    lngLats.destination,
                                    'oiseau',
                                    Math.trunc(coords.distanceTo(marker.latLng)) / 1000,
                                    Math.round(coords.distanceTo(marker.latLng) / 1000 / 260 * 60)
                                );
                            }
                            if (canCompute(marker, 'osrm')) {
                                calls.push(marker.lngLatOsrm);
                            }
                        })
                        if (calls.length > 0) {
                            this.loadingRoutingMachine = true;
                            // Fait des paquets pour éviter les URLs de plus de 2000 caractères qui peuvent être tronquées
                            const requestChunks = calls.chunk(100);
                            const promises = requestChunks.map(chunk => Request.fetchJson(
                                '/osrm/table/v1/driving/'
                                + origineDossier
                                + ';'
                                + chunk.join(';')
                                + '?sources=0'
                                + '&annotations=distance,duration'
                                + '&skip_waypoints=false'
                            ));

                            Promise.all(promises).then(responseChunks => {
                                responseChunks.forEach((data, indexChunk) => {
                                    let ok = data && 'Ok' === data.code && data.distances && data.distances[0].length > 0;
                                    requestChunks[indexChunk].forEach((destination, indexCall) => {
                                        const distanceVoiture = ok ? Math.trunc(data.distances[0][indexCall + 1]) / 1000 : 0;
                                        const departTrouvee = ok ? data.destinations[0].distance < 1000 : false; // Route trouvée à moins de 1km
                                        const destinationTrouvee = ok ? data.destinations[indexCall + 1].distance < 1000 : false; // Route trouvée à moins de 1km
                                        this.addItineraire(
                                            origineDossier,
                                            destination,
                                            'osrm',
                                            distanceVoiture && departTrouvee && destinationTrouvee ? distanceVoiture : 0,
                                            distanceVoiture && departTrouvee && destinationTrouvee ? Math.trunc(data.durations[0][indexCall + 1] / 60) : 0
                                        );
                                    });
                                });
                            }).catch(() => {
                                // Evite les nouvelles tentatives, tant pis
                                requestChunks.forEach(
                                    chunk => chunk.forEach(
                                        destination => this.addItineraire(origineDossier, destination, 'osrm', 0)
                                    )
                                );
                            }).finally(() => {
                                this.setMarkers(markers);
                                infoMarker && this.infoMarker(infoMarker);
                                this.loadingRoutingMachine = false;
                            });
                        } else {
                            this.setMarkers(markers);
                            infoMarker && this.infoMarker(infoMarker);
                        }
                    } else {
                        this.setMarkers(markers);
                        infoMarker && this.infoMarker(infoMarker);
                    }
                },
                updateMission(marker, mission) {
                    if (marker && mission) {
                        if(!marker.mission || marker.mission.decision.appel.numero !== mission.decision.appel.numero) {
                            marker.mission = mission;
                        }

                        const origine = marker.latLng.lng + ',' + marker.latLng.lat;
                        let cible = null;

                        if (mission.topPriseEnChargeDepartReel && mission._metaDestinationLatLng && mission._metaDestinationLatLng[0] && mission._metaDestinationLatLng[0].latitude) {
                            cible = mission._metaDestinationLatLng[0].longitude.trim() + ',' + mission._metaDestinationLatLng[0].latitude.trim();
                        } else if(mission.decision.appel.geolocalisation && mission.decision.appel.geolocalisation.latitude) {
                            cible = mission.decision.appel.geolocalisation.longitude.trim() + ',' + mission.decision.appel.geolocalisation.latitude.trim();
                        }

                        if(cible) {
                            Request.fetchJson(
                                '/osrm/route/v1/driving/' + origine + ';' + cible + '?overview=full&geometries=geojson'
                            ).then(data => {
                                const points = data.routes[0].geometry.coordinates.map(point => latLng(point[1], point[0]));

                                Vue.set(marker, 'trajet', {
                                    distance: Math.trunc(data.routes[0].distance) / 1000,
                                    temps: Math.trunc(data.routes[0].duration / 60),
                                    points: points,
                                });
                            });
                        }
                    }
                },
                checkMarkerVisibility(marker, entity) {
                    let hidden = false,
                        string = marker.libelle
                            + (marker.complement ? ' ' + marker.complement : '')
                            + (marker.adresse ? ' ' + marker.adresse.join(' ') : '')
                            + (marker.communeAbreviation ? ' ' + marker.communeAbreviation : '')
                    ;

                    if(marker.urgences || marker.services) {
                        this.checkServiceVisibility(marker);
                    }

                    const matchService = (marker.urgences && marker.urgences.filter(s => !s.hide).length) || (marker.services && marker.services.filter(s => !s.hide).length);

                    hidden = !entity.dae
                        && this.filtreTexteActif
                        && !matchService
                        && this.cleanedKeywords
                            .split(' ')
                            .some(word => string.toUpperCase().indexOf(word) === -1)
                    ;

                    if (entity.etablissements) {
                        let show = entity.selected;

                        if (this.urgenceOnly && !marker.estUrgences) {
                            show = false;
                        }

                        if (this.arbo.length !== 0 && this.isFiltreArboActif() && this.codesEtablissement) {
                            show = this.codesEtablissement.indexOf(marker.code) !== -1;
                        }

                        hidden = hidden || !show;

                        if (!marker.latLng && this.config.etbGeoOnly) {
                            hidden = true;
                        }
                    }

                    if (entity.medecins && this.codesMedecin.length) {
                        hidden = hidden || this.codesMedecin.indexOf(marker.code) === -1;
                    }

                    if (entity.pharmacies && this.codesPharmacie.length) {
                        hidden = hidden || this.codesPharmacie.indexOf(marker.code) === -1;
                    }

                    if (entity.pois && this.codesPoi.length) {
                        hidden = hidden || this.codesPoi.indexOf(marker.code) === -1;
                    }

                    if (marker.estMoyen) {
                        hidden = !entity.selected || (this.config.onMissionOnly && !marker.mission);
                    }

                    if (this.markersAppel && this.markersAppel.indexOf(marker.id) !== -1) {
                        hidden = false;
                    }

                    marker.hidden = hidden;

                    return !hidden;
                },
                checkServiceVisibility(marker) {
                    if(this.filtreTexteActif) {
                        const k = this.cleanedKeywords.replace(' ', '').replace('-', '');

                        if (marker.urgences) {
                            marker.urgences.forEach(service => {
                                const stringService = (service.libelle.replace(' ', '').replace('-', '') + ' ' + service.uf).toUpperCase();
                                const match = stringService.indexOf(k) !== -1 || (
                                    k.length >= 6 && levenshtein(stringService.substr(0, k.length), k) <= 1
                                );

                                service.hide = !match;
                            });
                        }

                        if (marker.services) {
                            marker.services.forEach(service => {
                                const stringService = (service.libelle.replace(' ', '').replace('-', '') + ' ' + service.uf).toUpperCase();
                                const match = stringService.indexOf(k) !== -1 || (
                                    k.length >= 6 && levenshtein(stringService.substr(0, k.length), k) <= 1
                                );

                                service.hide = !match;
                            });
                        }
                    }
                },
                scrollObservations() {
                    setTimeout(() => {
                        const obsList = document.getElementById('observations_list');
                        if (obsList) {
                            obsList.scrollTo(0, obsList.scrollHeight);
                        }
                        this.$refs.newObservationField && this.$refs.newObservationField.$el.focus();
                    }, 100);
                },
                centrerPaths(marker) {
                    const itineraires = this.getMarkerItineraires(marker);

                    if(itineraires && (itineraires.osrm || itineraires.fastest || itineraires.shortest)) {
                        this.$refs.carto.fitAllPaths();
                    }

                    if(marker.trajet) {
                        this.$refs.carto.fitPoints(marker.trajet.points);
                    }
                },
                centrerMarker(marker) {
                    if(marker.latLng) {
                        if (!marker.patient) {
                            this.carto.cursor = marker.id;
                        }

                        this.$refs.carto.selectMarker(marker.id);
                        this.$refs.carto.fitMarker(marker);
                    }
                },
                infoMarker(marker) {
                    if (marker.dossier) {
                        this.selectAppel(marker.numero, true);
                    } else if (marker.patient) {
                        this.carto.rightSidebar = 'appel';
                    } else {
                        if (!this.carto.marker || this.carto.marker.id !== marker.id) {
                            this.horairesEdited = null;
                            this.detailSelected = null;
                            this.newCommentaire.opened = false;
                        }

                        this.carto.marker = marker;
                        this.carto.cursor = marker.id;

                        this.page = this.markersArray.indexOf(marker) !== -1 ? Math.floor((this.markersArray.indexOf(marker) + parseInt(this.nbParPage)) / parseInt(this.nbParPage)) : 1;

                        if (marker.latLng && marker.latLng.lat && marker.latLng.lng) {
                            // Affiche le trajet OSRM
                            const origineDossier = this.origineDossier;
                            const itineraires = this.getMarkerItineraires(marker);

                            if (itineraires && itineraires.osrm && itineraires.osrm.distance && !itineraires.osrm.points) {
                                const lngLats = this.getMarkerLngLats(marker);

                                Request.fetchJson(
                                    '/osrm/route/v1/driving/' + lngLats.origine + ';' + lngLats.destination + '?overview=full&geometries=geojson'
                                ).then(data => {
                                    const points = data.routes[0].geometry.coordinates.map(point => latLng(point[1], point[0]));

                                    this.addItineraire(
                                        lngLats.origine,
                                        lngLats.destination,
                                        'osrm',
                                        Math.trunc(data.routes[0].distance) / 1000,
                                        Math.trunc(data.routes[0].duration / 60),
                                        points,
                                    );

                                    // Si le marker est toujours sélectionné, on affiche le trajet
                                    if (marker.id === this.carto.cursor && this.origineDossier === origineDossier) {
                                        this.$refs.carto.cleanPaths();
                                        this.$refs.carto.setPath('osrm', points);
                                    }
                                });
                            } else if (itineraires) {
                                this.$refs.carto.cleanPaths();
                                Object.entries(itineraires).forEach(([type, itineraire]) => {
                                    this.$refs.carto.setPath(type, itineraire.points);
                                });
                            }
                        }
                    }

                    this.$refs.carto.selectMarker(marker.id);
                },
                itineraire(marker) {
                    const types = ['shortest', 'fastest'];
                    if (marker !== this.carto.patient) {
                        if (marker.shortest || marker.fastest) {
                            this.$refs.carto.fitAllPaths();
                            return;
                        }

                        // Pour OSRM et le cache, on enregistre lngLat, pour les appels tomtom c'est latLng...
                        const lngLats = this.getMarkerLngLats(marker);
                        const tomTom = {
                            origine: lngLats.origine.split(',').reverse().join(','),
                            destination: lngLats.destination.split(',').reverse().join(','),
                        };

                        this.loadingItineraire = true;
                        Request.fetchJson('/rastik/routing', 'POST', {
                            batchItems: types.map(type => ({
                                query: '/calculateRoute/' + tomTom.origine + ':' + tomTom.destination + '/json?'
                                    + new URLSearchParams({
                                        language: 'fr-FR',
                                        maxAlternatives: 0,
                                        traffic: true,
                                        travelMode: 'car',
                                        departAt: 'now',
                                        routeType: type,
                                    }).toString()
                            }))
                        })
                        .then(data => {
                            types.forEach((type, index) => {
                                if (data.batchItems[index].response && data.batchItems[index].response.routes && data.batchItems[index].response.routes[0]) {
                                    const item = data.batchItems[index].response.routes[0],
                                        points = item.legs[0].points.map(point => latLng(point.latitude, point.longitude));

                                    this.$refs.carto.setPath(type, points);

                                    this.addItineraire(
                                        lngLats.origine,
                                        lngLats.destination,
                                        type,
                                        item.summary.lengthInMeters / 1000,
                                        Math.trunc(item.summary.travelTimeInSeconds / 60),
                                        points
                                    );
                                }
                            });

                            this.$refs.carto.fitAllPaths();
                        })
                        .finally(() => {
                            this.loadingItineraire = false;
                        });
                    }
                },
                showTransportSecondaire(transport) {
                    if (transport.etablissementPriseEnCharge && transport.etablissementDestination) {
                        const etablissements = this.entities.find(e => true === e.etablissements);

                        const from = etablissements ? etablissements.markers.find(m => m.code === transport.etablissementPriseEnCharge) : null;
                        const to = etablissements ? etablissements.markers.find(m => m.code === transport.etablissementDestination) : null;

                        if (from && from.latLng && to && to.latLng) {
                            const locations = from.latLng.lat + ','  + from.latLng.lng + ':' + to.latLng.lat + ','  + to.latLng.lng;
                            Request.fetchJson('/rastik/routing', 'POST', {
                                batchItems: [{
                                    query: '/calculateRoute/'
                                        + locations
                                        + '/json?'
                                        + new URLSearchParams({
                                            language: 'fr-FR',
                                            maxAlternatives: 0,
                                            traffic: true,
                                            travelMode: 'car',
                                            departAt: 'now',
                                            routeType: 'fastest',
                                        }).toString()
                                }]
                            }).then(data => {
                                this.$refs.carto.cleanPaths();
                                this.$refs.carto.setPath('secondaire', data.batchItems[0].response.routes[0].legs[0].points.map(point => latLng(point.latitude, point.longitude)));
                                this.$refs.carto.fitAllPaths();
                            });
                        }
                    }
                },
                showDevenirEtablissement(code, service, codeService) {
                    const etablissements = this.entities.find(e => true === e.etablissements);
                    const marker = etablissements ? etablissements.markers.find(m => m.code === code) : null;

                    if (marker) {
                        this.centrerMarker(marker);
                        this.infoMarker(marker);
                        if (marker.services && marker.services.length > 0) {
                            if (codeService) {
                                this.detailSelected = marker.services.find(s => s.numero === codeService) || marker.urgences.find(s => s.numero === codeService) || null;
                            } else if (service) {
                                this.detailSelected = marker.services.find(s => s.libelle === service) || marker.urgences.find(s => s.libelle === service) || null;
                            }
                        }
                    }
                },
                resetMarker() {
                    this.horairesEdited = null;
                    this.detailSelected = null;
                    this.carto.marker = null;
                    this.newCommentaire.opened = false;
                    this.$refs.carto.cleanPaths();
                },
                applyObservationModele(modele) {
                    let content = this.newObservation.contenu || '';
                    let initialLength = content.length;

                    if (content !== '') {
                        initialLength += 2
                        content += '\n\n';
                    }

                    content += modele.texte;
                    this.newObservation.contenu = content;
                },
                saveNewObservation() {
                    this.newObservation.saving = true;

                    Request.fetchJson(
                        Router.generate('appel.observation.add', {appel: this.appel.numero}),
                        'POST',
                        this.normalize(JSON.parse(JSON.stringify(this.forms.newObservation.skeleton)), this.newObservation)
                    ).then((response) => {
                        if (response.success) {
                            this.newObservation.errors = {};
                            this.newObservation.contenu = '';
                            this.appel = response.appel;
                        } else {
                            this.newObservation.errors = response.errors;
                        }
                    }, () => this.newObservation.errors = {'': ['Une erreur est survenue, les données n\'ont pas pu être enregistrées.']})
                    .finally(() => this.newObservation.saving = false);
                },
                saveNewCommentaire(codeEtablissement) {
                    this.newCommentaire.saving = true;

                    Request.fetchJson(
                        Router.generate('carto.commentaire.add', {codeEtablissement: codeEtablissement}),
                        'POST',
                        {contenu: this.newCommentaire.contenu, original: this.newCommentaire.original, _token: this.csrf.commentaire}
                    ).then((response) => {
                        if (response.success) {
                            if (this.newCommentaire.original) {
                                for (let i in this.carto.marker.commentaires) {
                                    if (this.newCommentaire.original === this.carto.marker.commentaires[i].id) {
                                        this.carto.marker.commentaires.splice(i, 1);
                                    }
                                }
                            }

                            this.newCommentaire.opened = false;
                            this.newCommentaire.errors = {};
                            this.newCommentaire.contenu = null;
                            this.newCommentaire.original = null;

                            this.carto.marker.commentaires.unshift(response.commentaire);
                        } else {
                            this.newCommentaire.errors = response.errors;
                        }
                    }, () => this.newCommentaire.errors = {'': ['Une erreur est survenue, les données n\'ont pas pu être enregistrées.']})
                    .finally(() => this.newCommentaire.saving = false);
                },
                formCommentaire(commentaire) {
                    this.newCommentaire.contenu = commentaire ? commentaire.contenu : null;
                    this.newCommentaire.original = commentaire ? commentaire.id : null;
                    this.newCommentaire.opened = true;
                },
                deleteCommentaire(commentaire) {
                    commentaire.deleting = true;

                    Request.fetchJson(
                        Router.generate('carto.commentaire.delete', { id: commentaire.id }),
                        'DELETE',
                        {
                            delete: {
                                submitted: '1',
                                _token: this.csrf.delete,
                            }
                        }
                    ).then((response) => {
                        if (response.success) {
                            for (let i in this.carto.marker.commentaires) {
                                if (response.id === this.carto.marker.commentaires[i].id) {
                                    this.carto.marker.commentaires.splice(i, 1);
                                }
                            }
                        }
                    })
                    .finally(() => commentaire.deleting = false);
                },
                selectAppel(numero, existingMarker, force) {
                    if (force) {
                        this.newObservation.contenu = '';
                    }
                    if ((this.newObservation.contenu ?? '').trim().length > 0 && this.appel && this.appel.numero && !force) {
                        this.selectAppelArgs = [numero, existingMarker, true];
                        this.$bvModal.show('modal-alerte-observation');
                        return;
                    }

                    Request.fetchJson(Router.generate('carto.appel.json', { appel: numero }))
                        .then(data => {
                            this.coordonneesCustom = null;
                            this.appel = data.appel;

                            this.carto.rightSidebar = 'appel';
                            this.updateMarkersPatient(false);

                            if (this.carto.patient) {
                                if(!existingMarker) {
                                    this.centrerMarker(this.carto.patient);
                                }
                                this.$refs.carto.selectMarker(this.carto.patient.id);
                            } else if (this.carto.triangulation) {
                                if(!existingMarker) {
                                    this.centrerMarker(this.carto.triangulation);
                                }
                            }
                        });
                },
                initMap() {
                    this.setCartoPatient(this.appel);
                    this.setCartoTriangulation(this.appel, true);

                    this.moyens.forEach(moyen => {
                        moyen.markers.forEach(marker => this.prepareMarker(marker, moyen));
                    });
                    this.entities.forEach(entity => {
                        entity.markers.forEach(marker => this.prepareMarker(marker, entity));
                    });
                    this.pois.forEach(poi => {
                        poi.markers.forEach(marker => this.prepareMarker(marker, poi));
                    });

                    this.updateMarkers();

                    if (this.carto.patient) {
                        this.$refs.carto.selectMarker('dossier');
                        this.$refs.carto.centrer(this.carto.patient.latLng);
                    } else if (this.carto.triangulation) {
                        this.$refs.carto.centrer(this.carto.triangulation.latLng);
                    } else {
                        this.$refs.carto.centrer();
                    }

                    this.$refs.carto.zoomer();
                },
                refreshHorloge: function () {
                    const jourActuel = {1: 'lundi', 2:  'mardi', 3: 'mercredi', 4: 'jeudi', 5: 'vendredi', 6: 'samedi', 7: 'dimanche'}[moment().isoWeekday()];
                    if (this.horlogeJour !== jourActuel) {
                        this.horlogeJour = jourActuel;
                    }
                    const heureActuelle = moment().hour();
                    if (this.horlogeHeure !== heureActuelle) {
                        this.horlogeHeure = heureActuelle;
                    }
                    const prochaineHeure = moment().add(1, 'hour').minutes(0).seconds(0).milliseconds(0);
                    setTimeout(() => this.refreshHorloge(), prochaineHeure.diff(moment(), 'seconds', false) * 1000);
                },
                showSecteur(secteur) {
                    this.$refs.carto.hideAllCommunes();

                    if(secteur) {
                        secteur.communeCollection.forEach(insee => {
                            this.$refs.carto.setCommunesState(insee, 'selected_noborder_soft');
                        });
                    } else if (this.secteurSelected) {
                        this.secteurSelected.communeCollection.forEach(insee => {
                            this.$refs.carto.setCommunesState(insee, 'selected_noborder_light');
                        });
                    }

                    this.$refs.carto.applyCommunesState();
                },
                selectSecteur(secteur) {
                    this.secteurSelected = secteur;
                    this.secteurListed = null;

                    this.showSecteur();
                    this.updateMarkers();
                },
                resetSecteur() {
                    this.$refs.carto.hideAllCommunes();

                    this.secteurSelected = null;
                    this.secteurListed = null;
                },
                resetFiltres() {
                    this.keywords = '';
                    this.primaireSelected = null;

                    this.moyens.filter((moyen) => { return moyen.selected; }).forEach(moyen => {
                        moyen.selected = false;
                    });

                    this.entities.filter((entity) => { return entity.selected }).forEach(entity => {
                        entity.selected = false;
                    });

                    this.pois.filter((poi) => { return poi.selected }).forEach(poi => {
                        poi.selected = false;
                    });

                    this.arbo.forEach(primaire => {
                        primaire.selected = false;
                        primaire.secondaires.forEach(secondaire => {
                            secondaire.selected = false;
                            secondaire.locked = false;
                        });
                    });
                    this.computeFiltrageArbo();

                    this.updateMarkers();
                },
                getClassForMission(mission) {
                    return CLASS_ORGANISME_FORMATTER(mission.organisme);
                },
                toggleRegulation(type) {
                    if(type) {
                        this.regulation[type] = !this.regulation[type];
                    } else {
                        const state = !this.affichageRegulation;
                        this.regulation.appels = state;
                        this.regulation.demandes = state;
                        this.regulation.missions = state;
                    }

                    this.updateMarkers();

                    if(this.affichageRegulation && this.appel && this.$refs.carto.markerSelected === 'dossier') {
                        this.$refs.carto.selectMarker('APP' + this.appel.numero);
                    } else if(!this.affichageRegulation && this.appel && this.$refs.carto.markerSelected === ('APP' + this.appel.numero)) {
                        this.$refs.carto.selectMarker('dossier');
                    }
                },
                roundFloat(float) {
                    return Math.round(float * 100) / 100;
                },
                switchModeHoraires(object) {
                    if(object && object.formHoraires) {
                        if (object.formHoraires.semaine) {
                            object.formHoraires = {
                                'lundi': [{debut: '', fin: ''}],
                                'mardi': [{debut: '', fin: ''}],
                                'mercredi': [{debut: '', fin: ''}],
                                'jeudi': [{debut: '', fin: ''}],
                                'vendredi': [{debut: '', fin: ''}],
                                'samedi': [{debut: '', fin: ''}],
                                'dimanche': [{debut: '', fin: ''}]
                            };
                        } else {
                            object.formHoraires = {
                                'semaine': [{debut: '', fin: ''}]
                            };
                        }

                        if(object.horaires) {
                            ['semaine', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'].forEach(key => {
                                if (object.formHoraires[key] && object.horaires[key]) {
                                    object.formHoraires[key] = object.horaires[key];
                                }
                            });
                        }
                    }
                },
                addHoraires(horaires, index) {
                    if(horaires) {
                        if(!horaires[index]) {
                            horaires[index] = [];
                        }
                        horaires[index].push({debut: '', fin: ''});
                        this.$forceUpdate();
                    }
                },
                presetHoraires(horaires, index, preset) {
                    if(horaires && presets[preset]) {
                        horaires[index] = JSON.parse(JSON.stringify(presets[preset]));
                        this.$forceUpdate();
                    }
                },
                saveHoraires(object) {
                    this.savingHoraires = true;

                    let url  = object.codeEtablissement ?
                        Router.generate('service.updateHoraires', {code: object.codeEtablissement + '-' + object.numero}) :
                        Router.generate('etablissement.updateHoraires', {code: object.code});
                    let csrf  = object.codeEtablissement ? this.csrf.serviceHoraires : this.csrf.horaires;

                    Request.fetchJson(url, 'POST', {horaires: JSON.stringify(object.formHoraires), _token: csrf}).then((response) => {
                        if (response.success) {
                            const json = JSON.stringify(response.success);
                            object.horaires = JSON.parse(json);
                            object.formHoraires = JSON.parse(json);
                        } else {
                            object.horaires = null;
                            object.formHoraires = {};
                        }

                        this.prepareMarkerColor(object);

                        // maj du marker sur la carte
                        if(this.$refs.carto.markersImported[object.id]) {
                            Vue.set(this.$refs.carto.markersImported[object.id], 'horaires', object.horaires);
                            Vue.set(this.$refs.carto.markersImported[object.id], 'currentHoraires', object.currentHoraires);
                            Vue.set(this.$refs.carto.markersImported[object.id], 'color', object.color);
                        }

                        // maj bloc entité
                        if(object.id && this.carto.marker && object.id === this.carto.marker.id) {
                            Vue.set(this.carto.marker, 'horaires', object.horaires);
                            Vue.set(this.carto.marker, 'currentHoraires', object.currentHoraires);
                            Vue.set(this.carto.marker, 'color', object.color);
                        }

                        this.$forceUpdate();
                    })
                    .finally(() => {
                        this.horairesEdited = null;
                        this.savingHoraires = false;
                    });
                },
                getCurrentHoraires(entity) {
                    if(entity && entity.horaires && !entity.dae) {
                        let current = 'ferme';

                        if (entity.horaires.semaine) {
                            entity.horaires.semaine.forEach(h => {
                                const splitDebut = h.debut.replace('h', ':').split(':'), splitFin = h.fin.replace('h', ':').split(':'),
                                    debut = moment().hour(parseInt(splitDebut[0]) ?? 0).minute(parseInt(splitDebut[1]) ?? 0).second(0),
                                    fin = moment().hour(parseInt(splitFin[0]) ?? 0).minute(parseInt(splitFin[1]) ?? 0 ).second(0);

                                if(moment().isBetween(debut, fin)) {
                                    current = h;
                                }
                            });
                        } else if(entity.horaires[this.horlogeJour]) {
                            entity.horaires[this.horlogeJour].forEach(h => {
                                const splitDebut = h.debut.replace('h', ':').split(':'), splitFin = h.fin.replace('h', ':').split(':'),
                                    debut = moment().hour(splitDebut[0] ? parseInt(splitDebut[0]) : 0).minute(splitDebut[1] ? parseInt(splitDebut[1]) : 0).second(0),
                                    fin = moment().hour(splitFin[0] ? parseInt(splitFin[0]) : 0).minute(splitFin[1] ? parseInt(splitFin[1]) : 0).second(0);

                                if(moment().isBetween(debut, fin)) {
                                    current = h;
                                }
                            });
                        }

                        return current;
                    }

                    return null;
                },
                prepareMarkerColor(object) {
                    object.color = '#00c292';
                    object.currentHoraires = this.getCurrentHoraires(object);

                    if ('ferme' === object.currentHoraires) {
                        object.color = '#e46a76';
                    } else if (!object.currentHoraires) {
                        object.color = '#6c757d';
                    }

                    if(object.urgences) {
                        object.urgences.forEach(urgence => {
                            this.prepareMarkerColor(urgence);
                        });
                    }
                    if(object.services) {
                        object.services.forEach(service => {
                            this.prepareMarkerColor(service);
                        });
                    }
                },
                refreshSecteursMatch() {
                    if (this.appel
                        && this.appel.primaire
                        && this.appel.primaire.codeInsee
                        && this.secteurs.VSAB
                        && this.secteurs.SMUR
                        && this.secteurs.ATSU
                        && this.secteurs.MG
                        && this.secteurs.PHAR
                        && this.secteurs.AUTRE
                    ) {
                        this.secteurs.secteursMatch = null;
                        this.secteurs.gardesMatch = null;

                        let secteursMatch = [];
                        let gardesMatch = [];
                        for (const [type, secteurs] of Object.entries(this.secteurs)) {
                            if(type !== 'secteursMatch' && type !== 'gardesMatch') {
                                secteurs.filter(s => s.communeCollection.length).forEach(secteur => {
                                    if(secteur.communeCollection.indexOf(this.appel.primaire.codeInsee) !== -1) {
                                        secteursMatch.push(secteur);
                                        if (type === 'SMUR' || type === 'VSAB' || (secteur.estGarde && secteur.gardeEnCours)) {
                                            gardesMatch.push(secteur);
                                        }
                                    }
                                });
                            }
                        }

                        this.secteurs.secteursMatch = secteursMatch;
                        this.secteurs.gardesMatch = gardesMatch;
                    }
                },
                latlngToTilePixel(lat, lng) {
                    const mapObject = this.$refs.carto.$refs.map.mapObject;
                    const zoom = mapObject.getZoom();
                    const pixelOrigin = mapObject.getPixelOrigin();

                    const layerPoint = mapObject.options.crs.latLngToPoint(latLng(lat, lng), zoom).floor()
                    const tile = layerPoint.divideBy(256).floor()
                    const tileCorner = tile.multiplyBy(256).subtract(pixelOrigin)
                    const tilePixel = layerPoint.subtract(pixelOrigin).subtract(tileCorner)

                    return {x: tile.x, y: tile.y, z: zoom, i: tilePixel.x, j: tilePixel.y};
                },
                onClickMap(event) {
                    this.$refs.carto.$refs.map.mapObject.eachLayer(layer => {
                        if (layer instanceof L.TileLayer && 'reperes_routiers' === layer.options.id) {
                            const coordinates = this.latlngToTilePixel(event.latlng.lat, event.latlng.lng);

                            if (coordinates.z >= layer.options.minZoom && coordinates.z <= layer.options.maxNativeZoom) {
                                Request.fetchJson(
                                    global.tilesProviders(true).reperes_routiers.url
                                        .replace('Request=GetTile', 'Request=GetFeatureInfo')
                                        .replace('{x}', coordinates.x)
                                        .replace('{y}', coordinates.y)
                                        .replace('{z}', coordinates.z)
                                        + '&I=' + coordinates.i
                                        + '&J=' + coordinates.j
                                        + '&INFOFORMAT=application/json'
                                ).then(data => {
                                    if (data && data.features && data.features[0] && data.features[0].properties) {
                                        L.popup()
                                            .setLatLng(latLng(event.latlng.lat, event.latlng.lng))
                                            .setContent(data.features[0].properties.route + ' - ' + data.features[0].properties.numero)
                                            .openOn(this.$refs.carto.$refs.map.mapObject)
                                        ;
                                    }
                                });
                            }
                        }
                    });
                },
                openContextMenu(event) {
                    this.contextMenu.lat = event.latlng.lat.toFixed(6);
                    this.contextMenu.lng = event.latlng.lng.toFixed(6);
                    this.contextMenu.dms = this.convertDMS(event.latlng.lat, event.latlng.lng);
                    this.$refs.contextMenu.open();
                },
                toDMS(coordinate) {
                    const absolute = Math.abs(coordinate);
                    const degrees = Math.floor(absolute);
                    const minutesNotTruncated = (absolute - degrees) * 60;
                    const minutes = Math.floor(minutesNotTruncated);
                    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

                    return degrees + '° ' + minutes + '′ ' + seconds + '″';
                },
                convertDMS(lat, lng) {
                    const latitude = this.toDMS(lat);
                    const latitudeCardinal = lat >= 0 ? 'N' : 'S';
                    const longitude = this.toDMS(lng);
                    const longitudeCardinal = lng >= 0 ? 'E' : 'O';

                    return latitude + ' ' + latitudeCardinal + ' ' + longitude + ' ' + longitudeCardinal;
                },
                clipboardCopy(text) {
                    App.Layout.clipboardCopy(text)
                },
                openWindy(lat, lng) {
                    window.open('https://www.windy.com/?' + lat + ',' + lng + ',' + this.$refs.carto.$refs.map.mapObject.getZoom(), 'windy');
                },
                formParametrage(entity, type, lat, lng) {
                    this.form.type = type;
                    if (entity) {
                        this.form.entity = entity;
                    } else {
                        this.form.entity = {
                            debut: new Date().toLocaleDateString('fr-FR') + ' ' + new Date().toLocaleTimeString('fr-FR', {timeStyle: 'short'}),
                            estActif: true,
                            etat: 'D',
                            icone: this.forms[type].choices && this.forms[type].choices.icone ? Object.keys(this.forms[type].choices.icone)[0] : null,
                        };
                        if ('poi' === type) {
                            this.form.entity.type = this.forms[type].choices.type[0];
                        }
                    }

                    if (lat && lng) {
                        this.form.entity.latitude = lat;
                        this.form.entity.longitude = lng;
                        this.form.loadingAdresse = true;
                        Request.fetchJson('https://api-adresse.data.gouv.fr/reverse/?lat=' + lat + '&lon=' + lng)
                            .then(data => {
                                if (data.features.length) {
                                    Vue.set(this.form.entity, 'adresse1', data.features[0].properties.housenumber);
                                    Vue.set(this.form.entity, 'noVoie', data.features[0].properties.housenumber);
                                    Vue.set(this.form.entity, 'adresse2', data.features[0].properties.street);
                                    Vue.set(this.form.entity, 'voie', data.features[0].properties.street);
                                    Vue.set(this.form.entity, 'insee', data.features[0].properties.citycode);
                                    Vue.set(this.form.entity, 'commune', {
                                        id: data.features[0].properties.citycode,
                                        codeInsee: data.features[0].properties.citycode,
                                        codePostal: data.features[0].properties.postcode,
                                        libelle: data.features[0].properties.city,
                                    });
                                }
                            })
                            .finally(() => this.form.loadingAdresse = false)
                        ;
                    }

                    this.form.saving = false;
                    this.form.errors = {};
                    this.form.open = true;
                },
                saveNewParametrage() {
                    this.form.saving = true;
                    const data = this.normalize(this.forms[this.form.type].skeleton, this.form.entity);
                    let url = this.form.entity.id
                        ? Router.generate('parametrage.edit', {type: this.form.type, id: this.form.entity.id, markers: true})
                        : Router.generate('parametrage.new', {type: this.form.type, markers: true})
                    ;
                    Request.fetchJson(url, 'POST', data)
                        .then(response => {
                            if (response.success) {
                                if ('poi' === this.form.type) {
                                    const indexPoi = this.pois.findIndex(e => this.form.entity.type && e.code === this.form.entity.type.code);
                                    if (indexPoi !== -1) {
                                        response.markers.forEach(m => this.prepareMarker(m, this.pois[indexPoi]))
                                        this.pois[indexPoi].markers = response.markers;
                                        this.pois[indexPoi].selected = true;
                                        let newMarker = response.markers.find(m => m.code === response.wrapper.entity.code);
                                        if (newMarker) {
                                            this.infoMarker(newMarker);
                                            this.centrerMarker(newMarker);
                                        }
                                    }
                                } else if ('carto_evenement' === this.form.type) {
                                    const indexEntity = this.entities.findIndex(e => e.evenements === true);
                                    response.markers.forEach(marker => this.prepareMarker(marker, this.entities[indexEntity]))
                                    this.entities[indexEntity].markers = response.markers;
                                    this.entities[indexEntity].selected = true;
                                    let newMarker = response.markers.find(m => m.id === 'EVT' + response.wrapper.entity.id);
                                    if (newMarker) {
                                        this.infoMarker(newMarker);
                                        this.centrerMarker(newMarker);
                                    }
                                }
                                this.updateMarkers(true);
                                this.form.open = false;
                            } else {
                                this.form.errors = response.errors;
                            }
                        })
                        .finally(() => this.form.saving = false)
                    ;
                },
                saveNewCartoPoi() {
                    this.form.saving = true;
                    const data = this.normalize(this.forms.carto_evenement.skeleton, this.form.entity);
                    let url = this.form.entity.id
                        ? Router.generate('parametrage.edit', {type: 'carto_evenement', id: this.form.entity.id, markers: true})
                        : Router.generate('parametrage.new', {type: 'carto_evenement', markers: true})
                    ;
                    Request.fetchJson(url, 'POST', data)
                        .then(response => {
                            if (response.success) {
                                const indexEntity = this.entities.findIndex(e => e.evenements === true);
                                response.markers.forEach(marker => this.prepareMarker(marker, this.entities[indexEntity]))
                                this.entities[indexEntity].markers = response.markers;
                                this.updateMarkers(true);
                                this.form.open = false;
                            } else {
                                this.form.errors = response.errors;
                            }
                        })
                        .finally(() => this.form.saving = false)
                    ;
                },
                resetAffichageServices(marker) {
                    marker.showAllService = true;

                    if (marker.urgences) {
                        marker.urgences.forEach(service => {
                            service.hide = false;
                        });
                    }
                    if (marker.services) {
                        marker.services.forEach(service => {
                            service.hide = false;
                        });
                    }
                },
                isFiltreArboActif() {
                    return this.arbo.filter(p => p.selected || p.secondaires.filter(s => s.selected).length).length > 0;
                },
                findHints() {
                    this.noMatchHints = {};

                    if(this.filtreTexteActif) {
                        this.entities.filter((entity) => { return entity.etablissements; }).forEach(entity => {
                            entity.markers.forEach(marker => {
                                if (marker.urgences) {
                                    marker.urgences.forEach(service => {
                                        this.checkHints(service.libelle, true);
                                    });
                                }
                                if (marker.services) {
                                    marker.services.forEach(service => {
                                        this.checkHints(service.libelle, true);
                                    });
                                }

                                this.checkHints(marker.libelle);
                            });
                        });
                    }
                },
                checkHints(libelle, partial) {
                    if(this.noMatchHints.length >= 20) {
                        return false;
                    }

                    const k = this.cleanedKeywords.replace(' ', '').replace('-', ''),
                          l = libelle.replace(' ', '').replace('-', '').toUpperCase();

                    if(levenshtein(l.substr(0, k.length), k) <= 2) {
                        this.noMatchHints[libelle] = libelle;
                    } else {
                        libelle.split(' ').forEach(word => {
                            const w = word.replace(' ', '').replace('-', '').toUpperCase();

                            if(w.length > 2 && k.length > 2 && levenshtein(w.substr(0, k.length), k) <= 2) {
                                const hint = partial ? word : libelle;

                                this.noMatchHints[hint] = hint;
                            }
                        })
                    }
                },
                toggleConfig(config) {
                    Vue.set(this.config, config, !this.config[config]);

                    this.updateMarkers();
                },
                generateSynoptique(mode) {
                    window.open(Router.generate('carto.dossier', {
                        session: 'kiosk',
                        dossier: mode,
                        lat: this.$refs.carto.$refs.map.mapObject.getCenter().lat,
                        long: this.$refs.carto.$refs.map.mapObject.getCenter().lng,
                        zoom: this.$refs.carto.$refs.map.mapObject.getZoom(),
                        forme: '',
                    }), '_blank', 'noreferrer');
                },
                handleKiosk() {
                    if(this.kiosk) {
                        this.regulation.appels = true;
                        this.regulation.demandes = true;
                        this.regulation.missions = true;

                        this.config.regroupement = false;
                        this.config.hideCards = true;
                        this.config.onMissionOnly = this.kiosk === 'mission';

                        if(this.kiosk === 'mission' || this.kiosk === 'full') {
                            this.moyens.filter(m => m.webservice).forEach(m => {
                                m.selected = true;
                            });
                        }
                    }
                },
                selectMoyen(organisme, moyenCode, ambulance, ambulanceMoyen) {
                    if (!organisme || !organisme.type) {
                        return;
                    }
                    // Cible le moyen si possible
                    for (let i = 0; i < this.moyens.length; i++) {
                        for (let j = 0; j < this.moyens[i].markers.length; j++) {
                            if (
                                (moyenCode && this.moyens[i].markers[j].id === 'AMUM' + organisme.type.code + moyenCode)
                                || (ambulanceMoyen && this.moyens[i].markers[j].id === 'AMBM' + ambulance.code + '-' + ambulanceMoyen.code)
                            ) {
                                this.infoMarker(this.moyens[i].markers[j]);
                                return;
                            }
                        }
                    }
                    // Cible la base à défaut
                    for (let i = 0; i < this.moyens.length; i++) {
                        for (let j = 0; j < this.moyens[i].markers.length; j++) {
                            if (
                                (this.moyens[i].markers[j].id === 'AMU' + organisme.type.code + organisme.code)
                                || (ambulance && this.moyens[i].markers[j].id === 'AMB' + ambulance.code)
                            ) {
                                const currentMarkers = JSON.parse(JSON.stringify(this.$refs.carto.markers || {}));
                                currentMarkers[this.moyens[i].markers[j].id] = this.moyens[i].markers[j];
                                this.updateDistances(currentMarkers, this.moyens[i].markers[j], true);
                                return;
                            }
                        }
                    }
                },
                onShortcutPatient() {
                    if (this.appel && this.appel.numero) {
                        this.carto.rightSidebar = 'appel' === this.carto.rightSidebar ? null : 'appel';
                    }
                },
                onShortcutAppels() {
                    this.carto.rightSidebar = 'appels' === this.carto.rightSidebar ? null : 'appels';
                },
                onShortcutMissions() {
                    this.carto.rightSidebar = 'missions' === this.carto.rightSidebar ? null : 'missions';
                },
                onShortcutObservation() {
                    if (this.appel && this.appel.numero) {
                        this.carto.rightSidebar = 'appel';
                        this.appelOnglet = 'observations';
                        this.scrollObservations();
                    }
                },
                onShortcutCentrerPatient() {
                   if (this.carto.patient) {
                       this.centrerMarker(this.carto.patient);
                   } else if (this.carto.triangulation) {
                       this.centrerMarker(this.carto.triangulation);
                   }
                },
                onShortcutTriangulation() {
                    if (this.carto.triangulation) {
                        this.showTriangulation = !this.showTriangulation;
                    }
                },
                onShortcutCentrerMarker() {
                    this.carto.marker && this.centrerMarker(this.carto.marker);
                },
                onShortcutReset() {
                    this.resetFiltres();
                },
                onShortcutTrajets() {
                    if (this.appel && this.appel.secondaire && this.appel.secondaire.etablissementPriseEnCharge) {
                        this.showTransportSecondaire(this.appel.secondaire);
                    } else if (this.carto.marker) {
                        this.centrerPaths(this.carto.marker);
                    }
                },
                shortcutToParam() {
                    let data = {ETB:'etablissement', PHAR: 'pharmacie', MED: 'medecin', dae: 'dae', POI: 'poi'};
                    for (let item in data) {
                        if(this.carto.marker.id.indexOf(item) !== -1) {
                            let id = this.carto.marker.code;
                            if (item === 'dae') {
                                id = this.carto.marker.id.split(item+'-')[1];
                            } else if (item === 'POI') {
                                id = this.carto.marker.id.split(item)[1];
                            }
                            window.open(Router.generate('parametrage.edit', {
                                type: data[item],
                                id: id
                            }), '_blank');
                        }
                    }
                },
                setCartoPatient(appel) {
                    this.carto.patient = appel && appel.geolocalisation && appel.geolocalisation.latitude && appel.geolocalisation.longitude ? {
                        color: 'white',
                        bg: '#ab8ce4',
                        icoClass: 'fa-solid fa-folder-open',
                        latLng: latLng(appel.geolocalisation.latitude, appel.geolocalisation.longitude),
                        id: this.$refs.carto.markers['APP' + appel.numero] ? 'APP' + appel.numero : 'dossier',
                        patient: true,
                    } : null;
                },
                setCartoTriangulation(appel, allowFromQuery) {
                    let center = null,
                        circle = null,
                        semicircle = null,
                        polygon = null,
                        ellipse = null,
                        tooltip = null,
                        origine = 'query';

                    const query = new URLSearchParams(window.location.search);
                    if (allowFromQuery && this.default.forme && this.default.forme.match(/^arc$/i) && query.get('x1') && query.get('y1') && query.get('angle_debut') === '0' && query.get('angle_fin') === '360') {
                        // Query : arc, mais cercle complet
                        tooltip = 'Position de l\'appelant';
                        center = latLng(query.get('x1'), query.get('y1')); // Attention, les X et Y sont inversés dans le code V5 qui génère l'URL...
                        circle = {
                            latLng: [query.get('x1'), query.get('y1')], // Attention, les X et Y sont inversés dans le code V5 qui génère l'URL...
                            radius: parseInt(query.get('rayon')),
                        };
                    } else if (allowFromQuery && this.default.forme && this.default.forme.match(/^arc$/i) && query.get('x1') && query.get('y1')) {
                        // Query : arc
                        tooltip = 'Position de l\'appelant';
                        center = latLng(query.get('x1'), query.get('y1')); // Attention, les X et Y sont inversés dans le code V5 qui génère l'URL...
                        semicircle = {
                            latLng: [query.get('x1'), query.get('y1')], // Attention, les X et Y sont inversés dans le code V5 qui génère l'URL...
                            options: {
                                radius: parseInt(query.get('rayon')),
                                startAngle: parseInt(query.get('angle_debut')) || '0.0',
                                // Conversion de l'ouverture en angle de fin (oui, on va à plus de 360°, selon l'angle de départ)
                                stopAngle: parseInt(query.get('angle_debut')) + parseInt(query.get('angle_fin')),
                            },
                        };
                    } else if (allowFromQuery && this.default.forme && this.default.forme.match(/^ellipse$/i) && query.get('x1') && query.get('y1')) {
                        // Query : ellipse
                        tooltip = 'Position de l\'appelant';
                        center = latLng(query.get('x1'), query.get('y1')); // Attention, les X et Y sont inversés dans le code V5 qui génère l'URL...
                        ellipse = {
                            latLng: [query.get('x1'), query.get('y1')], // Attention, les X et Y sont inversés dans le code V5 qui génère l'URL...
                            radius: [query.get('rayon_x1'), query.get('rayon_y1')],
                            tilt: parseFloat(query.get('angle1')) - 90, // Plugin Leaflet: angle depuis l'ouest, PFLAU : angle depuis le nord, les deux dans le sens horaire ?
                        };
                    } else if (allowFromQuery && this.default.forme && this.default.forme.match(/^polygone$/i) && query.get('points1')) {
                        // Query : polygone
                        tooltip = 'Position de l\'appelant';
                        // Moyenne des x et moyenne des y, en retirant le dernier point qui ferme le polygone
                        const pointCollection = query.get('points1').split('_').map(p => ({latitude: p.split(',')[0], longitude: p.split(',')[1]}));
                        const points = [...pointCollection];
                        points.pop();
                        center = latLng(
                            points.reduce((acc, cur) => acc + parseFloat(cur.latitude), 0) / points.length,
                            points.reduce((acc, cur) => acc + parseFloat(cur.longitude), 0) / points.length,
                        );
                        polygon = pointCollection.map(p => latLng(p.latitude, p.longitude))
                    } else if (appel && appel.triangulation) {
                        tooltip = 'Position de l\'appelant par ' + appel.triangulation.source + ' (certitude : ' + appel.triangulation.confiance + '%)';
                        origine = 'dossier';
                        if (appel.triangulation.arc && parseInt(appel.triangulation.arc.depart) === 0 && parseInt(appel.triangulation.arc.ouverture) === 360) {
                            // BDD : cercle
                            center = latLng(appel.triangulation.arc.latitude, appel.triangulation.arc.longitude);
                            circle = {
                                latLng: [appel.triangulation.arc.latitude, appel.triangulation.arc.longitude],
                                radius: parseInt(appel.triangulation.arc.rayonExterne),
                            };
                        } else if (appel.triangulation.arc) {
                            // BDD : arc
                            center = latLng(appel.triangulation.arc.latitude, appel.triangulation.arc.longitude);
                            semicircle = {
                                latLng: [appel.triangulation.arc.latitude, appel.triangulation.arc.longitude],
                                options: {
                                    radius: appel.triangulation.arc.rayonExterne,
                                    startAngle: parseInt(appel.triangulation.arc.depart) || '0.0',
                                    // Conversion de l'ouverture en angle de fin (oui, on va à plus de 360°, selon l'angle de départ)
                                    stopAngle: parseInt(appel.triangulation.arc.depart) + parseInt(appel.triangulation.arc.ouverture),
                                },
                            };
                        } else if (appel.triangulation.ellipse) {
                            // BDD : ellipse
                            center = latLng(appel.triangulation.ellipse.latitude, appel.triangulation.ellipse.longitude);
                            ellipse = {
                                latLng: [appel.triangulation.ellipse.latitude, appel.triangulation.ellipse.longitude],
                                radius: [appel.triangulation.ellipse.semiaxeMajeur, appel.triangulation.ellipse.semiaxeMineur],
                                tilt: parseFloat(appel.triangulation.ellipse.orientation) - 90, // Plugin Leaflet: angle depuis l'ouest, PFLAU : angle depuis le nord, les deux dans le sens horaire ?
                            };
                        } else {
                            // BDD : polygone
                            // Moyenne des x et moyenne des y, en retirant le dernier point qui ferme le polygone
                            const points = [...appel.triangulation.pointCollection];
                            points.pop();
                            center = latLng(
                                points.reduce((acc, cur) => acc + parseFloat(cur.latitude), 0) / points.length,
                                points.reduce((acc, cur) => acc + parseFloat(cur.longitude), 0) / points.length,
                            );
                            polygon = appel.triangulation.pointCollection.map(p => latLng(p.latitude, p.longitude))
                        }
                    }

                    if (center) {
                        this.carto.triangulation = {
                            color: '#ab8ce4',
                            bg: 'white',
                            icoClass: 'fa-solid fa-phone-arrow-up-right',
                            latLng: center,
                            libelle: 'Appelant',
                            id: 'triangulation',
                            tooltip,
                            circle,
                            semicircle,
                            polygon,
                            ellipse,
                            origine,
                        };
                        this.showTriangulation = 'query' === origine || !this.appel.geolocalisation || null === this.appel.geolocalisation.latitude;
                    } else {
                        this.carto.triangulation = null;
                    }
                },
                getMarkerLngLats(marker) {
                    if (marker && marker.lngLatOsrm && this.origineDossier) {
                        const markerEstOrigine =
                            // marker -> custom destination
                            (
                                marker.id !== 'coordonneesCustom'
                                && this.coordonneesCustom
                                && this.coordonneesCustom.lngLatOsrm
                                && this.coordonneesCustom.type === 'destination'
                                && this.coordonneesCustom.lngLatOsrm === this.origineDossier
                            )
                            // marker custom origine -> patient
                            || (
                                marker.id === 'coordonneesCustom'
                                && this.coordonneesCustom
                                && this.coordonneesCustom.lngLatOsrm
                                && this.coordonneesCustom.type === 'origine'
                                && this.coordonneesCustom.lngLatOsrm !== this.origineDossier
                            )
                            || marker.estMoyen
                            || marker.estOrganisme
                        ;

                        return {
                            origine: markerEstOrigine ? marker.lngLatOsrm : this.origineDossier,
                            destination: markerEstOrigine ? this.origineDossier : marker.lngLatOsrm
                        };
                    }

                    return null;
                },
                getMarkerItineraires(marker) {
                    if (marker && this.origineDossier) {
                        const lngLats = this.getMarkerLngLats(marker);

                        if(lngLats && lngLats.origine && lngLats.destination) {
                            let itineraires = {};

                            // fallback
                            if (this.itineraires[lngLats.destination] && this.itineraires[lngLats.destination][lngLats.origine]) {
                                Object.assign(itineraires, this.itineraires[lngLats.destination][lngLats.origine]);
                            }

                            if(this.itineraires[lngLats.origine] && this.itineraires[lngLats.origine][lngLats.destination]) {
                                Object.assign(itineraires, this.itineraires[lngLats.origine][lngLats.destination]);
                            }

                            return JSON.parse(JSON.stringify(itineraires));
                        }
                    }

                    return null;
                },
                setCoordonneesCustom(type, lngLatOsrm) {
                    let coords = latLng(lngLatOsrm.split(',').reverse());
                    this.coordonneesCustom = {
                        type,
                        lngLatOsrm,
                        info: {
                            id: 'coordonneesCustom',
                            libelle: type === 'origine' ? 'Point de départ personnalisé' : 'Destination personnalisée',
                            lngLatOsrm: lngLatOsrm,
                            hidden: false,
                            latLng: coords,
                            color: "#03a9f3",
                            icoClass: "fa-solid fa-lg " + (type === 'origine' ? 'fa-flag' : 'fa-flag-checkered'),
                        },
                    };
                    if (this.carto.markers && this.carto.markers.dossier) {
                        this.infoMarker(this.coordonneesCustom.info);
                        this.updateMarkers();
                    }
                    Request.fetchJson('https://api-adresse.data.gouv.fr/reverse/?lat=' + coords.lat + '&lon=' + coords.lng)
                        .then(data => {
                            if (data.features.length && this.coordonneesCustom && this.coordonneesCustom.info) {
                                const adresse = [
                                    (data.features[0].properties.housenumber + ' ' + data.features[0].properties.street).trim(),
                                    data.features[0].properties.postcode + ' ' + data.features[0].properties.city,
                                ];
                                Vue.set(this.coordonneesCustom.info, 'adresse', adresse);
                                if (this.carto.marker && this.carto.marker.id === 'coordonneesCustom') {
                                    Vue.set(this.carto.marker, 'adresse', adresse);
                                }
                            }
                        })
                    ;
                },
            },
            computed: {
                currentAppel() {
                    // Compatibilité avec la Régulation pour utiliser DevenirMixin
                    return this.appel;
                },
                urlRegulation() {
                    return this.appel && this.appel.numero ? Router.generate('regulation.voir', {numero: this.appel.numero}) : null;
                },
                origineDae() {
                    return this.entities.find(e => e.dae === true && e.selected === true) ? this.origineDossier : null;
                },
                origineMedecins() {
                    let keywords = this.cleanedKeywords.replace(',', ' ').trim();
                    return this.origineDossier && this.entities.find(e => e.medecins === true && e.selected === true)
                        ? this.origineDossier + ',' + keywords + ',' + (this.typeMedecin ? this.typeMedecin.code : '')
                        : null;
                },
                originePharmacies() {
                    let keywords = this.cleanedKeywords.replace(',', ' ').trim();
                    return this.origineDossier && this.entities.find(e => e.pharmacies === true && e.selected === true)
                        ? this.origineDossier + ',' + keywords
                        : null;
                },
                origineDossier() {
                    if (
                        this.carto.patient
                        && this.carto.patient.latLng
                        && this.carto.patient.latLng.lat
                        && this.carto.patient.latLng.lng
                    ) {
                        return parseFloat(this.carto.patient.latLng.lng).toFixed(6) + ',' + parseFloat(this.carto.patient.latLng.lat).toFixed(6);
                    }
                    if (
                        this.carto.triangulation
                        && this.carto.triangulation.latLng
                        && this.carto.triangulation.latLng.lat
                        && this.carto.triangulation.latLng.lng
                    ) {
                        return parseFloat(this.carto.triangulation.latLng.lng).toFixed(6) + ',' + parseFloat(this.carto.triangulation.latLng.lat).toFixed(6);
                    }
                    if (
                        this.coordonneesCustom
                        && this.coordonneesCustom.lngLatOsrm
                    ) {
                        return this.coordonneesCustom.lngLatOsrm;
                    }
                    return null;
                },
                appelDemandes() {
                    let demandes = [];

                    if(this.appel) {
                        this.appel.decisionCollection.filter(d => !d.estAnnulee).forEach(d => {
                            d.missionCollection.filter(m => m.enAttenteDeMoyen).forEach(m => {
                                demandes.push(m);
                            });
                        })
                    }

                    return demandes;
                },
                appelMissions() {
                    let missions = [];

                    if(this.appel) {
                        this.appel.decisionCollection.filter(d => !d.estAnnulee).forEach(d => {
                            d.missionCollection.filter(m => !m.enAttenteDeMoyen).forEach(m => {
                                missions.push(m);
                            });
                        })
                    }

                    return missions;
                },
                previousMarker() {
                    if (this.carto.cursor) {
                        const indexCursor = this.markersArray.findIndex(marker => marker.id === this.carto.cursor);
                        if (indexCursor > 0 && this.markersArray[indexCursor - 1].id !== 'dossier') {
                            return this.markersArray[indexCursor - 1];
                        }
                    }

                    return null;
                },
                nextMarker() {
                    if (this.carto.cursor) {
                        const indexCursor = this.markersArray.findIndex(marker => marker.id === this.carto.cursor);
                        if (indexCursor < this.markersArray.length - 1 && this.markersArray[indexCursor + 1].id !== 'dossier') {
                            return this.markersArray[indexCursor + 1];
                        }
                    }

                    return null;
                },
                markersArray() {
                    let markers = [];
                    Object.entries(this.carto.markers).forEach(([id, marker]) => {
                        if (marker.libelle && !marker.patient && 'triangulation' !== marker.id) {
                            let itineraire = {distance: null};
                            const itineraires = this.getMarkerItineraires(marker);

                            if (itineraires) {
                                for (let type of ['fastest', 'shortest', 'osrm', 'oiseau']) {
                                    if (itineraires[type] && itineraires[type].distance) {
                                        itineraire = Object.assign({type: type}, itineraires[type]);
                                        break;
                                    }
                                }
                            }
                            markers.push(Object.assign({itineraire: itineraire}, marker));
                        }
                    });
                    if ('km' === this.limiteUnite) {
                        markers.sort((a, b) => (a.latLng ? (a.itineraire.distance ?? 999999) : 0) - (b.latLng ? (b.itineraire.distance ?? 999999) : 0));
                    } else {
                        markers.sort((a, b) => (a.latLng ? (a.itineraire.temps ?? 999999) : 0) - (b.latLng ? (b.itineraire.temps ?? 999999) : 0));
                    }

                    return markers;
                },
                smsEntite() {
                    if (this.carto.marker) {
                        const telephones = Array.isArray(this.carto.marker.telephone) ? this.carto.marker.telephone.filter(t => t && t.type === 'tel' && t.numero && t.numero !== '') : [];
                        return [
                            'Le SAMU vous oriente vers cette destination :',
                            this.carto.marker.libelle,
                            ...(this.carto.marker.adresse ?? []),
                            telephones && telephones[0] ? 'Tél : ' + this.formatTelephone(telephones[0]['numero']) : null,
                            telephones && telephones[1] ? 'Tél : ' + this.formatTelephone(telephones[1]['numero']) : null,
                            this.carto.marker.codeAcces ? 'Code d\'accès : ' + this.carto.marker.codeAcces : null,
                        ].filter(v => !!v).join("\n");
                    }

                    return null;
                },
                affichageRegulation() {
                    return this.regulation.appels || this.regulation.demandes || this.regulation.missions;
                },
                nbAppelsGeocode() {
                    return this.appels.filter(appel => appel.geolocalisation && appel.geolocalisation.latitude && appel.geolocalisation.latitude && (
                        0 === this.appelsZoneFilter.length || appel.zone && this.appelsZoneFilter.some(zone => zone.code === appel.zone.code)
                    )).length;
                },
                nbDemandesGeocode() {
                    let count = 0,
                        appelsCounted = [];

                    this.missions.forEach(mission => {
                        if(mission.enAttenteDeMoyen
                            && mission.decision.appel.geolocalisation
                            && mission.decision.appel.geolocalisation.latitude
                            && mission.decision.appel.geolocalisation.latitude
                            && appelsCounted.indexOf(mission.decision.appel.numero) === -1
                            && (0 === this.appelsZoneFilter.length || mission.decision.appel.zone && this.appelsZoneFilter.some(zone => zone.code === mission.decision.appel.zone.code))
                        ){
                            count++;
                            appelsCounted.push(mission.decision.appel.numero);
                        }
                    });

                    return count;
                },
                nbMissionsGeocode() {
                    let count = 0,
                        appelsCounted = [];

                    this.missions.forEach(mission => {
                        if(!mission.enAttenteDeMoyen
                            && mission.decision.appel.geolocalisation
                            && mission.decision.appel.geolocalisation.latitude
                            && mission.decision.appel.geolocalisation.latitude
                            && appelsCounted.indexOf(mission.decision.appel.numero) === -1
                            && (0 === this.appelsZoneFilter.length || mission.decision.appel.zone && this.appelsZoneFilter.some(zone => zone.code === mission.decision.appel.zone.code))
                        ){
                            count++;
                            appelsCounted.push(mission.decision.appel.numero);
                        }
                    });

                    return count;
                },
                nbPages() {
                    return this.markersArray.length ? Math.ceil(this.markersArray.length / parseInt(this.nbParPage)) : 0;
                },
                indexMin() {
                    return (this.page - 1) * parseInt(this.nbParPage);
                },
                indexMax() {
                    return (this.page * parseInt(this.nbParPage)) - 1;
                },
                etablissementsDevenir() {
                    let etablissements = {};

                    if(this.appel) {
                        this.appel.patientCollection.forEach(patient => {
                            patient.devenirCollection.forEach(d => {
                                if (d.codeEtablissement) {
                                    if(!etablissements[d.codeEtablissement]) {
                                        etablissements[d.codeEtablissement] = [];
                                    }
                                    etablissements[d.codeEtablissement].push((patient.nomUsuel ?? '') + ' ' + (patient.prenomUsuel ?? '').trim());
                                }
                            });
                        });
                    }

                    return etablissements;
                },
                filtreTexteActif() {
                    return null !== this.keywords && '' !== this.keywords && (this.keywords.length >= 3 || this.keywords.match(/^DZ/i));
                },
                cleanedKeywords() {
                    return this.filtreTexteActif ? this.keywords.trim().toUpperCase().replace(/\s+/g, ' ') : '';
                },
                currentMarkerItineraires() {
                    if (this.carto.marker && this.origineDossier && this.carto.marker.lngLatOsrm !== this.origineDossier) {
                        return this.getMarkerItineraires(this.carto.marker);
                    }

                    return null;
                },
                countEtbUrgences() {
                    return this.entities.filter((entity) => { return entity.etablissements; })[0].markers.filter((marker) => { return !marker.hidden && marker.estUrgences; }).length;
                }
            },
        });
    }
}