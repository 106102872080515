<template>
    <a
        aria-haspopup="true"
        class="nav-link text-white"
        href="javascript:void(0)"
        title="Fournir un retour, une suggestion"
        v-b-modal.modal-feedback
    >
        <i class="fa-regular fa-paper-plane"></i>
        <b-modal
            v-model="showModal"
            cancel-title="Annuler"
            centered
            :header-bg-variant="'Bug' === type ? 'warning' : 'info'"
            header-text-variant="white"
            id="modal-feedback"
            ok-title="Envoyer"
            :ok-variant="'Bug' === type ? 'warning' : 'info'"
            size="md"
            title="Nouveau retour / suggestion"
            @ok.prevent="save()"
        >
            <p class="small text-muted">Ce formulaire vous permet d'envoyer une suggestion aux développeurs de l'application.</p>
            <b-form-group label="Type">
                <b-form-radio-group
                    v-model="type"
                    :options="types"
                    required
                ></b-form-radio-group>
                <form-error :errors="errors.type"></form-error>
                <div class="small mt-2" v-show="type === 'Bug'">
                    <b>En cas de bug bloquant, veuillez contacter le support</b> afin que votre demande soit traitée dans les plus brefs délais !
                </div>
            </b-form-group>
            <b-form-group label="Message">
                <b-textarea
                    v-model="message"
                    max-rows="15"
                    maxlength="2000"
                    required
                    rows="4"
                ></b-textarea>
                <form-error :errors="errors.message"></form-error>
            </b-form-group>
        </b-modal>
    </a>
</template>

<script>
import * as Sentry from '@sentry/vue';
import Request from '../../../utils/Request'
import Router from '../../../utils/Router'
import Vue from "vue";

const types = [{text: 'Amélioration', value: 'Feature'}, {text: 'Anomalie', value: 'Bug'}];

export default {
    data: function () {
        return {
            errors: {},
            message: null,
            saving: false,
            showModal: false,
            type: types[0].value,
            types: types,
        };
    },
    mounted() {
        this.nameInput = this.name;
        this.emailInput = this.email;
    },
    methods: {
        save() {
            let valid = true;
            if (!this.type) {
                Vue.set(this.errors, 'type', ['Champs requis.'])
                valid = false;
            }
            if (!this.message || !this.message.trim()) {
                Vue.set(this.errors, 'message', ['Champs requis.'])
                valid = false;
            }
            if (valid) {
                this.errors = {};
                // Ajout un code unique dans le titre pour éviter le throttling et aovir un sentry par feedback
                const uniqid = Math.random().toString(16).slice(2);
                const eventId = Sentry.captureMessage('Feedback - ' + this.type + ' - ID#' + uniqid, {
                    level: 'Bug' === this.type ? Sentry.Severity.Warning : Sentry.Severity.Info,
                });
                if (eventId) {
                    Request.fetchJson(
                        Router.generate(
                            'error-reporting-feedback',
                            {eventId}
                        ),
                        'POST',
                        {
                            message: this.message,
                            name: this.nameInput,
                            email: this.emailInput,
                        }
                    ).then(() => {
                        this.message = null;
                        this.type = this.types[0].value;
                        document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'success', flash: 'Merci de votre retour.'}}));
                    }).catch(() => {
                        document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: 'Une erreur est survenue.'}}));
                    });
                } else {
                    document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: 'Une erreur est survenue.'}}));
                }
                this.showModal = false;
            }
        },
    },
}
</script>