export default {
    data: function () {
        return {
            form: {
                type: null,
                entity: {},
                parent: null,
                saving: false,
                errors: {},
                open: false,
                loadingAdresse: false,
            },
            forms: {},
        };
    },
    methods: {
        normalize(skeleton, data) {
            let newData = {},
                base = JSON.parse(JSON.stringify(skeleton));

            for (const key in base) {
                const baseValue = base[key];
                const value = data[key] ?? '';

                if (value && 'object' === typeof value && value.hasOwnProperty('code') && value.hasOwnProperty('libelle')) {
                    newData[key] = value.code;
                } else if (key === 'commune') {
                    newData[key] = value.codeInsee;
                } else if (key === 'estActif') {
                    newData[key] = value ? '1' : '0';
                } else if (['secteurCollection', 'telephoneCollection'].includes(key)) {
                    newData[key] = value && value !== '' ? value : [];
                } else if ('_token' === key) {
                    newData[key] = baseValue;
                } else if (['etablissementCollection', 'medecinCollection', 'pharmacieCollection', 'poiCollection', 'zoneCollection'].includes(key)) {
                    newData[key] = value.length ? value.map(el => el.code) : [];
                } else if (baseValue !== null && typeof Array.isArray(baseValue)) {
                    newData[key] = value;
                } else if (baseValue !== null && typeof baseValue === 'object') {
                    newData[key] = this.normalize(base[key], value);
                } else if (key === 'horaires') {
                    newData[key] = JSON.stringify(value);
                } else {
                    newData[key] = value;
                }
            }

            if(data.communeCollection) {
                newData.communeCollection = data.communeCollection !== '' ? data.communeCollection : [];
            }

            return newData;
        },
        updateGeocodage(geocodage) {
            this.form.entity.latitude = geocodage.latitude;
            this.form.entity.longitude = geocodage.longitude;

            if(geocodage.latitude && geocodage.longitude && this.showMap) {
                this.showOnMap(this.form.entity);
            }
        },
        formatTelephone(value) {
            let cleaned = (value ?? '').toString().replace(/\s/g, '');
            let matches = cleaned.match(/^(\+\d{3}|\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);

            if(matches && matches[1]) {
                value = matches[1];
                for (let i = 2; i <= 5; i++) {
                    value += matches[i] ? ' ' + matches[i] : '';
                }
            }

            return value;
        }
    },
};